import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

const ViewMore = ({ children }) => {
  const [isHidden, setIsHidden] = useState(true);
  const divHeight = !isHidden ? '100%' : '180px';

  return (
    <Box
      sx={{ background: '#FFF', borderRadius: 1, border: '1px solid #E6E6E6', padding: '12px  24px', marginBottom: '24px', height: '100%' }}
    >
      <Box sx={{ height: divHeight, overflow: 'hidden' }}> {children}</Box>
      <Box
        mt={1}
        component={'div'}
        onClick={() => setIsHidden((prev) => !prev)}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Typography sx={{ textDecorationLine: 'underline' }}>{isHidden ? 'View More' : 'View Less'}</Typography>
      </Box>
    </Box>
  );
};

export default ViewMore;
