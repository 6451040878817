import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { getJobDetailsTable } from 'api/api';
import { clearTableData } from 'store/slices/JobDetailsTableSlice';
import CommonTable from '../table/DataSourceTable/CommonTable';

const styles = {
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#697586'
  },
  dialogContent: {
    width: '90vw',
    maxWidth: '90vw',
    maxHeight: '80vh',
    padding: '24px'
  },
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: '90vw',
      width: '90vw',
      margin: '16px'
    }
  }
};

const JOB_DETAILS_TABLE_ID = 'job_details_list';

const JobDetailsModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const jobDetailsData = useSelector((state) => state?.jobDetailsTable?.tables[JOB_DETAILS_TABLE_ID]);
  const loading = useSelector((state) => state?.jobDetailsTable?.loading);
  const error = useSelector((state) => state?.jobDetailsTable?.error);

  const memoizedData = useMemo(
    () => ({
      data: jobDetailsData || {
        columns: [],
        data: [],
        page_size: 0,
        page_number: 0,
        totalCount: 0,
        totalPages: 0
      },
      loading,
      error
    }),
    [jobDetailsData, loading, error]
  );

  const [totalCount, setTotalCount] = useState(0);

  const req_params = {
    source: 'ingestion',
    source_id: 'license_manual_upload',
    id: JOB_DETAILS_TABLE_ID
  };

  const apiCallOnPage = async (details) => {
    try {
      const res = await dispatch(
        getJobDetailsTable({
          ...details
        })
      );
      if (res?.payload?.totalCount) {
        setTotalCount(res?.payload?.totalCount);
      }
      return res.payload;
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearTableData(JOB_DETAILS_TABLE_ID));
    };
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} sx={styles.dialog}>
      <DialogTitle>
        Run History
        <IconButton aria-label="close" onClick={onClose} sx={styles.closeButton}>
          <CloseCircle size={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <CommonTable
          disableExport={true}
          apiCallOnPage={apiCallOnPage}
          tableData={memoizedData}
          req_params={req_params}
          title="Job Detail"
          tableTotalCount={totalCount}
          toolbarConfig={jobDetailsData?.data?.toolbarConfig}
        />
      </DialogContent>
    </Dialog>
  );
};

export default JobDetailsModal;
