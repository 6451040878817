import { createSlice } from '@reduxjs/toolkit';

import { AskAsatoGet } from '../../api/api';

const initialState = {
  hasQuery: false,
  data: {},
  loading: false,
  error: ''
};

const AskAsatoGetSlice = createSlice({
  name: 'Get Asato',
  initialState,
  reducers: {
    setHasQuery: (state, action) => {
      state.hasQuery = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(AskAsatoGet.pending, (state) => {
        state.loading = true;
      })
      .addCase(AskAsatoGet.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(AskAsatoGet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  }
});

export const { setHasQuery } = AskAsatoGetSlice.actions;
export default AskAsatoGetSlice.reducer;
