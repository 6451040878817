import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Box, CircularProgress, DialogContent, DialogTitle, Drawer } from '@mui/material';

import { createTask, getUsersList } from 'api/taskManagement.service';
import { INITIAL_TASK_FORM } from 'constants/TaskManagement/forms';
import useNotification from 'hooks/useNotifications';
// import { makeStyles } from '@mui/styles';
import { StyledButton } from 'styled-components/global';
import GlobalForm from 'ui-component/forms/GlobalForm';
import { useFunctionContext } from 'views/common/FunctionContext';

export default function Createtask({ open, handleClose }) {
  const [loading, setLoading] = useState(false);
  const { error: errorNotification, success: successNotification } = useNotification();
  const { sharedFunction } = useFunctionContext() || {};
  const handleRefreshTable = sharedFunction?.handleRefreshTable;
  const handleRefreshCards = sharedFunction?.handleRefreshCards;
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [formConfig, setFormConfig] = useState(INITIAL_TASK_FORM);

  const formRef = useRef();

  const defaultValues = {
    name: '',
    description: '',
    taskType: 'Channel Notification',
    priority: 'Medium',
    expectedCompletionDate: '',
    note: '',
    impactType: '',
    estimatedImpact: '',
    taskAssignedTo: []
  };

  const [formValues, setFormValues] = useState(defaultValues);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  useEffect(() => {
    // Fetch user list from API
    if (userList.length === 0) {
      setUserLoading(true);
      getUsersList()
        .then((response) => {
          setUserList(
            response?.data?.data?.map((user) => {
              return {
                label: user?.displayName || 'N/A',
                value: {
                  id: user?.id,
                  email: user?.employeeEmailId,
                  name: user?.displayName
                }
              };
            })
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setUserLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userList.length > 0) {
      setFormConfig((prev) => {
        return prev.map((form) => {
          if (form.name === 'taskAssignedTo') {
            return { ...form, options: userList };
          }
          return form;
        });
      });
    }
  }, [userList]);

  const handleSubmit = (values) => {
    setLoading(true);
    const newTask = {
      status: 'Assigned',
      ...values
    };

    createTask(newTask)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          successNotification('Task created successfully', 3000);
          handleClose();
          setFormValues(defaultValues);
          if (handleRefreshTable) {
            handleRefreshTable();
          }

          if (handleRefreshCards) {
            handleRefreshCards();
          }
        } else {
          errorNotification('Error creating task', 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        errorNotification('Error creating task', 3000);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const handleCloseModal = () => {
    if (formRef?.current) {
      setFormValues({ ...defaultValues });
      formRef.current.setFormValues({ ...defaultValues });
    }
    handleClose();
  };

  return (
    <Drawer anchor={'right'} open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box width={'500px'} height={'100%'}>
        <DialogTitle variant="h3">Create new Task</DialogTitle>
        {userLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="90%">
            <CircularProgress />
          </Box>
        ) : (
          <DialogContent>
            <GlobalForm
              resetOnSubmit
              ref={formRef}
              formData={formConfig}
              initialValues={formValues}
              onSubmit={handleSubmit}
              onChange={handleChange}
            >
              <Box display={'flex'} columnGap={2} justifyContent={'flex-end'}>
                <StyledButton onClick={handleCloseModal} variant="outlined" disabled={loading}>
                  <span>Cancel</span>
                </StyledButton>

                <StyledButton variant="contained" type="submit" disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : <span>Submit</span>}
                </StyledButton>
              </Box>
            </GlobalForm>
          </DialogContent>
        )}
      </Box>
    </Drawer>
  );
}
