import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';

function Breadcrump({ breadcrumbList = [] }) {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ zIndex: 10 }}>
      {breadcrumbList.map((breadcrumb, index) => {
        const isLast = index === breadcrumbList.length - 1;

        return isLast ? (
          <Typography key={index} color="primary">
            {breadcrumb.label}
          </Typography>
        ) : (
          <Link
            underline="none"
            key={index}
            href={breadcrumb?.path || breadcrumb?.link}
            color="inherit"
            sx={{
              '&:hover': {
                '& span': {
                  textDecoration: 'underline'
                }
              }
            }}
          >
            <span>{breadcrumb.label}</span>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}

export default Breadcrump;
