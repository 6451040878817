import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, useMediaQuery } from '@mui/material';
import { debounce } from 'lodash';

import { downloadAPI, getDownloadUrl } from 'api/api';
import DataSourceChips from 'ui-component/buttons/DataSourceChips';
import { chipData } from 'ui-component/DataList/dummyConfig';
import EmptyTablePlaceHolder from 'ui-component/ErrorComponents/TableEmpty';
import ServerError from 'ui-component/ErrorComponents/TableServerError';
import FadeInDiv from 'ui-component/FadeInDiv';
// import { GROUP_BY_KEYS_TABLE } from 'constants/constants';
// import { shouldHideFooter } from 'utils';
// import { configuration } from 'services/baseApiService';
import SearchBar from 'ui-component/SearchBar';
import DataGridSkleton from 'ui-component/Skeletons/DataGridSkleton';
import { escapeSpecialChars } from 'utils';

import DataSourceTable from '.';
import { styles } from './Styles/CommonTableStyles';

export const DownloadContext = createContext();

export default function CommonTable({
  apiCallOnPage,
  tableData,
  req_params,
  selectedChip,
  setSelectedChip,
  children,
  disableColumnSorting,
  disableColumnFilter,
  disableExport,
  entitlements,
  title,
  toggleButtons,
  isManualUploadTable,
  blob_url,
  searchBar = true,
  tableTotalCount,
  handleTaskOpen,
  handleStatusChange,
  handleClick,
  setTableState,
  onRowDelete,
  toolbarConfig,
  onEditClick,
  onDeleteClick
}) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const { invoiceType } = useSelector((state) => state?.page);
  const {
    data: { columns, data, columnGroupingModel, page_size, page_number, totalCount, search_column },
    loading,
    error
  } = tableData || {};
  // const data = [];
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
    totalCount: 0
  });

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [initialLoading, setInitialLoading] = useState(false);
  const [isFilterCleared, setIsFilterCleared] = useState(false);
  const [isSearchCleared, setIsSearchCleared] = useState(false);
  const [filters, setFilters] = useState({
    searchQuery: '',
    searchColumn: ''
  });

  const requestParams = {
    ...req_params,
    has_page_count: false,
    page_number: paginationModel?.page,
    page_size: paginationModel?.pageSize,
    search_query: filters?.searchQuery,
    search_column: filters?.searchColumn
  };

  sortBy && (requestParams.sort_by = sortBy);
  sortOrder && (requestParams.sort_order = sortOrder);

  // const uniqueLabelName = GROUP_BY_KEYS_TABLE[requestParams.id] || null;

  useEffect(() => {
    setInitialLoading(true);
    const details = {
      ...requestParams,
      page_number: 0,
      search_query: '',
      search_column: '',
      has_page_count: true
    };
    apiCallOnPage(details).then((res) => {
      if (res?.data) {
        setInitialLoading(false);
      }
    });
    // eslint-disable-next-line
  }, [invoiceType]);

  const handlePageChange = (newPage) => {
    if (setTableState) {
      setTableState({
        page_number: newPage?.page,
        page_size: newPage?.pageSize
      });
    }
    setPaginationModel((state) => ({
      ...state,
      page: newPage?.page,
      pageSize: newPage?.pageSize
    }));

    const details = {
      ...requestParams,
      page_number: newPage?.page,
      page_size: newPage?.pageSize,
      // has_page_count: true,
      sort_by: sortBy,
      sort_order: sortOrder
    };
    apiCallOnPage(details);
  };

  const handleSmPage = (event, page) => {
    const details = {
      ...requestParams,
      page_number: page
    };
    apiCallOnPage(details);
  };

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    const details = {
      ...requestParams,
      page_number: paginationModel?.page,
      page_size: value,
      has_page_count: true,
      sort_by: sortBy,
      sort_order: sortOrder
    };
    apiCallOnPage(details);
  };

  useEffect(() => {
    setPaginationModel({
      page: page_number,
      pageSize: page_size,
      totalCount
    });
  }, [page_number, page_size, totalCount]);

  const handleSorting = (name) => {
    setSortBy(name[0]?.field);
    setSortOrder(name[0]?.sort === 'asc' ? 1 : -1);

    const details = {
      ...requestParams,
      sort_by: name[0]?.field,
      sort_order: name[0]?.sort === 'asc' ? 1 : -1
    };
    apiCallOnPage(details);
  };

  const handleSearch = (value) => {
    const escapedValue = escapeSpecialChars(value);
    setIsSearchCleared(!value);

    const details = {
      ...requestParams,
      has_page_count: true,
      ...(value && { search_query: escapedValue }),
      page: 0
    };

    setFilters((prevFilters) => ({
      ...prevFilters,
      searchQuery: escapedValue
    }));

    setPaginationModel((state) => ({ ...state, page: 0 }));

    apiCallOnPage(details);
  };

  //to-do: need check this later
  useEffect(() => {
    if (isSearchCleared) {
      setInitialLoading(true);
      apiCallOnPage(requestParams).then(() => {
        setInitialLoading(false);
        setIsSearchCleared(false);
      });
    }
    // eslint-disable-next-line
  }, [isSearchCleared]);

  const handleDownload = async () => {
    if (isManualUploadTable) {
      getDownloadUrl(blob_url);
    } else {
      const response = await downloadAPI(requestParams, title);
      if (response?.download_url) {
        window.open(response.download_url, '_blank');
      } else {
        console.error('Download URL not found in the response');
      }
    }
  };

  const handleTogleButton = (value) => {
    localStorage.setItem('setSelectedChip', value);
    setSelectedChip(value);
    navigate(`/link?source=${value}`);
    const details = {
      ...requestParams,
      source: value,
      has_page_count: true
    };
    apiCallOnPage(details);
  };

  const onFilterChange = debounce((column) => {
    const field = column?.items[0]?.field;
    const value = column?.items[0]?.value;
    const escapedValue = value ? escapeSpecialChars(value) : '';

    let details = {
      ...requestParams,
      has_page_count: true,
      search_column: field,
      search_query: escapedValue,
      page_number: paginationModel?.page || 0,
      page_size: paginationModel?.pageSize || 10
    };

    if (filters.searchColumn !== field) {
      setPaginationModel({ ...paginationModel, page: 0 });
      details.page = 0;
    }

    setFilters({
      searchQuery: escapedValue || '',
      searchColumn: field || ''
    });

    if (!value) {
      setIsFilterCleared(true);
      details = {
        ...requestParams,
        has_page_count: true,
        search_column: '',
        search_query: '',
        page_number: 0,
        page_size: paginationModel?.pageSize || 10
      };
    } else {
      setIsFilterCleared(false);
    }

    apiCallOnPage(details);
  }, 1000);

  //use memo to cache the value
  const tableHeader = useMemo(() => {
    if (!columns) return [];

    return columns
      .map((item) => ({
        ...item,
        id: item?.key // Add id based on the key
      }))
      .filter((item) => item.display === undefined || item.display === true);
  }, [columns]);

  // key to title mapping
  const searchColumnTitle = useMemo(() => {
    const column = columns?.find((col) => col.key === search_column);
    return column ? column.title : '';
  }, [columns, search_column]);

  if (!loading && error) {
    return <ServerError retryDispatchFunction={() => apiCallOnPage(requestParams)} />;
  }

  if (initialLoading) {
    return <DataGridSkleton />;
  }

  // if (!loading && data?.length === 0) {
  //   return <EmptyTablePlaceHolder placeholder="Rows" />;
  // }
  const isSearching = Boolean(filters.searchQuery);

  return (
    <FadeInDiv>
      {data?.length || isFilterCleared || isSearchCleared || isSearching ? (
        <DownloadContext.Provider value={{ handleDownload, disableExport, entitlements, req_params, apiCallOnPage }}>
          <Box sx={styles.container}>
            <Box sx={{ ...styles.headerContainer, ...(isMobile && styles.mobileHeaderContainer) }}>
              {searchBar && (
                <Box>
                  <SearchBar placeholder={searchColumnTitle} onSearch={handleSearch} />
                </Box>
              )}
              {!isMobile && <Box>{children}</Box>}
            </Box>

            {isMobile && <Box mt={1}>{children}</Box>}

            {toggleButtons && (
              <Box sx={styles.chipContainer}>
                <DataSourceChips chipData={chipData} selectedChip={selectedChip} handleTogleButton={handleTogleButton} loading={loading} />
              </Box>
            )}

            <DataSourceTable
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              toolbarConfig={toolbarConfig}
              columnGroupingModel={columnGroupingModel}
              apiCallOnPage={apiCallOnPage}
              requestParams={requestParams}
              tableData={data}
              tableHeader={tableHeader}
              loading={loading}
              selectedChip={selectedChip}
              paginationModel={paginationModel}
              handlePageChange={handlePageChange}
              handleSmPage={handleSmPage}
              handleSmRowsPerPage={handleRowsPerPageChange}
              sortingMode={true}
              handleSorting={handleSorting}
              onFilterChange={onFilterChange}
              disableColumnFilter={disableColumnFilter}
              disableColumnSorting={disableColumnSorting}
              tableTotalCount={tableTotalCount}
              handleTaskOpen={handleTaskOpen}
              handleStatusChange={handleStatusChange}
              onRowDelete={onRowDelete}
            />
          </Box>
        </DownloadContext.Provider>
      ) : (
        <EmptyTablePlaceHolder
          handleClick={handleClick}
          placeholder="Data"
          handleRefreshTable={() => apiCallOnPage(requestParams)}
          selectedChip={selectedChip}
          handleTogleButton={handleTogleButton}
          toggleButtons={toggleButtons}
          req_params={req_params}
        />
      )}
    </FadeInDiv>
  );
}
