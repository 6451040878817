import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import img from 'assets/images/report.png';
import InvoiceModalWrapper from 'ui-component/CustomWrappers/Modal/InvoiceModalWrapper';
import CreateAction from 'ui-component/Modal/CreateAction';
import Createtask from 'ui-component/Modal/CreateTask';
import OnHoverToolTip from 'ui-component/Tooltips/OnHoverToolTip';
import { useFunctionContext } from 'views/common/FunctionContext';
import MenuDropdownButton from 'ui-component/buttons/MenuDropdownButton';
import { connectionMenuItems, handleConnectionOptionSelect } from 'utils/connectionUtils';
import DataSourceChips from 'ui-component/buttons/DataSourceChips';
import { chipData } from 'ui-component/DataList/dummyConfig';

const EmptyTablePlaceHolder = ({
  placeholder = 'Data',
  handleRefreshTable,
  placeholderText = 'No Data Found',
  selectedChip,
  handleTogleButton,
  toggleButtons,
  req_params
}) => {
  const [open, setOpen] = useState(false);
  const screen = useSelector((state) => state?.page?.page);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sharedFunction } = useFunctionContext() || {};
  const noEdit =
    sharedFunction?.taskStatus &&
    (sharedFunction?.taskStatus === 'Rejected' ||
      sharedFunction?.taskStatus === 'Closed by User' ||
      sharedFunction?.taskStatus === 'Completed');

  const handleOpenModel = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    handleRefreshTable?.();
  };

  const handleOptionSelect = (option) => {
    handleConnectionOptionSelect(option, navigate, dispatch);
  };

  if (screen === 'task-detail') {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" gap={1} justifyContent="center" minHeight={200}>
        <Typography variant="h4">Create action by clicking add button</Typography>
        <OnHoverToolTip variant="children" title={noEdit ? 'Please change the task status!' : 'Create a new Action'}>
          <Button disabled={noEdit} startIcon={<AddIcon />} variant="contained" onClick={handleOpenModel}>
            Create New Action
          </Button>
        </OnHoverToolTip>
        {open && <CreateAction open={open} handleClose={() => setOpen(false)} handleRefreshTable={handleRefreshTable} />}
      </Box>
    );
  }

  if (screen === 'tasks') {
    return (
      <>
        <Box minHeight={250} display="flex" flexDirection="column" alignItems="center" gap={1} justifyContent="center">
          <Typography variant="h4">Create Task by clicking add button</Typography>
          <Button startIcon={<AddIcon />} variant="contained" onClick={handleOpenModel}>
            Create New Task
          </Button>
        </Box>
        {open && <Createtask open={open} handleClose={() => setOpen(false)} handleRefreshTable={handleRefreshTable} />}
      </>
    );
  }

  if (screen === 'link' && toggleButtons) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" gap={2} justifyContent="center" minHeight={200}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
          <DataSourceChips chipData={chipData} selectedChip={selectedChip} handleTogleButton={handleTogleButton} loading={false} />
        </Box>
        <Typography variant="h4">Create Connection by clicking add button</Typography>
        <MenuDropdownButton name={'Create New Connection'} onOptionSelect={handleOptionSelect} menuItems={connectionMenuItems} />
      </Box>
    );
  }

  if (screen === 'invoice-list') {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" gap={1} justifyContent="center" minHeight={200}>
        <Typography variant="h4">Upload New Invoice by clicking add button</Typography>
        <Button startIcon={<AddIcon />} variant="contained" onClick={handleOpenModel}>
          Upload New Invoice
        </Button>
        {open && <InvoiceModalWrapper open={open} onClose={handleCloseModal} />}
      </Box>
    );
  }

  if (screen === 'dataset-detail' && req_params?.id === 'licenseDataset') {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" gap={1} justifyContent="center" minHeight={200}>
        <Typography variant="h4">Upload New Dataset by clicking add button</Typography>
        <Button startIcon={<AddIcon />} variant="contained" onClick={handleOpenModel}>
          New Dataset
        </Button>
        {open && <InvoiceModalWrapper open={open} onClose={handleCloseModal} />}
      </Box>
    );
  }

  if (screen === 'source-detail' && window.location.pathname.includes('/link')) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" gap={1} justifyContent="center" minHeight={200}>
        <Typography variant="h4">Upload New Data Source by clicking add button</Typography>
        <Button startIcon={<AddIcon />} variant="contained" onClick={handleOpenModel}>
          New Data Source
        </Button>
        {open && <InvoiceModalWrapper open={open} onClose={handleCloseModal} />}
      </Box>
    );
  }

  return (
    <Grid
      sx={{
        textAlign: 'center',
        width: '100%',
        m: 'auto'
      }}
    >
      <Box component="img" src={img} alt="No reports illustration" sx={{ width: '20%', height: 'auto', mb: 4 }} />
      <Typography gutterBottom sx={{ fontSize: '24px', fontWeight: '600' }}>
        {placeholderText ? placeholderText : `No ${placeholder} Found!`}
      </Typography>
    </Grid>
  );
};

export default EmptyTablePlaceHolder;
