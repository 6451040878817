import { StyledTypography } from 'styled-components/global';

import { StyledBetaDotWrapper } from '../styles';

const BetaTag = ({ beta, isSidebarExpanded }) => (
  <>
    {beta && !isSidebarExpanded && (
      <StyledBetaDotWrapper
        style={{
          width: 8,
          height: 8,
          backgroundColor: '#2A76F4',
          borderRadius: '50%'
        }}
        p={0.7}
      >
        <StyledTypography color={'white'} size={'0.5rem'} variant="caption">
          B
        </StyledTypography>
      </StyledBetaDotWrapper>
    )}
    {isSidebarExpanded && beta && (
      <StyledBetaDotWrapper style={{ background: '#2A76F4' }} p={0.3} borderRadius={4}>
        <StyledTypography color={'white'} size={'0.5rem'} variant="caption">
          BETA
        </StyledTypography>
      </StyledBetaDotWrapper>
    )}
  </>
);

export default BetaTag;
