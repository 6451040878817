import React, { useState } from 'react';
import { Box, Divider } from '@mui/material';
import { ArrowCircleLeft, ArrowCircleRight } from 'iconsax-react';
import { allRoutes } from 'routes/routers';
import { sidebarMotionStyle, StyledArrowButton, StyledSidebarWrapper } from '../styles';
import { getSidebarTabs } from '../utils';
import SidebarTab from './sidebar-tab';

const Sidebar = ({ isStorybook = false, sidebarData }) => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(() => {
    const savedState = localStorage.getItem('sidebarExpanded');
    return savedState ? JSON.parse(savedState) : false;
  });

  const SIDEBAR_TABS = isStorybook ? sidebarData : getSidebarTabs(allRoutes);

  const handleSidebarToggle = () => {
    const newState = !isSidebarExpanded;
    setIsSidebarExpanded(newState);
    localStorage.setItem('sidebarExpanded', JSON.stringify(newState));
  };

  return (
    <Box style={sidebarMotionStyle} data-test-id={'sidebar'}>
      <StyledSidebarWrapper>
        <StyledArrowButton onClick={handleSidebarToggle} role="button">
          {!isSidebarExpanded ? (
            <ArrowCircleRight size={20} data-test-id="arrow-right" />
          ) : (
            <ArrowCircleLeft size={20} data-test-id="arrow-left" />
          )}
        </StyledArrowButton>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {SIDEBAR_TABS.topTabs.map((route, index) => (
            <SidebarTab key={index} route={route} isSidebarExpanded={isSidebarExpanded} setIsSidebarExpanded={setIsSidebarExpanded} />
          ))}
          <Divider />
          {SIDEBAR_TABS.middleTabs.map((route, index) => (
            <SidebarTab key={index} route={route} isSidebarExpanded={isSidebarExpanded} setIsSidebarExpanded={setIsSidebarExpanded} />
          ))}
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {SIDEBAR_TABS.bottomTabs.map((route, index) => (
            <SidebarTab key={index} route={route} isSidebarExpanded={isSidebarExpanded} setIsSidebarExpanded={setIsSidebarExpanded} />
          ))}
        </Box>
      </StyledSidebarWrapper>
    </Box>
  );
};

export default Sidebar;
