import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { addNotification, removeNotification } from 'store/slices/NotificationSlice';

// todo : uuid() should be replaced by useId hook from react

const useNotification = () => {
  const dispatch = useDispatch();
  const error = (message, delay) => {
    dispatch(addNotification({ message, type: 'error', delay, id: uuidv4() }));
  };

  const success = (message, delay) => {
    dispatch(addNotification({ message, type: 'success', delay, id: uuidv4() }));
  };

  const info = (message, delay) => {
    dispatch(addNotification({ message, type: 'info', delay, id: uuidv4() }));
  };

  const asyncAction = async (message, type, delay, asyncTask) => {
    const id = uuidv4();
    dispatch(addNotification({ message, type, delay, id }));

    try {
      const res = await asyncTask();
      dispatch(removeNotification(id)); // Remove notification when async task completes
      return res;
    } catch (error) {
      dispatch(removeNotification(id)); // Remove notification on error
      return error;
    }
  };

  return { error, success, info, asyncAction };
};

export default useNotification;
