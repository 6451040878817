import { useState } from 'react';
import { StyledTabExternalLinkWrapper, StyledTabWithChildrenWrapper, StyledTabWrapper } from '../styles';
import TabContent from './tab-content';

const SidebarTab = ({ route, isSidebarExpanded, setIsSidebarExpanded }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (route?.children) {
      setOpen((prev) => !prev);
      setIsSidebarExpanded(true);
    }
  };

  /*
   Here we are checking if the route is external or not
   If the route is external, then we render the StyledTabExternalLinkWrapper component - which has 'a' tag
   If the route is not external, then we render the StyledTabWithChildrenWrapper component - which has 'div' tag, so that we can handle the onClick event
   If the route does not have children, then we render the StyledTabWrapper component - which has 'Link' tag which is used to navigate to the route
   */

  return route?.external ? (
    <StyledTabExternalLinkWrapper
      href={route?.onClickPath ? route?.onClickPath : route?.path}
      target="_blank"
      rel="noreferrer"
      data-test-id={`sidebar-tab-${route.name}`}
    >
      <TabContent route={route} isSidebarExpanded={isSidebarExpanded} open={open} setOpen={setOpen} />
    </StyledTabExternalLinkWrapper>
  ) : route?.children?.length > 0 ? (
    <StyledTabWithChildrenWrapper onClick={handleClick} data-test-id={`sidebar-tab-${route.name}`}>
      <TabContent route={route} isSidebarExpanded={isSidebarExpanded} open={open} setOpen={setOpen} />
    </StyledTabWithChildrenWrapper>
  ) : (
    <StyledTabWrapper to={route?.onClickPath ? route?.onClickPath : route?.path} data-test-id={`sidebar-tab-${route.name}`}>
      <TabContent route={route} isSidebarExpanded={isSidebarExpanded} open={open} setOpen={setOpen} />
    </StyledTabWrapper>
  );
};

export default SidebarTab;
