import React, { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContext } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LogoutCurve, Setting } from 'iconsax-react';

import { changeRole } from 'store/slices/PageSlice';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import ProfileSettingModal from 'ui-component/Modal/ProfileSettingModal';
import { capitalizeFirstLetter } from 'utils';

import LogoutModal from './LogoutModal';
import styles from './Styles/ProfileSectionStyles';

const ROLE_TO_INDEX = {
  executive: '1',
  user: '2',
  admin: '3'
};

const useUserDetails = () => {
  const navigate = useNavigate();
  const storedAccounts = JSON.parse(localStorage.getItem('userDetails'));
  if (!storedAccounts) {
    navigate('/login');
  }
  const personaList = storedAccounts?.user?.personas;
  const role = localStorage.getItem('persona');
  const user = storedAccounts?.user
    ? { displayName: storedAccounts.user.displayName, employeeEmailId: storedAccounts.user.employeeEmailId }
    : { displayName: '', employeeEmailId: '' };
  const initials = user.displayName
    ?.split(' ')
    ?.map((namePart) => namePart[0])
    ?.join('')
    ?.toUpperCase();

  return { storedAccounts, personaList, role, user, initials };
};

const AdminSection = ({ user, storedAccounts, selectedPersona, isSmallScreen, open }) =>
  !isSmallScreen && (
    <Box sx={styles.adminSection}>
      <Button sx={styles.adminButton}>
        {user.displayName} {!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </Button>
      <span style={styles.spanStyle}>
        {storedAccounts?.user?.jobTitle || ''}
        {storedAccounts?.user?.jobTitle && selectedPersona?.name ? ' | ' : ''}
        {selectedPersona?.name ? capitalizeFirstLetter(selectedPersona.name) : ''}
      </span>
    </Box>
  );

const ProfileSection = () => {
  const { storedAccounts, personaList, role, user: userData, initials } = useUserDetails();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const selectedPersona = personaList?.find((persona) => persona.id === role);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(role);
  const [user] = useState(userData);
  const [openSetting, setOpenSetting] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setOpenLogoutModal(true);
  };

  const handleClickOpenSetting = () => {
    handleClose();
    setOpenSetting(true);
  };

  const handleCloseLogoutModal = () => {
    setOpenLogoutModal(false);
  };

  const handleCloseSetting = () => {
    setOpenSetting(false);
  };

  useEffect(() => {
    if (anchorRef.current && !open) {
      anchorRef.current.focus();
    }
  }, [open]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    localStorage.setItem('persona', newValue);
    dispatch(changeRole(ROLE_TO_INDEX[newValue]));

    const redirectUrl = newValue === 'admin' ? '/link' : '/home'; //TODO: Find better solution
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    if (role && user) {
      setValue(role);
      // ! issue : on running the code below, i.e. navigation, this useEffect will run again causing infinite loop.
      // const redirectUrl = role === 'admin' ? '/link' : '/home';
      // window.location.href = redirectUrl;
    }
  }, [role, user]);

  return (
    <>
      <Chip
        label={
          <AdminSection
            user={user}
            storedAccounts={storedAccounts}
            selectedPersona={selectedPersona}
            isSmallScreen={isSmallScreen}
            open={open}
          />
        }
        sx={styles.chip}
        icon={
          <Avatar
            sx={styles.avatar}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <PerfectScrollbar style={styles.popper}>
                    <Box sx={{ p: '24px' }}>
                      <List component="nav" sx={styles.list(theme)}>
                        <ListItem sx={styles.listItem}>
                          <Box sx={styles.listItemBox}>
                            <Avatar sx={styles.avatarBox}>{initials}</Avatar>
                          </Box>
                          <Box sx={styles.userBox}>
                            <Typography variant="h3">{user.displayName}</Typography>
                            <Typography variant="h4" sx={{ fontWeight: 500 }}>
                              {storedAccounts?.user?.jobTitle || ''}
                            </Typography>
                            <Typography>{user.employeeEmailId}</Typography>
                          </Box>
                        </ListItem>
                        <ListItem sx={styles.personaBox}>
                          <Typography variant="div" sx={{ my: 1.5 }}>
                            Asato Role{':'}
                          </Typography>
                          <Box sx={styles.personaTab}>
                            <TabContext value={value}>
                              <Tabs
                                value={ROLE_TO_INDEX[role]}
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                                sx={styles.tabContext}
                              >
                                {personaList?.map((item) => (
                                  <Tab
                                    data-test-id={`role-${item?.name}`}
                                    className={value === item?.id ? 'Mui-selected' : ''}
                                    key={item?.id}
                                    label={
                                      <div style={styles.selected}>
                                        {item?.name}
                                        <CheckIcon
                                          color="primary"
                                          sx={{ visibility: value === item.id ? 'visible' : 'hidden', fontSize: 19 }}
                                        />
                                      </div>
                                    }
                                    value={item?.id}
                                  />
                                ))}
                              </Tabs>
                            </TabContext>
                          </Box>
                        </ListItem>
                        <Grid sx={styles.gridSection}>
                          <ListItemButton onClick={handleClickOpenSetting}>
                            <ListItemIcon>
                              <Setting stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Settings</Typography>} />
                          </ListItemButton>
                          {/* <ListItemButton
                            sx={{ mx: 0, py: 1 }}
                            target="_blank"
                            href={import.meta.env.VITE_API_HELP}
                            rel="noopener noreferrer"
                          >
                            <ListItemIcon>
                              <MessageQuestion stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Grid container spacing={1} justifyContent="space-between">
                                  <Grid item>
                                    <Typography variant="body2">Help</Typography>
                                  </Grid>
                                  <Grid item></Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton> */}
                        </Grid>
                        <Grid sx={{ ...styles.gridSection, pb: '0px' }}>
                          <ListItemButton sx={styles.logoutButton} onClick={handleLogout}>
                            <ListItemIcon>
                              <LogoutCurve stroke={1.5} size="1.3rem" color="red" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography sx={styles.logoutText} onClick={handleLogout} variant="body2">
                                  Logout
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Grid>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <LogoutModal open={openLogoutModal} handleClose={handleCloseLogoutModal} />
      <ProfileSettingModal open={openSetting} handleClose={handleCloseSetting} />
    </>
  );
};

export default ProfileSection;
