import React, { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

/*
* Usage :
You can use this hook to show toast messages.

0. import : `import useToast from 'hooks/useToast';`
1. declare : `const { showToast, Toast } = useToast();`
2. include : `<Toast />` in the component
3. call : `showToast('Your message', { severity: 'info', duration: 5000 });`

*/

const defaultOptions = {
  severity: 'info',
  duration: 5000
};

const useToast = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [options, setOptions] = useState(defaultOptions);

  const showToast = (msg, options) => {
    setMessage(msg);
    setOptions({ ...defaultOptions, ...options });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const Toast = () => (
    <Snackbar
      open={isOpen}
      autoHideDuration={options.duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={options.severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );

  return { showToast, Toast };
};

export default useToast;
