export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  mobileHeaderContainer: {
    flexDirection: 'column'
  },
  chipContainer: {
    paddingY: '0.5em'
  }
};
