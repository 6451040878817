import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { InfoCircle } from 'iconsax-react';

import useStyles from './styles/ErrorMessageWithIconStyles';

const ErrorMessageWithIcon = ({ message }) => {
  const classes = useStyles();

  return (
    <Tooltip title={<Typography className={classes.tooltipTypography}>{message}</Typography>} arrow classes={{ tooltip: classes.tooltip }}>
      <Box className={classes.box}>
        <InfoCircle size="15" color="#FA2B2B" className={classes.infoCircle} />
        <Typography className={classes.errorMessage}>Error Message</Typography>
      </Box>
    </Tooltip>
  );
};

export default ErrorMessageWithIcon;
