import { Box, Button, Chip, Grid, Paper, Select, Typography } from '@mui/material';
import { styled } from '@mui/system';

const getFontSize = (size) => {
  switch (size) {
    case 'xl':
      return '2rem';
    case 'largest':
      return '1.5rem';
    case 'large':
      return '1.2rem';
    case 'medium':
      return '1rem';
    case 'small':
      return '0.9rem';
    case 'smaller':
      return '0.8rem';
    case 'very small':
      return '0.6rem';
    default:
      return size || '1rem';
  }
};

const getFontWeight = (weight) => {
  switch (weight) {
    case 'boldest':
      return '900';
    case 'bolder':
      return '700';
    case 'bold':
      return '600';
    case 'normal':
      return '400';
    case 'light':
      return '300';
    default:
      return '400';
  }
};

const getContainerStyle = (containerStyle) => {
  switch (containerStyle) {
    case 'centeredChildren':
      return 'flex';
    default:
      return 'block';
  }
};

const getAlignItems = (containerStyle) => {
  switch (containerStyle) {
    case 'centeredChildren':
      return 'center';
    default:
      return 'flex-start';
  }
};

const getJustifyContent = (containerStyle) => {
  switch (containerStyle) {
    case 'centeredChildren':
      return 'center';
    default:
      return 'flex-start';
  }
};

export const getColor = (color) => {
  switch (color) {
    case 'primary':
      return '#1976d2';
    case 'secondary':
      return '#dc004e';
    case 'text':
      return '#333';
    case 'error':
      return '#f44336';
    case 'warning':
      return '#ff9800';
    case 'info':
      return '#2196f3';
    case 'success':
      return '#4caf50';
    case 'link':
      return '#2A76F4';
    case 'white':
      return '#ffffff';
    case 'grey':
      return '#697586';
    default:
      return '#333';
  }
};

const getCursor = (cursor) => {
  switch (cursor) {
    case 'pointer':
      return cursor;
    case 'default':
      return 'default';
    default:
      return 'inherit';
  }
};

const getBorderColor = (theme) => {
  switch (theme) {
    case 'light':
      return '#D4E4FD';
    case 'dark':
      return '#333';
    case 'paper':
      return '#e0e0e0';
    default:
      return '#e0e0e0';
  }
};

const getBackgroundColor = (theme) => {
  switch (theme) {
    case 'light':
      return '#ffffff';
    case 'dark':
      return '#0d2349';
    case 'paper':
      return '#2A76F40D';
    default:
      return '#ffffff';
  }
};

const getBorderRadius = (round) => {
  switch (round) {
    case 'subtle':
      return '8px';
    case 'medium':
      return '16px';
    case 'full':
      return '24px';
    case 'circle':
      return '100%';
    default:
      return '8px';
  }
};

const getThemeColor = (theme) => {
  switch (theme) {
    case 'light':
      return '#020D20';
    case 'dark':
      return '#ffffff';
    case 'paper':
      return '#4E5663';
    default:
      return '#020D20';
  }
};

export const LoaderContainer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  font-size: ${({ size }) => getFontSize(size)};
  font-weight: ${({ weight }) => getFontWeight(weight)};
  display: ${({ containerStyle }) => getContainerStyle(containerStyle)};
  align-items: ${({ containerStyle }) => getAlignItems(containerStyle)};
  justify-content: ${({ containerStyle }) => getJustifyContent(containerStyle)};
  color: ${({ color }) => getColor(color)};
  cursor: ${({ cursor }) => getCursor(cursor)};
`;

export const StyledCard = styled(Box)`
  padding: 1rem;
  width: 100%;
  border: 1px solid ${({ theme }) => getBorderColor(theme)};
  background-color: ${({ theme }) => getBackgroundColor(theme)};
  border-radius: ${({ round }) => getBorderRadius(round)};
  color: ${({ theme }) => getThemeColor(theme)};
`;

export const StyledErrorContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ fullScreen }) => (fullScreen ? '100%' : 'auto')};
  width: ${({ fullScreen }) => (fullScreen ? '100%' : 'auto')};
  .error-container {
    max-width: 500px;
  }
`;

export const StyledErrorTextGroupContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.5rem;
  height: 90%;
`;

const getChipBackgroundColor = (color) => {
  switch (color) {
    case 'warning':
      return '#FEEFD7';
    case 'success':
      return '#EFF8E6';
    case 'error':
      return '#FEE9E9';
    case 'primary':
      return '#E9F3FE';
    default:
      return '#ffffff';
  }
};

const getChipColor = (color) => {
  switch (color) {
    case 'warning':
      return '#FAAE35';
    case 'success':
      return '#5DB806';
    case 'error':
      return '#FF0000 !important';
    case 'primary':
      return '#0077FF';
    default:
      return '#333';
  }
};

export const StyledChip = styled(Chip)(({ color, padding, bordered }) => ({
  borderRadius: '8px',
  padding: padding || '1rem 0.5rem',
  backgroundColor: getChipBackgroundColor(color),
  color: getChipColor(color),
  border: bordered ? `1px solid ${getChipColor(color)}` : 'none'
}));

const getSelectBackgroundColor = (color) => {
  switch (color) {
    case 'warning':
      return '#FEEFD7';
    case 'success':
      return '#EFF8E6';
    case 'error':
      return '#FEE9E9';
    case 'primary':
      return '#E9F3FE';
    default:
      return '#ffffff';
  }
};

const getSelectColor = (color) => {
  switch (color) {
    case 'warning':
      return '#FAAE35';
    case 'success':
      return '#5DB806';
    case 'error':
      return '#FF0000 !important';
    case 'primary':
      return '#0077FF';
    default:
      return '#333';
  }
};

const getSelectBorderColor = (color) => {
  switch (color) {
    case 'warning':
      return '#FAAE35';
    case 'success':
      return '#5DB806';
    case 'error':
      return '#FF0000';
    case 'primary':
      return '#0077FF';
    default:
      return '#cccccc';
  }
};

const getSelectHoverBorderColor = (color) => {
  switch (color) {
    case 'warning':
      return 'red';
    case 'success':
      return '#4CAF50';
    case 'error':
      return '#E53935';
    case 'primary':
      return '#1E88E5';
    default:
      return '#999999';
  }
};

const getSelectFocusBorderColor = (color) => {
  switch (color) {
    case 'warning':
      return '#F57C00';
    case 'success':
      return '#4CAF50';
    case 'error':
      return '#E53935';
    case 'primary':
      return '#1E88E5';
    default:
      return '#666666';
  }
};

const getSelectIconColor = (color) => {
  switch (color) {
    case 'warning':
      return '#FAAE35';
    case 'success':
      return '#5DB806';
    case 'error':
      return '#FF0000';
    case 'primary':
      return '#0077FF';
    default:
      return '#666666';
  }
};

export const StyledSelect = styled(Select)(({ color }) => ({
  margin: '0.5rem 0',
  '& .MuiSelect-select': {
    backgroundColor: getSelectBackgroundColor(color),
    color: getSelectColor(color)
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: getSelectBorderColor(color)
    },
    '&:hover fieldset': {
      borderColor: getSelectHoverBorderColor(color)
    },
    '&.Mui-focused fieldset': {
      borderColor: getSelectFocusBorderColor(color)
    }
  },
  '& .MuiSvgIcon-root': {
    color: getSelectIconColor(color)
  }
}));

export const StyledButton = styled(Button)`
  display: inline-block;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledIconWrapper = styled(Box)`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.7rem;
  aspect-ratio: 1 / 1;
  border-radius: ${({ round }) => getBorderRadius(round)};
  background-color: ${({ theme }) => getBackgroundColor(theme)};
`;

export const StyledNoPaddingGrid = styled(Grid)`
  &.MuiGrid-item {
    padding-top: 0;
  }
`;

export const StyledGridItem = styled(Grid)`
  padding-right: ${({ index, total }) => (index === 0 ? '0.6rem' : index === total - 1 ? '0' : '0.6rem')};
  padding-left: ${({ index, total }) => (index === total - 1 ? '0.6rem' : index === 0 ? '0' : '0.6rem')};
`;

export const StyledModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  background-color: #ffffff;
  box-shadow: 24px;
  padding: ${({ padding }) => padding || '1rem'};
  border-radius: ${({ round }) => getBorderRadius(round)};
`;

export const StyledBox = styled(Box)`
  border-color: ${({ borderColor }) => getColor(borderColor)};
  display: ${({ layoutStyle }) => (layoutStyle?.toLowerCase()?.includes('flex') ? 'flex' : 'block')};
  flex-direction: ${({ layoutStyle }) => (layoutStyle?.toLowerCase()?.includes('column') ? 'column' : 'row')};
  justify-content: ${({ layoutStyle }) => {
    const lowerCaseLayout = layoutStyle?.toLowerCase();
    if (lowerCaseLayout?.includes('center')) return 'center';
    if (lowerCaseLayout?.includes('end')) return 'flex-end';
    if (lowerCaseLayout?.includes('between')) return 'space-between';
    return 'flex-start';
  }};
  align-items: ${({ layoutStyle }) => {
    const lowerCaseLayout = layoutStyle?.toLowerCase();
    if (lowerCaseLayout?.includes('center')) return 'center';
    if (lowerCaseLayout?.includes('end')) return 'flex-end';
    if (lowerCaseLayout?.includes('between')) return 'space-between';
    return 'flex-start';
  }};
`;

export const StyledBlockquote = styled(Box)`
  border-left: ${({ sideLine }) => (sideLine === false ? 'none' : '4px solid #0077ff')};
  background-color: #f4f8fe;
  padding: ${({ padding }) => padding || '1rem'};
  color: #333;
  font-style: italic;
  border-radius: ${({ round }) => getBorderRadius(round)};
`;

export const StyledAutocompletePaper = styled(Paper)`
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const StyledScrollbarWrapper = styled(Box)`
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: 200ms ease;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  &:hover {
    scrollbar-color: #b0b0b0 transparent;

    &::-webkit-scrollbar-thumb {
      background-color: #b0b0b0;
    }
  }
`;
