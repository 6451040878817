import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Box from '@mui/material/Box';

import TablePopover from 'ui-component/table/TablePopover';

const chipStyle = {
  padding: '10px 5px',
  borderRadius: '5px',
  textAlign: 'center',
  width: '80px'
};

export const chipData = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Connectors',
    value: 'ingestion'
  },
  {
    label: 'Transformation',
    value: 'transformation'
  }
];

export const tableHeaderLink = [
  {
    id: 'name',
    label: 'Name',
    title: 'Name',
    type: 'string',
    display: true,
    key: 'name'
  },
  {
    id: 'collection',
    label: 'Collection',
    title: 'Collection',
    type: 'string',
    display: true,
    key: 'collection'
  },
  {
    id: 'type',
    label: 'Type',
    time: 'Type',
    type: 'string',
    display: true,
    key: 'type',
    title: 'Type'
  },
  {
    id: 'connection_status',
    title: 'Connection Status',
    label: 'Connection Status',
    type: 'string',
    display: true,
    key: 'connection_status',
    render: (column) => {
      return column.connection_status ? (
        <Box
          sx={{
            ...chipStyle,
            backgroundColor: '#EFF8E6',
            color: '#4CAF50'
          }}
        >
          Active
        </Box>
      ) : (
        <Box
          sx={{
            ...chipStyle,
            backgroundColor: '#FEE9E9',
            color: '#F44336'
          }}
        >
          Inactive
        </Box>
      );
    }
  },
  {
    id: 'last_run_date',
    title: 'Last Run Date',
    label: 'Last Run Date',
    type: 'string',
    display: true,
    key: 'last_run_date'
  },
  {
    id: 'last_run_status',
    label: 'Last Run Status',
    title: 'Last Run Status',
    type: 'string',
    display: true,
    key: 'last_run_status',
    render: (column) => {
      return <TablePopover data={column} />;
    }
  },
  {
    id: 'log_status',
    title: 'Status',
    label: 'Status',
    type: 'string',
    display: true,
    key: 'log_status'
  },
  {
    id: 'actions',
    title: 'Actions',
    label: 'Actions',
    type: 'string',
    display: true,
    key: 'actions',
    width: '10px',
    render: () => {
      return <PlayCircleIcon sx={{ cursor: 'pointer', color: '#6E6893', marginLeft: '1em' }} />;
    }
  }
  // {
  //   id: 'details',
  //   title: '',
  //   label: 'Details',
  //   display: true,
  //   key: 'details',
  //   width: '10px',
  //   render: () => {
  //     return (
  //       <MoreHorizIcon
  //         sx={{
  //           cursor: 'pointer',
  //           color: '#6E6893'
  //         }}
  //       />
  //     );
  //   }
  // }
];

export const dataSourceTableData = [
  {
    id: 1,
    name: 'Data Source 1',
    collection: 'Collection 1',
    type: 'Api',
    connection_status: true,
    last_run_date: '2021-09-01',
    last_run_status: {
      status: 'Running',
      current_running: 2,
      total_running: 5,
      remaining_running: 3,
      description: 'Mapping to asato data',
      start_time: '2021-09-01 10:00:00',
      end_time: '2021-09-01 10:00:00'
    },
    actions: 'Edit',
    log_status: true,
    details: 'Details'
  },
  {
    id: 2,
    name: 'Data Source 2',
    collection: 'Collection 2',
    type: 'File Fetcher',
    connection_status: true,
    last_run_date: '2021-09-01',
    last_run_status: {
      status: 'Success',
      current_running: 2,
      total_running: 5,
      remaining_running: 3,
      start_time: '2021-09-01 10:00:00',
      end_time: '2021-09-01 10:00:00',
      description: 'Successfully completed the Job'
    },
    actions: 'Edit',
    log_status: true,
    details: 'Details'
  },
  {
    id: 3,
    name: 'Data Source 3',
    collection: 'Collection 3',
    type: 'Manual Upload',
    connection_status: false,
    last_run_date: '2021-09-01',
    last_run_status: {
      status: 'Failed',
      current_running: 2,
      total_running: 5,
      remaining_running: 3,
      start_time: '2021-09-01 10:00:00',
      end_time: '2021-09-01 10:00:00',
      description: 'Transforming and augmentation data with addition look value.'
    },
    actions: 'Edit',
    log_status: false,
    details: 'Details'
  },
  {
    id: 4,
    name: 'Data Source 4',
    collection: 'Collection 4',
    type: 'File Fetcher',
    connection_status: true,
    last_run_date: '2021-09-01',
    last_run_status: {
      status: 'Success',
      current_running: 2,
      total_running: 5,
      remaining_running: 3,
      start_time: '2021-09-01 10:00:00',
      end_time: '2021-09-01 10:00:00',
      description: 'Successfully completed the Job'
    },
    actions: 'Edit',
    log_status: true,
    details: 'Details'
  },
  {
    id: 5,
    name: 'Data Source 5',
    collection: 'Collection 5',
    type: 'File Fetcher',
    connection_status: true,
    last_run_date: '2021-09-01',
    last_run_status: {
      status: 'Success',
      current_running: 2,
      total_running: 5,
      remaining_running: 3,
      start_time: '2021-09-01 10:00:00',
      end_time: '2021-09-01 10:00:00',
      description: 'Successfully completed the Job'
    },
    actions: 'Edit',
    log_status: true,
    details: 'Details'
  },
  {
    id: 6,
    name: 'Data Source 6',
    collection: 'Collection 6',
    type: 'Manual Upload',
    connection_status: false,
    last_run_date: '2021-09-01',
    last_run_status: {
      status: 'Failed',
      current_running: 2,
      total_running: 5,
      remaining_running: 3,
      start_time: '2021-09-01 10:00:00',
      end_time: '2021-09-01 10:00:00',
      description: 'Transforming and augmentation data with addition look value.'
    },
    actions: 'Edit',
    log_status: true,
    details: 'Details'
  },
  {
    id: 7,
    name: 'Data Source 7',
    collection: 'Collection 2',
    type: 'File Fetcher',
    connection_status: true,
    last_run_date: '2021-09-01',
    last_run_status: {
      status: 'Success',
      current_running: 2,
      total_running: 5,
      remaining_running: 3,
      start_time: '2021-09-01 10:00:00',
      end_time: '2021-09-01 10:00:00',
      description: 'Successfully completed the Job'
    },
    actions: 'Edit',
    log_status: true,
    details: 'Details'
  }
];

export const tableHeader = [
  {
    id: 'run_date',
    label: 'Run Date',
    title: 'Run Date',
    type: 'string',
    display: true,
    key: 'run_date'
  },
  {
    id: 'status',
    label: 'Status',
    title: 'Status',
    type: 'string',
    display: true,
    key: 'status'
  },
  {
    id: 'total_records',
    label: 'Total records',
    title: 'Total records',
    type: 'number',
    display: true,
    key: 'total_records'
  },
  {
    id: 'new_record_found',
    label: 'New record found',
    title: 'New record found',
    type: 'number',
    display: true,
    key: 'new_record_found'
  },
  {
    id: 'resolved_against_mkg',
    label: 'Resolved against MKG',
    title: 'Resolved against MKG',
    type: 'number',
    display: true,
    key: 'resolved_against_mkg'
  },
  {
    id: 'not_resolved',
    label: 'Not Resolved',
    title: 'Not Resolved',
    type: 'number',
    display: true,
    key: 'not_resolved'
  },
  {
    id: 'logs',
    label: 'Logs',
    title: 'Logs',
    type: 'string',
    display: true,
    key: 'logs',
    render: (column) => {
      return (
        <a href={column.logs} target="_blank" rel="noopener noreferrer">
          Logs
        </a>
      );
    }
  }
];

export const tableData = [
  {
    id: 1,
    run_date: '04/26/2025 | 9:00 AM PDT',
    status: 'Success',
    total_records: 22,
    new_record_found: 12,
    resolved_against_mkg: 5,
    not_resolved: 0,
    logs: 'Logs'
  },
  {
    id: 2,
    run_date: '04/26/2025 | 9:00 AM PDT',
    status: 'Success',
    total_records: 5,
    new_record_found: 51,
    resolved_against_mkg: 10,
    not_resolved: 5,
    logs: 'Logs'
  },
  {
    id: 3,
    run_date: '04/26/2025 | 9:00 AM PDT',
    status: 'Success',
    total_records: 7,
    new_record_found: 10,
    resolved_against_mkg: 13,
    not_resolved: 2,
    logs: 'Logs'
  },
  {
    id: 4,
    run_date: '04/26/2025 | 9:00 AM PDT',
    status: 'Failed',
    total_records: 0,
    new_record_found: 0,
    resolved_against_mkg: 0,
    not_resolved: 0,
    logs: 'Logs'
  },
  {
    id: 5,
    run_date: '04/26/2025 | 9:00 AM PDT',
    status: 'Success',
    total_records: 12,
    new_record_found: 22,
    resolved_against_mkg: 9,
    not_resolved: 8,
    logs: 'Logs'
  },
  {
    id: 6,
    run_date: '04/26/2025 | 9:00 AM PDT',
    status: 'Failed',
    total_records: 0,
    new_record_found: 0,
    resolved_against_mkg: 0,
    not_resolved: 0,
    logs: 'Logs'
  }
];
