import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

export const StyledToggleContainer = styled(Box)`
  position: relative;
  padding: 4px;
  background: #f4f8fe;
  border-radius: 8px;
  display: flex;
  width: max-content;
  flex-wrap: wrap;
  border: 1px solid #d4e4fd;
  overflow: hidden;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

// styled component for the sliding background
// Todo: Do something about responsiveness (i.e. large text handling)
export const SlidingBackground = styled(motion.div)`
  position: absolute;
  top: 5px;
  left: 5px;
  width: 130px;
  height: 80%;
  background: ${({ disabled }) => (disabled ? '#E6E7E9' : '#2a76f4')};
  border-radius: 6px;
  z-index: 1;
`;

// styled component for toggle buttons
export const StyledToggleButton = styled(Box)`
  min-width: 130px;
  padding: 10px 10px;
  border-radius: ${({ index, data }) => {
    return index === 0 ? '6px 0 0 6px' : index === data.length - 1 ? '0 6px 6px 0' : '0';
  }};
  transition: 0.3s;
  background: transparent; // background is handled by the sliding background
  color: ${({ selected, value }) => {
    return selected === value ? '#ffffff' : '#6F89B5';
  }};
  cursor: pointer;
  position: relative;
  z-index: 2; // keep the buttons above the sliding background
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;
