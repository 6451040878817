import React from 'react';
import { setSelectedCard } from 'store/slices/CategoryDetailsSlice';
import { DocumentUpload, Link } from 'iconsax-react';
import { changeStep } from 'store/slices/StepperSlice';

export const connectionMenuItems = [
  {
    label: 'API',
    icon: <Link size={20} className="icon" />,
    description: 'Connect through API integration',
    value: 'API'
  },
  {
    label: 'Manual Upload',
    icon: <DocumentUpload size={20} className="icon" />,
    description: 'Upload files manually',
    value: 'ManualUpload'
  }
];

export const handleConnectionOptionSelect = (option, navigate, dispatch) => {
  // localStorage.setItem('setRadioValue', option);

  if (option === 'ManualUpload') {
    navigate(`/link/detail?source_id=license_manual_upload&source=ingestion`);
  } else {
    navigate(`/link/${option}/connector-list`);
    // dispatch(changeStep(0));
  }

  // dispatch(setSelectedCard(null));
  sessionStorage.removeItem('selectedID');
};
