import React from 'react';
import { Box } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { SearchNormal1 } from 'iconsax-react';
import throttle from 'lodash/throttle';

const StyledInputBase = styled(InputBase)(() => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    width: '260px',
    padding: 0
  }
}));

const SearchBar = ({ onSearch }) => {
  const onChange = (e) => {
    onSearch(e.target.value);
  };

  const throttledOnChange = throttle(onChange, 1000);
  return (
    <Box
      sx={{
        padding: '12px 16px',
        bgcolor: '#F3F3F4',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        maxWidth: { md: '290px', xs: 'auto' }
      }}
    >
      <StyledInputBase placeholder="Search your query here..." inputProps={{ 'aria-label': 'search' }} onChange={throttledOnChange} />
      <SearchNormal1 size="16" />
    </Box>
  );
};

export default SearchBar;
