import React from 'react';
import { Box, Button, CircularProgress, Modal } from '@mui/material';
import { StyledBlockquote, StyledModalContainer, StyledTypography } from 'styled-components/global';

const DeleteConfirmationModal = ({ open, onClose, onConfirm, displayName, isDeleting }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalContainer padding={3} round="full">
        <StyledTypography size={'large'} weight={'bold'}>
          Are you sure you want to delete this license?
        </StyledTypography>

        {displayName && (
          <StyledBlockquote marginY={2}>
            <StyledTypography>{displayName}</StyledTypography>
          </StyledBlockquote>
        )}

        <Box mt={2} display="flex" justifyContent="flex-end" columnGap={2}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            No
          </Button>
          <Button disabled={isDeleting} variant="contained" color="primary" onClick={onConfirm}>
            {isDeleting ? <CircularProgress color="inherit" size={20} /> : 'Yes'}
          </Button>
        </Box>
      </StyledModalContainer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
