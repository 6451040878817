import { createSlice } from '@reduxjs/toolkit';

import { updateUserDetails, userListForAdmin } from 'api/api';

const initialState = {
  loading: false,
  data: {},
  error: null
};

const userListAdmin = createSlice({
  name: 'userListAdmin',
  initialState,
  reducers: {
    setUserCheckedIds: (state, action) => {
      state.checkedIds = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(userListForAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userListForAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(userListForAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updateUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserDetails.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export const { setUserCheckedIds } = userListAdmin.actions;
export default userListAdmin.reducer;
