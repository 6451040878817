import { NavLink } from 'react-router-dom';
import { AccordionSummary, Box, List } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const TAB_STYLE = {
  textDecoration: 'none',
  cursor: 'pointer',
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  padding: '6px',
  color: '#4e5663',
  position: 'relative',
  userSelect: 'none',
  '&:hover': {
    background: '#d4e4fd',
    color: '#2a76fa !important'
  },
  '&.active': {
    background: '#d4e4fd',
    color: '#2a76fa !important'
  }
};

export const StyledArrowButton = styled(Box)`
  position: absolute;
  right: -10px;
  top: 20px;
  z-index: 1000;
  color: #2a76f4;
  background-color: #eaf1fe;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #2a76f4;
    color: #fff;
  }
`;

export const StyledSidebarWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
`;

export const StyledTabWrapper = styled(motion(NavLink))({
  ...TAB_STYLE
});

export const StyledTabExternalLinkWrapper = styled(motion('a'))({
  ...TAB_STYLE
});

export const StyledTabWithChildrenWrapper = styled(motion(Box))({
  ...TAB_STYLE,
  '&:hover': {
    background: 'inherit',
    color: '#2a76fa !important'
  },
  '&.active': {
    background: 'inherit',
    color: '#2a76fa !important'
  }
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  padding: 0,
  background: 'transparent',
  margin: 0
});

export const StyledMotionDiv = styled(motion.div)({
  width: 'fit-content',
  borderRight: '1px solid #d4e4fd',
  backgroundColor: '#F4F8FE',
  overflow: 'hidden'
});

export const sidebarMotionStyle = {
  // position: 'relative',
  borderRight: '1px solid #d4e4fd',
  backgroundColor: '#F4F8FE',
  width: 'fit-content'
  // overflow: 'hidden'
};

export const StyledBetaDotWrapper = styled(Box)({
  position: 'absolute',
  top: -2,
  right: -5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#2A76F4'
});

export const StyledPopover = styled(Box)(({ open }) => ({
  // position: 'absolute',
  animation: 'appear 0.3s ease-in-out',
  '@keyframes appear': {
    from: {
      opacity: 0,
      transform: 'scale(0.95)'
    },
    to: {
      opacity: 1,
      transform: 'scale(1)'
    }
  },
  zIndex: 1000,
  transition: 'all 0.3s ease-in-out',
  width: 'fit-content',
  height: 'fit-content',
  borderRadius: 8,
  // boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
  width: open ? 'fit-content' : 0,
  opacity: open ? 1 : 0,
  top: -19,
  left: 5
}));

export const StyledList = styled(List)({
  minWidth: 150,
  borderRadius: 8,
  padding: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: 8
});

export const StyledListItem = styled(NavLink)({
  gap: 8,
  width: '100%',
  ...TAB_STYLE,
  padding: '0.5rem 0.7rem',
  '&.active': {
    color: '#2a76fa !important',
    background: '#d4e4fd'
  }
});

export const StyledPopoverItem = styled(Box)({
  borderRadius: 4,
  minWidth: 'max-content',
  width: '100%'
});

export const StyledBall = styled(Box)({
  width: 10,
  height: 10,
  borderRadius: '100%',
  backgroundColor: '#2A76F4'
});

export const DownArrowStyles = (open) => ({
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s ease'
});

export const motionDivStyles = (hover) => ({
  marginLeft: hover ? '8px' : 0,
  transformOrigin: 'left',
  overflow: 'hidden',
  display: 'inline-block'
});
