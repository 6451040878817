import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion'; // Import motion for animation

import useInternetConnection from 'hooks/useInternetConnection';

const StyledConnectionStatusBox = styled(motion(Box))({
  padding: 10,
  backgroundColor: '#FA2B2B',
  color: 'white',
  textAlign: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1001,
  maxHeight: '50px',
  transition: 'max-height 0.15s ease-out'
});

const InternetConnectionCard = () => {
  const isOnline = useInternetConnection();

  if (isOnline) return null;

  //   todo: clean it
  return (
    <StyledConnectionStatusBox
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      Internet connection lost. Please check your connection.
    </StyledConnectionStatusBox>
  );
};

export default InternetConnectionCard;
