import React, { useEffect, useState } from 'react';
// import Vector from 'assets/images/Group 1707478317.svg';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { TickSquare } from 'iconsax-react';
import moment from 'moment';

import { getAccessData, UpdateAccessData } from 'api/api';
import useToast from 'hooks/useToast';

const icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="vuesax/linear/tick-square">
      <g id="tick-square">
        <path
          id="Vector"
          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
          stroke="#2A76F4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default function UpdateUserModal({ open, handleClose, selected }) {
  const dispatch = useDispatch();
  const role = localStorage.getItem('persona');
  const loginDetails = JSON.parse(localStorage.getItem('userDetails'));
  const userCustomerId = loginDetails?.user?.customer?.id;
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    displayName: '',
    employeeEmailId: '',
    jobTitle: '',
    selectedRoles: [],
    createdTimestamp: '',
    employeeId: '',
    id: ''
  });

  const [validationErrors, setValidationErrors] = useState({
    displayName: '',
    employeeEmailId: '',
    jobTitle: '',
    selectedRoles: ''
  });

  const { showToast, Toast } = useToast();

  const checkedIcon = <TickSquare size="24" color="#2A76F4" variant="Bold" />;
  useEffect(() => {
    if (selected) {
      setUserDetails({
        employeeEmailId: selected?.employeeEmailId || '',
        displayName: selected?.displayName || '',
        createdTimestamp: selected?.createdTimestamp || '',
        jobTitle: selected?.jobTitle || '',
        employeeId: selected?.employeeId || '',
        // todo : make  item[name] dynamic
        selectedRoles: selected?.personas?.map((item) => item.name) || [],
        id: selected?.id || ''
      });
    }
  }, [selected]);
  const rolesInfo = {
    executive: 'This role is responsible for making decisions regarding the reports generated and assigning people to work on them.',
    admin: 'This role is responsible for managing system settings and user permissions.',
    user: 'This role is primarily focused on day-to-day operations within the system.'
    // 'user & admin': 'This role combines responsibilities of both User and Admin roles.'
  };

  const handleSubmit = () => {
    setLoading(true);
    const errors = {};

    if (!userDetails.employeeEmailId.trim()) {
      errors.employeeEmailId = 'User Email is required';
    }

    if (userDetails.selectedRoles.length === 0) {
      errors.selectedRoles = 'Please select at least one role';
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setLoading(false);
      return;
    }

    const details = {
      id: userDetails?.id,
      displayName: userDetails?.displayName,
      employeeEmailId: userDetails?.employeeEmailId,
      jobTitle: userDetails?.jobTitle,
      customerId: userCustomerId,
      userPersonas: userDetails?.selectedRoles?.map((role) => role.toLowerCase()),
      createdTimestamp: new Date(),
      updatedTimestamp: new Date()
    };

    const useDetail = {
      customerId: userCustomerId,
      fromDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      screen: 'accessmanagement',
      section: 'details',
      persona: role
    };

    dispatch(UpdateAccessData(details))
      .then((res) => {
        if (res?.payload?.status === 200) {
          showToast('User Updated Successfully', { severity: 'success', duration: 2000 });
          handleClose();
          setTimeout(() => {
            dispatch(getAccessData(useDetail));
            // .then((res) => {
            // if (res.payload.data) {
            //   handleUpdateUser(selected?.id, res.payload.data);
            //   setLoading(false);
            // }
            // });
          }, 2000);
        } else {
          showToast('Something went wrong', { severity: 'error', duration: 2000 });
          setLoading(false);
        }
      })
      .catch(() => {
        showToast('Something went wrong', { severity: 'error', duration: 2000 });
        setLoading(false);
      });
  };

  const closeModal = () => {
    setUserDetails({
      displayName: '',
      employeeEmailId: '',
      jobTitle: '',
      selectedRoles: [],
      createdTimestamp: '',
      employeeId: '',
      id: ''
    });
    handleClose();
  };

  // const handleDisplayNameChange = (e) => {
  //   setUserDetails({ ...userDetails, displayName: e.target.value });
  //   if (validationErrors.displayName) {
  //     setValidationErrors({ ...validationErrors, displayName: '' });
  //   }
  // };

  // const handleEmployeeEmailIdChange = (e) => {
  //   setUserDetails({ ...userDetails, employeeEmailId: e.target.value });
  //   if (validationErrors.employeeEmailId) {
  //     setValidationErrors({ ...validationErrors, employeeEmailId: '' });
  //   }
  // };

  const handleJobTitleChange = (e) => {
    setUserDetails({ ...userDetails, jobTitle: e.target.value });
    if (validationErrors.jobTitle) {
      setValidationErrors({ ...validationErrors, jobTitle: '' });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="add-user-modal"
        aria-describedby="add-user-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            width: 550,
            height: 'auto',
            maxHeight: 650,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: '24px 32px',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            overflowY: 'auto'
          }}
        >
          <Typography id="modal-modal-title" sx={{ fontSize: '18px', fontWeight: 'bold' }} variant="h4">
            Update User
          </Typography>
          {/* <TextField
            disabled
            label="Username"
            variant="outlined"
            fullWidth
            placeholder="Enter Name"
            value={userDetails?.displayName}
            onChange={handleDisplayNameChange}
            error={!!validationErrors.displayName}
            helperText={validationErrors.displayName}
          />
          <TextField
            disabled
            label="User Email"
            variant="outlined"
            fullWidth
            placeholder="Enter Email"
            value={userDetails?.employeeEmailId}
            onChange={handleEmployeeEmailIdChange}
            error={!!validationErrors.employeeEmailId}
            helperText={validationErrors.employeeEmailId}
          /> */}
          <TextField
            label="Organizational Role"
            variant="outlined"
            fullWidth
            placeholder="Enter Role"
            value={userDetails?.jobTitle}
            onChange={handleJobTitleChange}
            error={!!validationErrors.jobTitle}
            helperText={validationErrors.jobTitle}
          />
          <Autocomplete
            multiple
            id="checkboxes-tags-demo1"
            options={Object.keys(rolesInfo)}
            value={(userDetails?.selectedRoles || []).map((i) => i.toLowerCase())}
            onChange={(event, newValue) => {
              setUserDetails({ ...userDetails, selectedRoles: newValue });
              if (validationErrors.selectedRoles) {
                setValidationErrors({ ...validationErrors, selectedRoles: '' });
              }
            }}
            disableCloseOnSelect
            fullWidth
            renderOption={(props, option, state) => (
              <li {...props} style={{ textTransform: 'capitalize' }}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={state.selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Asato Role"
                placeholder="Select or type to add"
                error={!!validationErrors.selectedRoles}
                helperText={validationErrors.selectedRoles}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={option}
                  sx={{
                    bgcolor: '#EAF1FE',
                    color: '#2A76F4',
                    '& .MuiChip-deleteIcon': {
                      color: '#2A76F4'
                    }
                  }}
                  {...getTagProps({ index })}
                  onDelete={() => {
                    const newSelectedRoles = [...userDetails.selectedRoles];
                    newSelectedRoles.splice(index, 1);
                    setUserDetails({ ...userDetails, selectedRoles: newSelectedRoles });
                  }}
                  deleteIcon={<CloseIcon />}
                />
              ))
            }
          />

          {/* {userDetails?.selectedRoles &&
            userDetails?.selectedRoles.map((role) => (
              <Box key={role} sx={{ bgcolor: '#f0f0f0', p: 2, borderRadius: '8px', display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: '32px',
                    height: '32px',
                    padding: '10px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#6F89B5',
                    mr: '10px'
                  }}
                >
                  <img src={Vector} alt={`${role} icon`} style={{ width: '100%', height: '100%' }} />
                </Box>
                <Box>
                  <Typography variant="h5">{role}: </Typography>
                  <Typography color={'#4E5663'} variant="body2">
                    {rolesInfo[role.toLowerCase()]}
                  </Typography>
                </Box>
              </Box>
            ))} */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', mt: 2 }}>
            <Button onClick={closeModal} variant="outlined">
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ bgcolor: '#2A76F4' }}
              onClick={handleSubmit}
              disabled={userDetails.selectedRoles.length === 0 || loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Update'}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Toast />
    </>
  );
}
