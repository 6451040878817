import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';

import OnHoverToolTip from 'ui-component/Tooltips/OnHoverToolTip';

function TablePopover() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const RenderStepper = () => {
    return (
      <Box>
        <Typography>Job completed successfully</Typography>
        <Typography>New Record: #</Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '5px'
      }}
    >
      <OnHoverToolTip title={<RenderStepper />} placement="bottom-end" variant="icon">
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      </OnHoverToolTip>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 2, backgroundColor: 'black', color: 'white' }}>
          <RenderStepper />
        </Box>
      </Popover>
    </Box>
  );
}

export default TablePopover;
