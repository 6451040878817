import React, { useCallback, useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ChangePassword } from '../../api/api';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '16px',
    fontWeight: 600,
    paddingTop: 15
  },
  label: {
    marginTop: '12px',
    fontWeight: 400,
    paddingTop: 15,
    color: '#353D4D'
  },
  validation: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    marginTop: '5px',
    fontSize: '12px',
    fontWeight: 500
  },
  circle: {
    fontSize: 8
  },
  box: {
    float: 'right',
    margin: '10px 0'
  }
}));

const PasswordChange = () => {
  const classes = useStyles();
  const [password, setPassword] = useState({ new: '', confirm: '' });
  const [validation, setValidation] = useState({ case1: false, case2: false, case3: false, case4: false });
  const [showPassword, setShowPassword] = useState({ new: true, confirm: true });
  const [isPasswordWrong, setIsPasswordWrong] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    const patterns = {
      case1: value.length > 8,
      case2: /\d/.test(value),
      case3: /[!@#$%^&*(),.?":{}|<>-]/.test(value),
      case4: /[A-Z]/.test(value)
    };

    if (name === 'new') {
      setValidation({
        case1: patterns.case1,
        case2: patterns.case2,
        case3: patterns.case3,
        case4: patterns.case4
      });
    }

    setPassword((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleClickShowPassword = useCallback((name) => {
    setShowPassword((prev) => ({ ...prev, [name]: !prev[name] }));
  }, []);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleReset = async () => {
    if (password?.new !== password?.confirm) {
      setIsPasswordWrong(true);
      return;
    }
    setIsLoading(true);
    try {
      const encodedNewPassword = password.new;
      const response = await ChangePassword('', encodedNewPassword);
      if (response.ok) {
        setSuccessMessage('Password changed successfully.');
        localStorage.clear();
        window.location.reload();
        setErrorMessage('');
        setPassword({
          new: '',
          confirm: ''
        });
        setIsPasswordWrong(false);
      } else {
        const errorMessage = await response.json();
        setErrorMessage(errorMessage.error);
        setSuccessMessage('');
      }
    } catch {
      setErrorMessage('Failed to change password.');
      setSuccessMessage('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setPassword({ new: '', confirm: '' });
    setValidation({ case1: false, case2: false, case3: false, case4: false });
    setIsPasswordWrong(false);
    setErrorMessage('');
    setSuccessMessage('');
  };

  const isFormValid = () => {
    return (
      password.new &&
      password.confirm &&
      // password.new === password.confirm &&
      validation.case1 &&
      validation.case2 &&
      validation.case3 &&
      validation.case4
    );
  };

  return (
    <>
      <Grid mx={2}>
        <Typography className={classes.title}>Password</Typography>

        <InputLabel className={classes.label}>New Password</InputLabel>
        <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-password"
            placeholder="New Password"
            name="new"
            value={password?.new}
            type={showPassword.new ? 'password' : 'text'}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('new')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword?.new ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Grid container my={0}>
          <Grid
            item
            xs={6}
            className={classes.validation}
            sx={{ color: validation?.case1 ? 'black' : '#a9a9a9', fontWeight: validation?.case1 ? 600 : 400 }}
          >
            <CircleIcon color={validation?.case1 ? 'primary' : ''} className={classes.circle} /> 8 characters minimum
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.validation}
            sx={{ color: validation?.case2 ? 'black' : '#a9a9a9', fontWeight: validation?.case2 ? 600 : 400 }}
          >
            <CircleIcon color={validation?.case2 ? 'primary' : ''} className={classes.circle} /> One number
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.validation}
            sx={{ color: validation?.case3 ? 'black' : '#a9a9a9', fontWeight: validation?.case3 ? 600 : 400 }}
          >
            <CircleIcon color={validation?.case3 ? 'primary' : ''} className={classes.circle} /> One special character
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.validation}
            sx={{ color: validation?.case4 ? 'black' : 'grey', fontWeight: validation?.case4 ? 600 : 400 }}
          >
            <CircleIcon color={validation?.case4 ? 'primary' : ''} className={classes.circle} /> One uppercase character
          </Grid>
        </Grid>

        <InputLabel className={classes.label}>Confirm New Password</InputLabel>
        <FormControl sx={{ my: 1, width: '100%' }} variant="outlined">
          <OutlinedInput
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: isPasswordWrong ? '1px solid red !important' : '1px solid rgba(0, 0, 0, 0.23) !important'
              }
            }}
            placeholder="Confirm New Password"
            htmlFor="component-error"
            id="component-error"
            aria-describedby="component-error-text"
            name="confirm"
            type={showPassword?.confirm ? 'password' : 'text'}
            value={password?.confirm}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('confirm')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword?.confirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {isPasswordWrong && (
            <FormHelperText id="component-error-text" sx={{ color: 'red' }}>
              Password does not match
            </FormHelperText>
          )}
        </FormControl>

        {errorMessage && (
          <FormHelperText id="component-error-text" sx={{ color: 'red' }}>
            {errorMessage}
          </FormHelperText>
        )}
        {successMessage && (
          <FormHelperText id="component-success-text" sx={{ color: 'green' }}>
            {successMessage}
          </FormHelperText>
        )}

        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box className={classes.box}>
            <Button sx={{ p: '6px 16px', mr: 2, border: '1px solid #757575', color: '#757575' }} variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button sx={{ p: '8px 16px' }} variant="contained" onClick={handleReset} disabled={!isFormValid()}>
              Reset Password
            </Button>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default PasswordChange;
