import { createSlice } from '@reduxjs/toolkit';

import { getDetailedTableV2 } from '../../api/api';

const initialState = {
  tables: {},
  loading: false,
  error: ''
};

const insightsTableSlice = createSlice({
  name: 'insights table',
  initialState,
  reducers: {
    clearTableData: (state, action) => {
      const tableId = action.payload;
      if (tableId) {
        delete state.tables[tableId];
      } else {
        state.tables = {};
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getDetailedTableV2.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDetailedTableV2.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const tableId = action.meta.arg.id;

        state.tables[tableId] = action.payload || {
          columns: [],
          data: [],
          page_size: 0,
          page_number: 0,
          totalCount: 0,
          totalPages: 0,
          tableTitle: action.payload?.tableTitle
        };
      })
      .addCase(getDetailedTableV2.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;

        const tableId = action.meta.arg.id;

        state.tables[tableId] = {
          columns: [],
          data: [],
          page_size: 0,
          page_number: 0,
          totalCount: 0,
          totalPages: 0
        };
      });
  }
});

export const { clearTableData } = insightsTableSlice.actions;
export default insightsTableSlice.reducer;
