import React, { useState } from 'react';
import { useCallback, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DocumentDownload, TickSquare } from 'iconsax-react';
import debounce from 'lodash.debounce';

import { searchApi } from 'api/taskManagement.service';
import { REACT_QUILL_FORMATS } from 'constants/constants';
import { REACT_QUILL_CONFIG } from 'constants/constants';
import { StyledAutocompletePaper } from 'styled-components/global';
import MyDropzone from 'ui-component/DragNDrop';
import ToggleTabs from 'ui-component/Toggle';
import { formatDate } from 'utils';
// import { currencyFormatter } from 'utils/index';

const LabelDisplay = ({ label, required, forEle }) => {
  return (
    <label
      htmlFor={forEle}
      style={{
        fontSize: '14px',
        fontWeight: 600,
        marginBottom: '8px'
      }}
    >
      <b>{label}</b>
      {required && <span style={{ color: 'red' }}>*</span>}
    </label>
  );
};

// todo: move this somewhere else
const icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="vuesax/linear/tick-square">
      <g id="tick-square">
        <path
          id="Vector"
          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
          stroke="#2A76F4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

const deleteIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clipPath="url(#clip0_963_8076)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99566 10.0612L13.2383 14.3038C13.5311 14.5967 14.006 14.5967 14.2989 14.3038C14.5918 14.0109 14.5918 13.5361 14.2989 13.2431L10.0563 9.00052L14.2989 4.75788C14.5918 4.46499 14.5918 3.99011 14.2989 3.69722C14.006 3.40432 13.5311 3.40432 13.2383 3.69722L8.99566 7.93987L4.75298 3.69721C4.46009 3.40432 3.98521 3.40432 3.69232 3.69721C3.39943 3.9901 3.39943 4.46497 3.69232 4.75787L7.93494 9.00052L3.69232 13.2432C3.39943 13.5361 3.39943 14.0109 3.69232 14.3038C3.98521 14.5967 4.46009 14.5967 4.75298 14.3038L8.99566 10.0612Z"
        fill="#2A76F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_963_8076">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

// todo: move this somewhere else
const handleRenderTags = (value, getTagProps) =>
  value?.map((option, index) => {
    return (
      <Chip
        sx={{
          background: '#EAF1FE',
          borderRadius: '8px',
          border: '1px solid #2A76F4',
          padding: '6px 8px 6px 12px',
          '& .MuiChip-label': {
            padding: 0,
            mr: 1.5,
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            color: '#2A76F4',
            textTransform: 'capitalize'
          }
        }}
        label={option.label}
        key={index}
        {...getTagProps({ index })}
        deleteIcon={deleteIcon}
      />
    );
  });

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  limit: 500
});

const FormInput = (props) => {
  const { formik, type, name, labelText, required, handleChange, handleDownload, loading, isEditable = true, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);

  const inputDisableProps = {
    disabled: !isEditable
  };

  const handleToggleVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
  const fetchOptions = async (searchValue) => {
    if (searchValue === inputValue && searchValue !== '') return;
    if (!props?.apiPath || !props?.searchKey) return;
    try {
      setAutoCompleteLoading(true);
      const response = await searchApi(searchValue, props.apiPath, props.searchKey);
      setOptions(response.data || []);
    } catch (error) {
      console.error('Error fetching options:', error);
    } finally {
      setAutoCompleteLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 300), [props?.apiPath, props?.searchKey]);

  useEffect(() => {
    // if (inputValue) {
    debouncedFetchOptions(inputValue);
    // }
  }, [inputValue, debouncedFetchOptions]);

  switch (type) {
    case 'email':
    case 'text':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />

          <TextField
            fullWidth
            variant={'outlined'}
            label={null}
            id={name}
            name={name}
            value={formik.values[name]}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            {...inputDisableProps}
            {...props}
          />
        </Box>
      );

    case 'number':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />

          <TextField
            fullWidth
            variant={'outlined'}
            label={null}
            id={name}
            name={name}
            type="number"
            inputProps={{
              min: 0,
              onKeyDown: (e) => {
                if (e.key === '-') {
                  e.preventDefault();
                }
              }
            }}
            value={formik.values[name]}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            {...inputDisableProps}
            {...props}
            onWheel={(e) => e.target.blur()}
          />
        </Box>
      );

    case 'secret':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <TextField
            fullWidth
            variant={'outlined'}
            id={name}
            name={name}
            type={showPassword ? 'text' : 'password'}
            value={formik.values[name]}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleToggleVisibility} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            {...rest}
          />
        </Box>
      );

    case 'password':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <TextField
            fullWidth
            variant={'outlined'}
            id={name}
            name={name}
            value={formik.values[name]}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            {...props}
          />
        </Box>
      );

    case 'text-area':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} />
          <TextField
            fullWidth
            multiline
            variant={'outlined'}
            id={name}
            name={name}
            value={formik.values[name]}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            {...props}
          />
        </Box>
      );
    case 'date':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id={name}
              value={formik.values[name] ? dayjs(formik.values[name]) : null}
              onChange={(newValue) => {
                const formattedDate = formatDate(newValue);
                formik.setFieldValue(name, formattedDate);
                if (handleChange) {
                  handleChange({
                    target: { name, value: formattedDate }
                  });
                }
              }}
              disablePast={props.disablePast || false}
              slotProps={{
                textField: {
                  error: Boolean(formik.errors[name]),
                  helperText: formik.errors[name] ? `${labelText} is required` || formik.errors[name] : undefined
                }
              }}
              {...props}
            />
          </LocalizationProvider>
        </Box>
      );

    case 'currency':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <TextField
            fullWidth
            variant={'outlined'}
            id={name}
            name={name}
            value={formik.values[name]}
            onChange={(e) => {
              // Remove any non-numeric characters (except the decimal)
              const cleanedValue = e.target.value.replace(/[^\d.-]/g, '');

              // Convert the cleaned value to a number and format it as currency
              const formattedValue = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: props?.currencyType || 'USD',
                maximumFractionDigits: 2
              }).format(cleanedValue || 0);

              formik.setFieldValue(name, formattedValue);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       {props?.currencyType ? currencyFormatter(props.currencyType).format(0).charAt(0) : '$'}
            //     </InputAdornment>
            //   )
            // }}
            {...props}
          />
        </Box>
      );

    case 'wysiwyg':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <ReactQuill
            id={name}
            className={`${'custom-quill-editor'} ${formik.errors[name] && 'quill-err'}`}
            theme="snow"
            modules={REACT_QUILL_CONFIG}
            formats={REACT_QUILL_FORMATS}
            value={formik.values[name]}
            onChange={(content) => formik.setFieldValue(name, content)}
            {...props}
          />
          {/* todo : handle onblur situation */}
          {formik.errors[name] && <FormHelperText error>{formik.errors[name]}</FormHelperText>}
        </Box>
      );

    case 'autocomplete':
      return (
        <Box sx={{ opacity: loading ? 0.5 : 1 }} display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <Autocomplete
            {...props}
            filterOptions={filterOptions}
            value={props?.multiple ? formik.values[name]?.displayName : formik.values[name]}
            onChange={(event, newValue) => {
              formik.setFieldValue(name, props?.multiple ? newValue.map((item) => item.value) : newValue?.value);
            }}
            onBlur={formik.handleBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  required: props?.multiple ? formik.values[name]?.length === 0 : required
                }}
                name={name}
                error={Boolean(formik.errors[name])}
                helperText={formik.errors[name] ? formik.errors[name] : ''}
              />
            )}
          />
        </Box>
      );

    case 'autocomplete-with-api':
      return (
        <Box sx={{ opacity: loading ? 0.5 : 1 }} display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <Autocomplete
            {...props}
            noOptionsText="Type to search"
            options={options}
            value={formik.values[name] || (props?.multiple ? [] : null)}
            onInputChange={(event, newInputValue) => {
              if (event?.type === 'change') {
                setInputValue(newInputValue);
                debouncedFetchOptions(newInputValue);
              }
            }}
            onFocus={() => {
              if (!inputValue) {
                debouncedFetchOptions('');
              }
            }}
            onChange={(event, newValue) => {
              formik.setFieldValue(name, props?.multiple ? newValue || [] : newValue || null);
            }}
            onBlur={formik.handleBlur}
            loading={autoCompleteLoading}
            PaperComponent={(props) => <StyledAutocompletePaper {...props} />}
            getOptionLabel={(option) => option?.userName || option?.assetName || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  required: props?.multiple ? formik.values[name]?.length === 0 : required
                }}
                name={name}
                error={Boolean(formik.errors[name])}
                helperText={formik.errors[name] ? formik.errors[name] : ''}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {autoCompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </Box>
      );

    case 'select':
      return (
        <Box sx={{ opacity: loading ? 0.5 : 1 }} display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                zIndex: 1
              }}
            />
          )}
          <Box width={'100%'}>
            <Select
              id={name}
              value={formik.values[name]}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              sx={{ width: 'inherit' }}
              error={Boolean(formik.errors[name])}
              helperText={formik.errors[name] ? formik.errors[name] : ''}
              disabled={!isEditable}
              {...props}
            >
              {props?.options?.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                  sx={{
                    color: props.MenuProps ? 'rgba(0, 0, 0, 0.38)' : 'inherit'
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {formik.errors[name] && (
              <FormHelperText sx={{ marginLeft: 1.8 }} error>
                {formik.errors[name]}
              </FormHelperText>
            )}
          </Box>
        </Box>
      );

    case 'drag-n-drop':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <MyDropzone id={name} {...props} supportedFormats={props.supportedFormats} />
          {!props?.uploadedFiles?.length && formik?.errors[name] && <FormHelperText error>{formik.errors[name]}</FormHelperText>}
        </Box>
      );

    case 'download':
      return (
        <Box display={'flex'} sx={{ cursor: 'pointer' }} flexDirection={'column'} gap={1} onClick={handleDownload}>
          <Typography
            variant="p"
            color="primary"
            display="flex"
            alignItems="center"
            gap={1}
            fontWeight={500}
            fontSize={14}
            sx={{ textDecoration: 'underline' }}
          >
            Download sample format <DocumentDownload size="18" />
            CSV
          </Typography>
        </Box>
      );

    case 'tag-input':
      return (
        // todo : fix this shit!
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <Autocomplete
            {...props}
            multiple
            renderTags={handleRenderTags}
            renderOption={(renderProps, option, state) => (
              // todo: checkbox must be optional
              <li {...renderProps} style={{ textTransform: 'capitalize' }}>
                <Checkbox
                  icon={icon}
                  checkedIcon={<TickSquare size="24" color="#2A76F4" variant="Bold" />}
                  style={{ marginRight: 8 }}
                  checked={state.selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...props}
                {...params}
                value={formik.values[name]}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors[name]}
                helperText={`${labelText} is required` || formik.errors[name]}
              />
            )}
          />
        </Box>
      );

    case 'radio-group':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            {props?.options?.map((option, index) => (
              <Box display={'flex'} alignItems={'center'} key={index}>
                <input
                  type="radio"
                  id={`${name}-${index}`}
                  name={name}
                  value={option.value}
                  checked={formik.values[name] === option.value}
                  onChange={handleChange}
                  style={{ cursor: 'pointer' }}
                />
                <label htmlFor={`${name}-${index}`} style={{ marginLeft: '8px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}>
                  {option.label}
                </label>
              </Box>
            ))}
          </Box>
          {formik.errors[name] && <FormHelperText error>{formik.errors[name]}</FormHelperText>}
        </Box>
      );

    case 'switch':
      return (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <LabelDisplay label={labelText} required={required} forEle={name} />
          <ToggleTabs
            data={props.options}
            selected={formik.values[name]}
            onChange={(val) => handleChange({ target: { name, value: val } })}
            setSelect={(val) => formik.setFieldValue(name, val)}
            {...props}
          />
          {formik.errors[name] && <FormHelperText error>{formik.errors[name]}</FormHelperText>}
        </Box>
      );
  }
};

export default FormInput;
