import { useState } from 'react';
import { useParams } from 'react-router';
import { Box, Button, CircularProgress, Modal } from '@mui/material';

import { deleteAction, deleteTask } from 'api/taskManagement.service';
import useNotification from 'hooks/useNotifications';
import { StyledBlockquote, StyledModalContainer, StyledTypography } from 'styled-components/global';
import { transformText } from 'utils';
import { useFunctionContext } from 'views/common/FunctionContext';

const DeleteModal = ({ handleRefreshTable, open, setOpen, row, variant }) => {
  const { error: errorNotification, success: successNotification } = useNotification();
  const { sharedFunction } = useFunctionContext() || {};
  const { handleRefreshHybridCards, handleRefreshCards } = sharedFunction || '';
  if (!variant) {
    errorNotification('Variant is required', 3000);
  }
  const variantToApiCall = {
    action: deleteAction,
    task: deleteTask
  };
  const variantToNameKey = {
    action: 'actionDescription',
    task: 'name'
  };
  const variantToTaskId = (row) => {
    if (variant === 'action') {
      return taskId;
    }
    return row?.id;
  };
  const [submitting, setSubmitting] = useState(false);
  const { taskId } = useParams();
  const handleDelete = () => {
    setSubmitting(true);
    variantToApiCall[variant]({ task_id: variantToTaskId(row), action_id: row?.id })
      .then((res) => {
        if (res?.status === 200) {
          successNotification(`${transformText({ text: variant, style: 'capitalize' })} deleted Successfully`, 3000);
          handleRefreshTable();
          if (handleRefreshCards && variant === 'task') {
            handleRefreshCards();
          }
          if (handleRefreshHybridCards && variant === 'action') {
            handleRefreshHybridCards('getTaskChartData');
            handleRefreshHybridCards('getTaskLogs');
            handleRefreshHybridCards('getTaskInfo');
          }
        }
      })
      .catch((err) => {
        errorNotification(err?.message, 3000);
      })
      .finally(() => {
        setSubmitting(false);
        setOpen(false);
      });
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <StyledModalContainer padding={3} round="full">
        <StyledTypography size={'large'} weight={'bold'}>
          Are you sure you want to delete this {variant}?
        </StyledTypography>
        {row?.name && (
          <StyledBlockquote marginY={2}>
            <StyledTypography>{row[variantToNameKey[variant]]}</StyledTypography>
          </StyledBlockquote>
        )}
        <Box mt={2} display="flex" justifyContent="flex-end" columnGap={2}>
          <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
            No
          </Button>
          <Button disabled={submitting} variant="contained" color="primary" onClick={handleDelete}>
            {submitting ? <CircularProgress color="inherit" size={20} /> : 'Yes'}
          </Button>
        </Box>
      </StyledModalContainer>
    </Modal>
  );
};

export default DeleteModal;
