import React, { useContext } from 'react';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid';
import { RefreshCircle } from 'iconsax-react';

import { DEFAULT_TOOLBAR_CONFIG_OVERRIDES } from 'constants/constants';
import AskAsatoWidgetToolbar from 'ui-component/toolbar-components/ask-asato-widget';
import CreateActionToolbar from 'ui-component/toolbar-components/create-action';
import NewEntitlements from 'ui-component/toolbar-components/new-entitlements';
import RunHistoryToolbar from 'ui-component/toolbar-components/run-history';

import { DownloadContext } from './CommonTable';

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt'
);

export const buttonBaseProps = {
  color: 'primary',
  size: 'small',
  startIcon: <ExportIcon />
};

const styles = {
  tooltipButton: {
    color: '#2A76FA',
    '&:hover': {
      backgroundColor: 'rgba(42, 118, 250, 0.04)'
    }
  }
};

const RefreshButton = ({ onClick }) => (
  <Tooltip title="Refresh" variant="children" placement="bottom">
    <IconButton onClick={onClick} sx={styles.tooltipButton}>
      <RefreshCircle size="24" />
    </IconButton>
  </Tooltip>
);

const CustomToolbar = ({ title = '', config = [], onRefresh }) => {
  const { handleDownload, disableExport, apiCallOnPage, req_params } = useContext(DownloadContext) || {};

  const handleRefresh = () => {
    if (apiCallOnPage && req_params) {
      const refreshParams = {
        ...req_params,
        has_page_count: true
      };
      apiCallOnPage(refreshParams);
    }
    if (onRefresh) {
      onRefresh();
    }
  };

  const toolbarConfig = [
    {
      key: 'filter',
      component: <GridToolbarFilterButton />,
      position: 'left'
    },
    {
      key: 'columns',
      component: <GridToolbarColumnsButton />,
      position: 'left'
    },
    {
      key: 'density',
      component: <GridToolbarDensitySelector />,
      position: 'left'
    },
    {
      key: 'hasRefresh',
      component: <RefreshButton onClick={handleRefresh} />,
      position: 'left'
    },
    {
      key: 'ask-asato-widget',
      component: <AskAsatoWidgetToolbar />,
      position: 'right'
    },
    {
      key: 'create-action',
      component: <CreateActionToolbar />,
      position: 'right'
    },
    {
      key: 'new-entitlements',
      component: <NewEntitlements />,
      position: 'right'
    },
    {
      key: 'run-history',
      component: <RunHistoryToolbar />,
      position: 'right'
    },
    {
      key: 'download',
      component: !disableExport && (
        <Tooltip title="Download CSV" variant="children" placement="bottom">
          <Button {...buttonBaseProps} onClick={handleDownload} sx={styles.tooltipButton}>
            Download
          </Button>
        </Tooltip>
      ),
      position: 'right'
    }
  ];

  const findAndReturnToolbarConfig = ({ arr = [], key }) => {
    if (!Array.isArray(arr)) return null;
    return arr?.find((item) => item.value === key);
  };

  return (
    <GridToolbarContainer sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      {title ? (
        <Box px={2} py={1}>
          <Typography variant="h6" fontWeight={500}>
            {title}
          </Typography>
        </Box>
      ) : null}
      <Box display={'flex'} width={'100%'}>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          {toolbarConfig.map((item) => {
            const configItem =
              findAndReturnToolbarConfig({ arr: DEFAULT_TOOLBAR_CONFIG_OVERRIDES, key: item.key }) ||
              findAndReturnToolbarConfig({ arr: config, key: item.key });
            return item.position === 'left' && configItem ? (
              <Box key={`left-${item.key}`}>{React.cloneElement(item.component, { configItem })}</Box>
            ) : null;
          })}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {toolbarConfig.map((item) => {
            const configItem =
              findAndReturnToolbarConfig({ arr: DEFAULT_TOOLBAR_CONFIG_OVERRIDES, key: item.key }) ||
              findAndReturnToolbarConfig({ arr: config, key: item.key });
            return item.position === 'right' && configItem && item.component ? (
              <Box key={`right-${item.key}`}>{React.cloneElement(item.component, { configItem })}</Box>
            ) : null;
          })}
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
