export const STATUS_COLOR = {
  Accepted: 'warning',
  Assigned: 'warning',
  'In-Progress': 'primary',
  Completed: 'success',
  'Completed (With Failures)': 'success',
  'Completed (Success)': 'success',
  Rejected: 'error',
  Failure: 'error',
  Success: 'success',
  'Pending Approval': 'warning',
  Medium: 'warning',
  High: 'error',
  Low: 'success',
  'Initiate Review': '#08873B', // Green
  'Review Pending': '#CF7C16', // Orange
  'Review Approved': '#08873B', // Green
  'Review In Progress': '#2A76F4',
  Discarded: 'error',
  Cancelled: 'error',
  Pending: 'warning',
  failed: '#EA4141',
  success: '#4CAF50'
};
