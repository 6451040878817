import React from 'react';
import { useCallback } from 'react';
import { Box, InputAdornment } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { SearchNormal1 } from 'iconsax-react';
import { debounce } from 'lodash';
import throttle from 'lodash/throttle';

const StyledInputBase = styled(InputBase)(() => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    width: '260px',
    padding: 0,
    borderRadius: '0px'
  }
}));

const SearchBar = ({ onSearch, placeholder }) => {
  const displayPlaceholder = placeholder ? `Search by ${placeholder}` : 'Search...';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(
    debounce((e) => {
      onSearch(e.target.value);
    }, 1000),
    []
  );

  const throttledOnChange = throttle(onChange, 1000);
  return (
    <Box
      sx={{
        padding: '12px 16px',
        bgcolor: '#F3F3F4',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        maxWidth: { md: '290px', xs: 'auto' }
      }}
    >
      <StyledInputBase
        startAdornment={
          <InputAdornment position="start">
            <SearchNormal1 size="16" color="#7685A3" />
          </InputAdornment>
        }
        placeholder={displayPlaceholder}
        inputProps={{ 'aria-label': 'search' }}
        onChange={throttledOnChange}
      />
    </Box>
  );
};

export default SearchBar;
