import React, { useEffect, useRef, useState } from 'react';
import { FormControl, FormLabel, TextField, Typography } from '@mui/material';

const createFilterOperator = (dataType) => ({
  label: 'contains',
  value: 'contains',
  getApplyFilterFn: (filterItem) => {
    if (!filterItem.columnField || !filterItem.value) return null;
    return ({ value }) => value?.toString()?.includes(filterItem.value);
  },
  InputComponent: ({ item, applyValue }) => {
    const [filterValue, setFilterValue] = useState(item.value || '');
    const inputRef = useRef();

    useEffect(() => {
      inputRef.current?.focus();
    }, [filterValue]);

    const handleChange = (event) => {
      const newValue = event.target.value;
      setFilterValue(newValue);
      applyValue({ ...item, value: newValue });
    };

    return (
      <FormControl fullWidth>
        <FormLabel>
          <Typography fontSize="12px">Value</Typography>
        </FormLabel>
        <TextField
          inputRef={inputRef}
          variant="standard"
          value={filterValue}
          onChange={handleChange}
          placeholder={dataType === 'date' ? 'yyyy-mm-dd' : 'Filter value'}
          InputProps={{ style: { padding: 0 } }}
          fullWidth
        />
      </FormControl>
    );
  }
});

const customFilterOperators = (dataType) => [createFilterOperator(dataType)];

export default customFilterOperators;
