import React from 'react';
import Breadcrump from 'ui-component/Breadcrumps';
// import CatalogDetailCard from '../components/CatalogDetailCard'
import { useParams } from 'react-router';
import { Grid } from '@mui/material';

import Table from 'ui-component/Sections/Table';

function CatalogList() {
  const { id: catalogId } = useParams();

  const breadcrumbList = [
    {
      label: 'Datasets',
      path: '/datasets'
    },
    {
      label: 'Catalog',
      path: '/datasets/catalogs'
    },
    {
      label: catalogId?.toUpperCase(),
      path: `/datasets/catalogs/${catalogId}`
    }
  ];

  return (
    <div style={{ padding: '20px' }}>
      <div item xs={12}>
        <Breadcrump breadcrumbList={breadcrumbList} />
      </div>

      <div>
        <Table screen="catalogs" />
      </div>
    </div>
  );
}

export default CatalogList;
