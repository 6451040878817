export const styles = {
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500]
  },
  buttonContainer: {
    gap: 2,
    marginTop: 3,
    width: '50%',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: '90vh',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column'
  },
  headerSection: {
    position: 'sticky',
    top: 0,
    padding: 3,
    paddingBottom: 2
  },
  contentSection: {
    padding: 3,
    paddingTop: 2,
    overflowY: 'auto'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px'
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    minHeight: '200px'
  }
};

export default styles;
