import { motion } from 'framer-motion';

const FadeInDiv = ({ children, height = 'inherit' }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{ height: height || 'inherit' }}
    >
      {children}
    </motion.div>
  );
};

export default FadeInDiv;
