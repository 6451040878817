import React from 'react';
import { Box, Typography } from '@mui/material';

const ProfileTabs = ({ data, selected, setSelected }) => {
  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'8px'} flexWrap={'wrap'}>
      {data.map(({ label, value }, index) => (
        <Box
          key={`${value}-${index}`}
          component={'div'}
          onClick={() => setSelected(value)}
          textAlign={'center'}
          sx={{
            paddingX: '16px',
            paddingY: '16px',
            borderRadius: '6px',
            bgcolor: selected === value ? '#2A76F4' : '',
            color: selected === value ? '#FFF' : '#4E5663',
            cursor: 'pointer',
            borderColor: selected === value ? '#2A76F4' : '#D4E4FD',
            border: '1px solid #D4E4FD'
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: selected === value ? 600 : 400,
              lineHeight: '10px'
            }}
          >
            {label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ProfileTabs;
