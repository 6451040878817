import { styled } from '@mui/material/styles';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
    maxWidth: '450px'
  }
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(3),
  '& .MuiTypography-h4': {
    fontSize: '1.5rem',
    fontWeight: 600
  }
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2)
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3, 3),
  '& .MuiButton-root': {
    borderRadius: 8,
    padding: theme.spacing(1, 3),
    fontSize: '0.95rem',
    textTransform: 'none',
    fontWeight: 500
  }
}));

export const IconWrapper = styled(Box)(({ theme, success }) => ({
  backgroundColor: success ? 'rgba(84, 214, 44, 0.16)' : 'rgba(255, 72, 66, 0.16)',
  borderRadius: '50%',
  padding: theme.spacing(1.5),
  marginRight: theme.spacing(2)
}));

export const MessageWrapper = styled(Box)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  textAlign: 'center',
  marginTop: theme.spacing(1),
  lineHeight: 1.6
}));
