import { useState } from 'react';
import { useEffect } from 'react';
import { Button } from '@mui/material';
import { Add } from 'iconsax-react';

import CreateAction from 'ui-component/Modal/CreateAction';
import { buttonBaseProps } from 'ui-component/table/DataSourceTable/CustomToolbar';
import { useFunctionContext } from 'views/common/FunctionContext';

const CreateActionToolbar = ({ configItem }) => {
  const createButtonBaseProps = {
    ...buttonBaseProps,
    startIcon: <Add />
  };

  const [open, setOpen] = useState(false);
  const [disableCreateAction, setDisableCreateAction] = useState(false);
  const { sharedFunction } = useFunctionContext() || {};
  const { taskStatus } = sharedFunction || '';
  useEffect(() => {
    setDisableCreateAction(taskStatus === 'Completed' || taskStatus === 'Rejected' || taskStatus === 'Closed by User');
  }, [sharedFunction, taskStatus]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    !disableCreateAction && (
      <>
        <Button {...createButtonBaseProps} onClick={() => setOpen((prev) => !prev)}>
          {configItem?.label || 'Create Action'}
        </Button>
        {open && <CreateAction open={open} handleClose={handleClose} handleRefreshTable={sharedFunction?.handleActionRefresh} />}
      </>
    )
  );
};

export default CreateActionToolbar;
