import { createSlice } from '@reduxjs/toolkit';

import { fetchInsightHighlights } from 'api/api';

const initialState = {
  data: [],
  isLoading: false,
  error: null
};

const insightHighlightSlice = createSlice({
  name: 'insightHighlight',
  initialState,
  reducers: {
    // Add your reducer functions here
  },
  extraReducers: (builder) => {
    // Add your extra reducers here
    builder.addCase(fetchInsightHighlights.pending, (state) => {
      state.data = [];
      state.isLoading = true;
    });
    builder.addCase(fetchInsightHighlights.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchInsightHighlights.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    });
  }
});

export const { actions, reducer } = insightHighlightSlice;
export default insightHighlightSlice.reducer;
