import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, IconButton, Modal, Stack, Typography } from '@mui/material';

import { createLicenseUsingForm } from 'api/api';
import useNotification from 'hooks/useNotifications';
import GlobalForm from 'ui-component/forms/GlobalForm';
import ToggleTabs from 'ui-component/Toggle';
import { transformFormToPayload } from 'utils';

import styles from './styles/InvoiceModalStyles';

const InvoiceModal = ({
  open,
  onClose,
  // req_params,
  formData,
  loading,
  error,
  // defaultType,
  screen,
  showToggle,
  toggleData,
  activeTab,
  setActiveTab,
  modalDimensions,
  inputValues,
  handleInputChange,
  setInputValues,
  refreshTable,
  setShowProcessingMessage,
  setProcessingMessage
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileName, setFilename] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const notification = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUploadResponse, setHasUploadResponse] = useState(false);

  useEffect(() => {
    if (!open) {
      setUploadedFiles([]);
      setFilename('');
      setErrorMsg('');
      setHasFileUploaded(false);
      setHasUploadResponse(false);
    }
  }, [open]);

  useEffect(() => {
    if (errorMsg?.closeAfter) {
      if (uploadedFiles?.length > 0) {
        const successfulUpload = uploadedFiles.find((file) => file?.status === 'success');
        if (successfulUpload?.popUpMessage) {
          setProcessingMessage(successfulUpload.popUpMessage);
          setShowProcessingMessage(true);
        }
      }
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMsg, uploadedFiles.length]);

  const handleDownload = () => {
    const url = `https://stbackendservicedev.blob.core.windows.net/publicfiles/license_data_sample.csv`;
    window.open(url, '_blank');
  };

  const getSupportedFormats = (type) => {
    switch (type) {
      case 'License_PDF':
        return 'PDF';
      case 'License_CSV':
        return 'CSV';
      case 'Invoice':
        return 'PDF, JPG, JPEG, PNG';
      default:
        return 'PDF';
    }
  };

  const handleClose = () => {
    if (hasFileUploaded && hasUploadResponse) {
      if (uploadedFiles?.length > 0) {
        const successfulUpload = uploadedFiles.find((file) => file?.status === 'success');
        if (successfulUpload?.popUpMessage) {
          setProcessingMessage(successfulUpload.popUpMessage);
          setShowProcessingMessage(true);
        }
      }
    }
    setUploadedFiles([]);
    setFilename('');
    setErrorMsg('');
    setHasFileUploaded(false);
    setHasUploadResponse(false);
    onClose();
  };

  const handleFormSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const payload = transformFormToPayload(formData, values);
      const response = await createLicenseUsingForm(payload);

      if (response?.status === 200) {
        notification.success('License created successfully', 3000);
        refreshTable();
        handleClose();
      } else {
        notification.error('Failed to create license', 3000);
      }
    } catch (err) {
      console.error('Failed to create license:', err);
      notification.error(err?.data?.detail || 'Failed to create license', 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setInputValues({});

    handleClose();
  };

  const FORM_DATA = Array.isArray(formData)
    ? formData?.map((item) => {
        if (item.name === 'download') {
          return { ...item, handleDownload };
        }
        if (item.name === 'File Upload') {
          return {
            ...item,
            supportedFormats: getSupportedFormats(inputValues.Type),
            upload_type: screen === 'invoice-list' ? 'Invoice' : inputValues.Type,
            setUploadedFiles: (files) => {
              setUploadedFiles(files);
              setHasFileUploaded(true);
              setHasUploadResponse(true);
            },
            uploadedFiles,
            setFilename,
            fileName,
            setErrorMsg,
            onTypeChange: () => {
              return inputValues.Type;
            },
            onClose: handleClose
          };
        }
        return item;
      })
    : formData;

  const renderToggle = () => {
    if (!showToggle) return null;
    return (
      <Box sx={styles.toggleWrapper}>
        <ToggleTabs
          data={toggleData}
          selected={activeTab}
          setSelected={(value) => {
            setActiveTab(value);
          }}
        />
      </Box>
    );
  };

  const renderLoadingState = () => (
    <Box sx={styles.loadingWrapper}>
      <CircularProgress />
    </Box>
  );

  const renderErrorState = () => (
    <Box sx={styles.errorWrapper}>
      <Typography color="error">{error}</Typography>
    </Box>
  );

  const renderSubmitButtonContent = () => {
    if (isSubmitting) {
      return (
        <Box sx={styles.submitButtonContent}>
          <CircularProgress size={20} color="inherit" />
          <span>Submitting...</span>
        </Box>
      );
    }
    return 'Submit';
  };

  const renderForm = () => (
    <GlobalForm
      formData={activeTab === 'Form' ? formData : FORM_DATA}
      onSubmit={activeTab === 'Form' ? handleFormSubmit : handleClose}
      resetOnSubmit={false}
      validateOnChange={true}
      initialValues={inputValues}
      onChange={handleInputChange}
      loading={loading}
    >
      {activeTab === 'Form' && (
        <Stack direction="row" spacing={2} sx={styles.buttonStack}>
          <Button variant="outlined" onClick={handleCancel} fullWidth sx={styles.cancelButton} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" fullWidth sx={styles.submitButton} disabled={isSubmitting}>
            {renderSubmitButtonContent()}
          </Button>
        </Stack>
      )}
      <Box>
        <Typography
          sx={{
            ...styles.errorText,
            color: errorMsg?.status === 200 ? '#4CAF50' : '#EA4141'
          }}
        >
          {errorMsg?.message || errorMsg}
        </Typography>
      </Box>
    </GlobalForm>
  );

  const renderContent = () => {
    if (loading) return renderLoadingState();
    if (error) return renderErrorState();
    return renderForm();
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="invoice-modal">
      <Box sx={{ ...styles.modalContainer, ...modalDimensions }}>
        <IconButton aria-label="close" onClick={handleClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>

        <Box sx={styles.contentWrapper}>
          {renderToggle()}
          <Box sx={styles.formWrapper}>{renderContent()}</Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default InvoiceModal;
