import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Box, Typography } from '@mui/material';

import { getDetailedTableVersionSecond, getMainServices } from 'api/api';
import { DATE_RANGES } from 'constants/constants';
import { clearStep } from 'store/slices/StepperSlice';
import { clearTableData } from 'store/slices/InsightTableSlice';
import { StyledTypography } from 'styled-components/global';
import ActionStateManagement from 'ui-component/CustomWrappers/Table/ActionStateManagement';
import TaskStateManagementTable from 'ui-component/CustomWrappers/Table/TaskStateManagement';
import DatasetDetailTable from 'ui-component/CustomWrappers/Table/DatasetDetailTable';
import FadeInDiv from 'ui-component/FadeInDiv';
import SectionWrapper from 'ui-component/SectionWrapper';
import CommonTable from 'ui-component/table/DataSourceTable/CommonTable';
import { getUrlParams } from 'utils';
import CreateButton from './CreateButton';

const CustomTable = ({
  tableVariant,
  title,
  sectionProps,
  apiCallOnPage,
  tableData,
  params,
  selectedChip,
  setSelectedChip,
  tableTotalCount,
  handleClick,
  toolbarConfig
}) => {
  switch (tableVariant) {
    case 'task-management':
      return (
        <TaskStateManagementTable
          apiCallOnPage={apiCallOnPage}
          tableData={tableData}
          params={params}
          title={title}
          disableExport={sectionProps?.disableExport}
          toggleButtons={sectionProps?.toggleButtons}
          selectedChip={selectedChip}
          setSelectedChip={setSelectedChip}
          tableTotalCount={tableTotalCount}
          searchBar={sectionProps?.searchBar}
          toolbarConfig={toolbarConfig}
        >
          {/* idk yet if it is there in tms */}
          {sectionProps?.actionButton && <CreateButton handleClick={handleClick} />}
        </TaskStateManagementTable>
      );
    case 'action-management':
      return (
        <ActionStateManagement
          apiCallOnPage={apiCallOnPage}
          tableData={tableData}
          params={params}
          title={title}
          disableExport={sectionProps?.disableExport}
          toggleButtons={sectionProps?.toggleButtons}
          selectedChip={selectedChip}
          setSelectedChip={setSelectedChip}
          tableTotalCount={tableTotalCount}
          searchBar={sectionProps?.searchBar}
          toolbarConfig={toolbarConfig}
        >
          {/* idk yet if it is there in tms */}
          {sectionProps?.actionButton && <CreateButton handleClick={handleClick} />}
        </ActionStateManagement>
      );
    case 'dataset-detail':
      return (
        <DatasetDetailTable
          apiCallOnPage={apiCallOnPage}
          tableData={tableData}
          params={params}
          title={title}
          disableExport={sectionProps?.disableExport}
          entitlements={sectionProps?.entitlements}
          actionButton={sectionProps?.actionButton}
          sectionProps={sectionProps}
          selectedChip={selectedChip}
          setSelectedChip={setSelectedChip}
          tableTotalCount={tableTotalCount}
          searchBar={sectionProps?.searchBar}
          handleClick={handleClick}
          toolbarConfig={toolbarConfig}
        />
      );
    default:
      return (
        <CommonTable
          apiCallOnPage={apiCallOnPage}
          tableData={tableData}
          req_params={params}
          title={title}
          disableExport={sectionProps?.disableExport}
          entitlements={sectionProps?.entitlements}
          toggleButtons={sectionProps?.toggleButtons}
          actionButton={sectionProps?.actionButton}
          selectedChip={selectedChip}
          setSelectedChip={setSelectedChip}
          tableTotalCount={tableTotalCount}
          searchBar={sectionProps?.searchBar}
          handleClick={handleClick}
          toolbarConfig={toolbarConfig}
        >
          {sectionProps?.actionButton && <CreateButton name={sectionProps.name} handleClick={handleClick} />}
        </CommonTable>
      );
  }
};

const Table = ({ title = '', sectionProps, screen, tableVariant, tableId: propsTableId, assetId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { taskId } = useParams();

  const screenSelector = useSelector((state) => state?.page?.page);
  // const adminTableData = useSelector((state) => state?.sources);
  const dateSelect = useSelector((state) => state.dateSelect?.selectValue);
  const { startDate: startDateRedux, endDate: endDateRedux } = DATE_RANGES[dateSelect];

  const [selectedChip, setSelectedChip] = useState(localStorage.getItem('setSelectedChip') ?? 'all');
  const [totalCount, setTotalCount] = useState(0);

  const role = localStorage.getItem('persona');
  const tenantId = localStorage.getItem('tenantId');

  const urlParams = getUrlParams(location.search);
  // const queryParams = new URLSearchParams(location.search);
  // const sourceId = queryParams.get('source_id');

  //TODO: Refactor Table ID Variable Globally
  const tableId = propsTableId || sectionProps?.tableId || urlParams?.data_product_id || urlParams?.id || urlParams?.tableId;

  const tableDataFromStore = useSelector((state) => state?.insightsTable?.tables[tableId]);
  const loading = useSelector((state) => state?.insightsTable?.loading);
  const error = useSelector((state) => state?.insightsTable?.error);

  const tableData = useMemo(
    () => ({
      data: tableDataFromStore || {
        columns: [],
        data: [],
        page_size: 0,
        page_number: 0,
        totalCount: 0,
        totalPages: 0
      },
      loading,
      error
    }),
    [tableDataFromStore, loading, error]
  );

  const requestParams = {
    from_date: startDateRedux,
    to_date: endDateRedux,
    tenant_id: tenantId,
    screen_name: screen ?? screenSelector,
    role,
    source: selectedChip,
    id: tableId,
    has_page_count: true,
    ...(assetId && { asset_id: assetId }),
    ...(taskId && { task_id: taskId }),
    ...urlParams
  };

  const detailParams = {
    from_date: startDateRedux,
    to_date: endDateRedux,
    id: tableId,
    pipeline_id: urlParams.pid
  };

  const params = urlParams?.pid ? detailParams : requestParams;

  const apiCallOnPage = async (params) => {
    try {
      const fetchAction = getDetailedTableVersionSecond;
      const response = await dispatch(fetchAction(params));
      if (response.payload?.totalCount != null) {
        setTotalCount(response.payload?.totalCount);
        sessionStorage.removeItem('totalCount');
      }

      return response.payload;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleClick = () => {
    dispatch(getMainServices());
    dispatch(clearStep());
    localStorage.setItem('setRadioValue', 'API');
    navigate('/link');
  };

  useEffect(() => {
    return () => {
      if (tableId) {
        dispatch(clearTableData(tableId));
      }
    };
  }, [dispatch, tableId]);

  if (!tableId) {
    return (
      <Box className="box" padding={2}>
        <Typography fontSize={'2rem'} align="center">
          ⚠️
        </Typography>
        <StyledTypography align="center">No Table Found.</StyledTypography>
      </Box>
    );
  }

  return (
    <FadeInDiv>
      <SectionWrapper
        title={title}
        variant={sectionProps?.variant}
        bordered={sectionProps?.bordered}
        hasBackButton={sectionProps?.hasBackButton}
      >
        <CustomTable
          sectionProps={sectionProps}
          tableVariant={tableVariant}
          apiCallOnPage={apiCallOnPage}
          tableData={tableData}
          params={params}
          title={title}
          disableExport={sectionProps?.disableExport}
          entitlements={sectionProps?.entitlements}
          toggleButtons={sectionProps?.toggleButtons}
          selectedChip={selectedChip}
          setSelectedChip={setSelectedChip}
          tableTotalCount={totalCount}
          searchBar={sectionProps?.searchBar}
          handleClick={handleClick}
          toolbarConfig={tableData?.data?.toolbarConfig}
        />
      </SectionWrapper>
    </FadeInDiv>
  );
};

export default Table;
