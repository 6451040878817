import React, { useState } from 'react';
import { Button } from '@mui/material';
import JobDetailsModal from 'ui-component/Modal/JobDetailsModal';
import { Timer } from 'iconsax-react';

const RunHistoryToolbar = () => {
  const [jobDetailsOpen, setJobDetailsOpen] = useState(false);

  const handleJobDetailsOpen = () => {
    setJobDetailsOpen(true);
  };

  const handleJobDetailsClose = () => {
    setJobDetailsOpen(false);
  };

  return (
    <>
      <Button startIcon={<Timer size="20" />} color="primary" onClick={handleJobDetailsOpen}>
        Run History
      </Button>
      <JobDetailsModal open={jobDetailsOpen} onClose={handleJobDetailsClose} />
    </>
  );
};

export default RunHistoryToolbar;
