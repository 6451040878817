import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';

class ComponentErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // to keep track of when an error occurs
    // and the error itself
    this.state = {
      hasError: false,
      error: undefined
    };

    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
  }

  // update the component state when an error occurs
  static getDerivedStateFromError(error) {
    console.error('error : ', error);
    // specify that the error boundary has caught an error
    return {
      hasError: true,
      error: error
    };
  }

  resetErrorBoundary() {
    this.setState({ hasError: false });
  }

  // defines what to do when an error gets caught
  componentDidCatch(error, errorInfo) {
    // log the error

    console.error(error);
    console.error(errorInfo);

    // record the error in an APM tool...
  }

  render() {
    if (this.state.hasError) {
      console.log('child has error');
      return (
        <Box sx={{ padding: 2, textAlign: 'center' }} display={'flex'} flexDirection={'column'} gap={1}>
          <Typography variant="h3">⚠️</Typography>
          <Typography variant="h3">Oops!</Typography>
          <Typography variant="h5">something went wrong</Typography>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ComponentErrorBoundary;
