import React, { useState } from 'react';
import { Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material';

import { updateTaskStatus } from 'api/taskManagement.service';
import useNotification from 'hooks/useNotifications';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2
};

const TaskRejectModal = ({ open, handleClose, taskId, from, setState, handleRefreshTable }) => {
  const [rejectionReason, setRejectionReason] = useState('');
  const [loading, setLoading] = useState(false);
  const { error: errorNotification, success: successNotification } = useNotification();

  const handleUpdate = () => {
    if (rejectionReason) {
      setLoading(true);
      updateTaskStatus(taskId, {
        newStatus: 'Rejected',
        // ! Hard code alert!
        completionDate: new Date(),
        currentStatus: from,
        note: rejectionReason
      })
        .then((res) => {
          if (res.status === 200) {
            setState({ from, to: 'Rejected' });
            successNotification('Task updated successfully', 3000);
            handleRefreshTable();
          } else {
            errorNotification('Error updating task', 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          errorNotification('Error updating task', 3000);
        })
        .finally(() => {
          handleClose();
          setLoading(false);
        });
    } else {
      errorNotification('Please enter a reason for rejection', 3000);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="reject-task-modal" aria-describedby="reject-task-form">
      <Box sx={style}>
        <Typography id="reject-task-modal" variant="h3" mb={2}>
          Do you want to reject this task?
        </Typography>

        <TextField
          placeholder="Enter reason for rejection"
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          {/* ! Hard code alert */}
          {/* Todo : Remove inline style */}
          <Button
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleUpdate}
          >
            <Box sx={{ visibility: loading ? 'hidden' : 'visible' }}>Reject Task</Box>
            {loading && (
              <CircularProgress
                size={24}
                color="inherit"
                sx={{
                  position: 'absolute'
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TaskRejectModal;
