import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDetailedTableVersionSecond, getLicenseFormFields, SourceDropdown } from 'api/api';

import ErrorModal from '../../Modal/ErrorModal';
import InvoiceModal from '../../Modal/InvoiceModal';

const InvoiceModalWrapper = ({ open, onClose, req_params }) => {
  const screen = useSelector((state) => state?.page?.page);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [defaultType, setDefaultType] = useState('');
  const [activeTab, setActiveTab] = useState('Upload');
  const [formFields, setFormFields] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [showProcessingMessage, setShowProcessingMessage] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('');
  const dispatch = useDispatch();

  const toggleData = [
    { label: 'Upload', value: 'Upload' },
    { label: 'Entitlement Form', value: 'Form' }
  ];

  const fetchModalData = async () => {
    setLoading(true);
    setError(null);

    try {
      const [sourceResponse, formFieldsResponse] = await Promise.all([SourceDropdown(screen), getLicenseFormFields()]);

      const choices =
        sourceResponse?.options?.map((option) => ({
          value: option.value,
          label: option.label
        })) || [];

      if (choices?.length > 0) {
        const defaultValue = choices[0].value;
        setOptions(choices);
        setDefaultType(defaultValue);
        setInputValues({ Type: defaultValue });
      }

      setFormFields(formFieldsResponse?.data);
    } catch (err) {
      console.error('Error fetching modal data:', err);
      setError('Failed to load data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && (screen === 'dataset-detail' || screen === 'source-detail')) {
      fetchModalData();
    }
    // Reset states when modal closes
    if (!open) {
      setActiveTab('Upload');
      setError(null);
      setDefaultType('');
      setInputValues({ Type: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, screen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const defaultFormData = [
    {
      name: 'File Upload',
      labelText: 'Upload file',
      type: 'drag-n-drop',
      placeholder: 'Choose a file',
      required: true,
      defaultValue: '',
      options: [],
      fileUploadAPI: 'data-wrapper/upload-file',
      upload_type: 'Invoice'
    }
  ];

  const invoiceListFormData = [
    {
      name: 'Type',
      labelText: 'Type',
      type: 'select',
      placeholder: 'Type',
      required: true,
      defaultValue: defaultType,
      options: options
    },
    {
      name: 'download',
      type: 'download'
    },
    {
      name: 'File Upload',
      labelText: 'Upload file',
      type: 'drag-n-drop',
      placeholder: 'Choose a file',
      required: true,
      defaultValue: '',
      options: [],
      fileUploadAPI: 'data-wrapper/upload-file'
    }
  ];

  const getFormData = () => {
    if (loading) return [];
    if (error) return [];

    switch (screen) {
      case 'dataset-detail':
        switch (activeTab) {
          case 'Upload':
            return invoiceListFormData;
          case 'Form':
            return formFields;
          default:
            return defaultFormData;
        }
      case 'source-detail':
        return invoiceListFormData;
      default:
        return defaultFormData;
    }
  };

  // todo : get modal dimensions from sections
  const getModalDimensions = () => {
    switch (screen) {
      case 'dataset-detail':
      case 'source-detail':
        return {
          width: '80%',
          height: '90%'
        };
      default:
        return {
          width: '50%',
          height: '60%'
        };
    }
  };

  const refreshTable = () => {
    if (req_params) {
      const refreshParams = {
        ...req_params,
        has_page_count: true,
        page_number: 0,
        page_size: 10
      };
      dispatch(getDetailedTableVersionSecond(refreshParams)).then((response) => {
        if (response?.payload?.totalCount != null) {
          sessionStorage.setItem('totalCount', response?.payload?.totalCount);
        }
      });
    }
  };

  const handleCloseProcessingMessage = () => {
    setShowProcessingMessage(false);
    refreshTable();
  };

  return (
    <>
      <InvoiceModal
        open={open}
        onClose={onClose}
        req_params={req_params}
        formData={getFormData()}
        loading={loading}
        error={error}
        defaultType={defaultType}
        screen={screen}
        showToggle={screen === 'dataset-detail'}
        toggleData={toggleData}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        modalDimensions={getModalDimensions()}
        inputValues={inputValues}
        handleInputChange={handleInputChange}
        setInputValues={setInputValues}
        refreshTable={refreshTable}
        setShowProcessingMessage={setShowProcessingMessage}
        setProcessingMessage={setProcessingMessage}
      />
      <ErrorModal
        open={showProcessingMessage}
        onClose={handleCloseProcessingMessage}
        message={processingMessage}
        isSuccess={true}
        context="upload"
      />
    </>
  );
};

export default InvoiceModalWrapper;
