import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, IconButton, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CloseCircle, DocumentUpload } from 'iconsax-react';

import { genericFileUpload, getDownloadUrl } from 'api/api';
import { STATUS_COLOR } from 'constants/colors';
import FadeInDiv from 'ui-component/FadeInDiv';
import OnHoverToolTip from 'ui-component/Tooltips/OnHoverToolTip';

import styles from './MyDropzoneStyles';

const MyDropzone = ({
  fileUploadAPI,
  accept,
  maxSize,
  upload_type,
  setUploadedFiles,
  disabled,
  setFilename,
  // fileName = 'Uploaded Files',
  setErrorMsg,
  setOpen,
  supportedFormats,
  uploadedFiles,
  multiple = true,
  onTypeChange,
  onClose,
  showContinueButton = true,
  height
}) => {
  const loginDetails = JSON.parse(localStorage.getItem('userDetails'));
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (onTypeChange) {
      setSelectedFiles([]);
    }
  }, [onTypeChange]);

  const removeFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  const handleFileUpload = async () => {
    try {
      setIsUploading(true);
      const tenant_id = loginDetails?.user?.customer?.id;
      const uploadDetails = {
        tenant_id,
        upload_type,
        files: selectedFiles
      };

      const response = await genericFileUpload(uploadDetails, fileUploadAPI);

      if (response?.data?.files?.length > 0) {
        const newUploadedFiles = response?.data?.files?.map((fileResponse) => ({
          blob_name: fileResponse.file,
          detail: fileResponse.detail,
          status: fileResponse.status,
          source_id: response.data.source_id,
          source_type: response.data.source_type,
          popUpMessage: response.data.popUpMessage,
          download_url: fileResponse.download_url
        }));

        const allSuccess = newUploadedFiles.every((file) => file?.status === 'success');

        const combinedFiles = [...(uploadedFiles || []), ...newUploadedFiles];
        setUploadedFiles(combinedFiles);
        setFilename(combinedFiles.map((f) => f.blob_name).join(', '));

        if (allSuccess) {
          setErrorMsg({
            message: response?.data?.detail || 'Upload successful',
            status: 200,
            closeAfter: true
          });
        } else {
          setErrorMsg({
            message: response?.data?.detail || 'Upload successful',
            status: 200
          });
        }

        setSelectedFiles([]);
      } else {
        const errorMessage =
          response?.status === 413
            ? response?.response?.data?.message || 'Files are too large to upload'
            : response?.response?.data?.detail || 'File upload failed';

        setErrorMsg({
          message: errorMessage,
          status: response?.status || 400
        });
        setOpen?.(true);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      const errorMessage = error?.response?.data?.detail || 'Error uploading files';
      setErrorMsg(errorMessage);
      setOpen?.(true);
    } finally {
      setIsUploading(false);
    }
  };

  const shouldAllowMultiple = (format) => {
    const singleUploadFormats = ['CSV'];
    return !singleUploadFormats.includes(format);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!shouldAllowMultiple(supportedFormats)) {
        setSelectedFiles([acceptedFiles[0]]);
      } else {
        setSelectedFiles((prev) => [...prev, ...acceptedFiles]);
      }
    },
    [supportedFormats]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxSize,
    noClick: disabled,
    multiple: shouldAllowMultiple(supportedFormats)
  });

  const showAddMoreFiles = supportedFormats === 'PDF' && (selectedFiles.length > 0 || uploadedFiles?.length > 0);

  const renderAddMoreFiles = () => {
    if (!showAddMoreFiles) return null;

    return (
      <Box sx={styles.addMoreFilesContainer}>
        <Typography sx={styles.addMoreFilesText}>+ Add more files</Typography>
      </Box>
    );
  };

  const renderSelectedFiles = () => (
    <Box sx={styles.scrollableFileBox}>
      {renderAddMoreFiles()}
      {selectedFiles.map((file, index) => (
        <Box key={index} sx={styles.fileContainer}>
          <Box sx={styles.fileDetails}>
            <DocumentUpload size="24" color="#2A76FA" />
            <Box sx={styles.fileNameContainer}>
              <Box sx={styles.fileInfoContainer}>
                <Typography variant="body1" sx={styles.fileName}>
                  {file.name}
                </Typography>
                <Typography variant="caption" sx={styles.fileSize}>
                  {(file.size / 1024).toFixed(2)} KB
                </Typography>
              </Box>
              <IconButton onClick={() => removeFile(index)} sx={{ color: '#2A76FA' }}>
                <CloseCircle size="20" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );

  const handleDownloadFile = async (fileUrl) => {
    try {
      const downloadUrl = await getDownloadUrl(fileUrl);
      if (downloadUrl) {
        window.open(downloadUrl, '_blank');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      setErrorMsg({ message: 'Error downloading file' });
    }
  };

  const renderUploadedFiles = () => (
    <Box sx={styles.scrollableFileBox}>
      {renderAddMoreFiles()}
      {uploadedFiles?.map((file, index) => (
        <OnHoverToolTip key={index} title="Download" variant="children" placement="top">
          <Box
            sx={styles.downloadableFileContainer}
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadFile(file.download_url);
            }}
          >
            <Box sx={styles.fileDetails}>
              <DocumentUpload size="24" color="#2A76FA" />
              <Box sx={styles.fileInfoContainer}>
                <Typography variant="body1" sx={styles.fileName}>
                  {file.blob_name}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    ...styles.fileDetail,
                    color: STATUS_COLOR[file.status] || 'inherit'
                  }}
                >
                  {file.detail}
                </Typography>
              </Box>
            </Box>
          </Box>
        </OnHoverToolTip>
      ))}
    </Box>
  );

  const handleCancel = () => {
    if (selectedFiles.length > 0 || uploadedFiles?.length > 0) {
      setSelectedFiles([]);
      setUploadedFiles([]);
      setFilename('');
      setErrorMsg('');
    } else {
      onClose();
    }
  };

  const shouldShowContinueButton = () => {
    return uploadedFiles?.length > 0;
  };

  return (
    <FadeInDiv>
      <Box style={styles.root}>
        <div {...getRootProps()} style={styles.dropzone(disabled, height)}>
          <input {...getInputProps()} />
          {!selectedFiles.length && !uploadedFiles?.length && (
            <>
              <DocumentUpload size="20" color="#434C5B" />
              <Typography variant="body1" color="#434C5B">
                Drag & Drop or
                <Typography component="span" mx={0.5} sx={styles.chooseFileText}>
                  Choose files
                </Typography>
                to upload
              </Typography>
              <Typography>Supported Formats: {supportedFormats}</Typography>
            </>
          )}
          {selectedFiles.length > 0 && renderSelectedFiles()}
          {uploadedFiles?.length > 0 && shouldShowContinueButton() && showContinueButton && renderUploadedFiles()}

          {isUploading && (
            <Box sx={styles.progressOverlay}>
              <Box sx={styles.progressContent}>
                <Typography variant="body2" color="primary" sx={styles.uploadingText}>
                  Uploading...
                </Typography>
                <LinearProgress sx={styles.progress} />
              </Box>
            </Box>
          )}
        </div>

        <Box sx={styles.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel} sx={styles.cancelButton}>
            Cancel
          </Button>
          {selectedFiles.length > 0 ? (
            <Button variant="contained" onClick={handleFileUpload} disabled={isUploading} sx={styles.uploadButton}>
              {isUploading ? 'Uploading...' : 'Upload'}
            </Button>
          ) : uploadedFiles?.length > 0 && shouldShowContinueButton() && showContinueButton ? (
            <Button variant="contained" onClick={onClose} sx={styles.continueButton}>
              Finish Upload
            </Button>
          ) : (
            <Button variant="contained" onClick={handleFileUpload} disabled={true} sx={styles.uploadButton}>
              Upload
            </Button>
          )}
        </Box>
      </Box>
    </FadeInDiv>
  );
};

export default MyDropzone;
