import { createSlice } from '@reduxjs/toolkit';

import { AskAsatoPost } from '../../api/api';

const initialState = {
  data: [],
  loading: false,
  error: ''
};

const AskAsatoPostSlice = createSlice({
  name: 'Post Asato',
  initialState,
  reducers: {
    clearData: (state) => {
      return { ...state, data: [] };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(AskAsatoPost.pending, (state) => {
        state.data = [];
        state.loading = true;
      })
      .addCase(AskAsatoPost.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(AskAsatoPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  }
});
export const { clearData } = AskAsatoPostSlice.actions;
export default AskAsatoPostSlice.reducer;
