const styles = {
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative'
  },
  toggleWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'background.paper',
    padding: '20px 32px 12px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },
  formWrapper: {
    flex: 1,
    overflowY: 'auto',
    padding: '20px 32px',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
      '&:hover': {
        background: '#555'
      }
    }
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
    zIndex: 2
  },
  errorText: {
    fontSize: '14px'
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px'
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    gap: 2
  },
  buttonStack: {
    mt: 2,
    width: '50%',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cancelButton: {
    borderColor: '#2A76FA',
    color: '#2A76FA',
    flex: 1,
    '&:hover': {
      color: '#FB5555',
      borderColor: '#FDAAAA'
    }
  },
  submitButton: {
    flex: 1,
    backgroundColor: '#2A76FA',
    '&:hover': {
      backgroundColor: '#1b5cd4'
    }
  },
  submitButtonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  }
};

export default styles;
