import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import { Eye } from 'iconsax-react';

import { getDownloadUrl, ToggleUserEnable } from 'api/api';
import useToast from 'hooks/useToast';
import { changeBannerId } from 'store/slices/PageSlice';
import UpdateUserModal from 'ui-component/Modal/UpdateModal';

import MyResponsiveDataGrid from '../MobileScreenTable';

import { ConnectionStatusRenderer, useNavigationHandlers } from './CellRenderers';
import DataGridTable from './DataGridTable';
import styles from './Styles/DataSourceTableStyles';

const useStyles = makeStyles(() => ({
  True: {
    ...styles.chip,
    ...styles.activeChip
  },
  False: {
    ...styles.chip,
    ...styles.inactiveChip
  },
  navlink: {
    ...styles.navlink
  },
  urlButton: {
    ...styles.urlButton
  }
}));

// Temporary since we have only view and to manage state
export const ActionIcon = ({ onClick }) => {
  const [hover, setHover] = useState(false);

  return (
    <Box sx={styles.actionIcon} title="View" onClick={onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Eye size="20" color={hover ? '#2A76F4' : '#818690'} variant={hover ? 'Bulk' : 'Outline'} style={{ marginRight: '8px' }} />
      View
    </Box>
  );
};

function DataSourceTable({
  columnGroupingModel,
  tableData = [],
  tableHeader = [],
  selectedChip,
  loading,
  handlePageChange,
  paginationModel,
  hideFooter,
  handleSmRowsPerPage,
  handleSmPage,
  sortingMode,
  handleSorting,
  disableColumnSorting,
  disableColumnFilter,
  onFilterChange,
  tableTotalCount,
  requestParams,
  apiCallOnPage,
  handleTaskOpen,
  handleStatusChange,
  onRowDelete,
  toolbarConfig,
  onEditClick,
  onDeleteClick
}) {
  const classes = useStyles();
  const [updateModal, setUpdateModal] = useState(false);
  const [selected, setSelected] = useState({});
  const role = localStorage.getItem('persona');
  const [downloadUrl, setDownloadUrl] = useState('');
  const downloadLinkRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 750px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();

  const pageType = useSelector((state) => state?.page?.page);

  const handleOpenBar = () => {
    showToast('User Details Updated Successfully', {
      severity: 'success',
      duration: 2000
    });
  };

  useEffect(() => {
    if (downloadUrl && downloadLinkRef.current) {
      downloadLinkRef.current.click();
      setDownloadUrl(null);
    }
  }, [downloadUrl]);

  const handleFileDownload = async (fileUrl) => {
    try {
      const downloadUrl = await getDownloadUrl(fileUrl);
      setDownloadUrl(downloadUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const { menuOptions, handleRow } = useNavigationHandlers(selectedChip);

  const handleNavigate = (row, item) => {
    // Handle the navigation completely from backend
    // or add a case for task screen
    // ! Hard coding alert!
    if (pageType === 'tasks' || window?.location?.pathname?.includes('/tasks/overview')) {
      navigate(`/tasks/${row?.id}`);
    } else if (pageType === 'invoice-list') {
      navigate(`/invoice-list/${row?.invoiceId}?view=extracted-data`);
    } else {
      navigate(`/asset?id=${row[item['assetIdColumn']]}&type=${item.assetType}`);
      dispatch(changeBannerId(row[item['assetIdColumn']]));
    }
  };

  const handleSwitchChange = (item, index) => {
    const { id } = item;
    const req = {
      isEnabled: !item['accountEnabled'],
      customerId: id,
      currentPersona: role,
      index
    };

    dispatch(ToggleUserEnable(req));
  };

  const handleEdit = (item) => {
    setUpdateModal(true);
    setSelected(item);
  };

  const closeModal = () => {
    setUpdateModal(false);
    setSelected({});
  };

  const renderCell = (data, params, i) => {
    return ConnectionStatusRenderer({
      data,
      params,
      classes,
      handleNavigate,
      handleFileDownload,
      handleRow,
      handleTaskOpen,
      handleStatusChange,
      handleSwitchChange,
      handleEdit,
      onRowDelete,
      onEditClick,
      onDeleteClick,
      pageType,
      navigate,
      i,
      selectedChip,
      menuOptions,
      apiCallOnPage,
      requestParams
    });
  };

  const columnsNotSortable = tableHeader?.filter((data) => data?.isSortable === false).map((data) => data.key);

  const updatedColumn = tableHeader
    ?.filter((data) => data?.display)
    ?.map((data, i) => ({
      field: data?.key,
      headerName: data?.title,
      headerClassName: 'header',
      type: data?.type,
      dataType: data?.dataType,
      flex: 1,
      editable: false,
      filterable: data?.filterable ?? true,
      disableColumnMenu: data?.disableColumnMenu ?? false,
      sortable: !columnsNotSortable.includes(data?.key),
      minWidth: data?.width || 160,
      defaultVisible: data?.defaultVisible ?? true,
      hideable: data?.hideable ?? true,
      renderCell: (params) => renderCell(data, params, i)
    }));

  const updatedRow = Array.isArray(tableData)
    ? tableData.map((item) => ({
        ...item,
        // Instead of id in res I am getting _id
        id: item?._id || item?.id
        // status: item?.isActive
      }))
    : [];

  return (
    <div>
      <a
        ref={downloadLinkRef}
        aria-label="Download"
        target="_blank"
        style={{ display: 'none' }}
        href={downloadUrl}
        download
        rel="noreferrer"
      />
      {!isMobile ? (
        <DataGridTable
          toolbarConfig={toolbarConfig}
          columnGroupingModel={columnGroupingModel}
          hideFooter={hideFooter}
          dataSource={updatedRow}
          columns={updatedColumn}
          pagination={false}
          selectedChip={selectedChip}
          loading={loading}
          paginationModel={paginationModel}
          handlePageChange={handlePageChange}
          sortingMode={sortingMode}
          handleSorting={handleSorting}
          disableColumnSorting={disableColumnSorting}
          disableColumnFilter={disableColumnFilter}
          onFilterChange={onFilterChange}
          tableTotalCount={tableTotalCount}
        />
      ) : (
        <MyResponsiveDataGrid
          handleSwitchChange={handleSwitchChange}
          handleEdit={handleEdit}
          loading={loading}
          head={updatedColumn}
          rows={updatedRow}
          paginationModel={paginationModel}
          handleSmPage={handleSmPage}
          handleSmRowsPerPage={handleSmRowsPerPage}
          selectedChip={selectedChip}
          tableTotalCount={tableTotalCount}
        />
      )}
      <UpdateUserModal open={updateModal} selected={selected} handleClose={closeModal} handleOpenBar={handleOpenBar} />
    </div>
  );
}

export default DataSourceTable;
