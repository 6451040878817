/* eslint-disable */
import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { Add } from 'iconsax-react';
import { styled } from '@mui/material/styles';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    minWidth: 220,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    padding: '8px'
  }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: 6,
  padding: '10px 16px',
  margin: '4px 0',
  '&:hover': {
    backgroundColor: theme.palette.primary.lighter,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main
    }
  },
  '& .icon': {
    marginRight: 12,
    color: theme.palette.grey[600]
  }
}));

const MenuDropdownButton = ({ name, onOptionSelect, menuItems, buttonIcon = <Add /> }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option) => {
    onOptionSelect(option);
    handleMenuClose();
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button startIcon={buttonIcon} variant="contained" onClick={handleMenuOpen} aria-controls="menu-dropdown" aria-haspopup="true">
        {name}
      </Button>
      <StyledMenu
        id="menu-dropdown"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {menuItems.map((item) => (
          <StyledMenuItem key={item.value} onClick={() => handleSelect(item.value)}>
            <Box>
              <Box display="flex" alignItems="center">
                {item.icon}
                <Typography variant="subtitle1" fontWeight={500}>
                  {item.label}
                </Typography>
              </Box>
              <Typography variant="caption" color="textSecondary" sx={{ pl: '32px' }}>
                {item.description}
              </Typography>
            </Box>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};

export default MenuDropdownButton;
