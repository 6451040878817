import { createSlice } from '@reduxjs/toolkit';

import { fetchAssetLinkStatus } from 'api/api';

const initialState = {
  loading: false,
  error: null,
  data: null
};

const LinkageOverviewSlice = createSlice({
  name: 'linkageOverview',
  initialState,
  reducers: {
    // Define your reducers here
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssetLinkStatus.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAssetLinkStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAssetLinkStatus.rejected, (state, action) => {
      state.loading = false;
      // console.log('actionds', action);
      state.error = action.payload.response.data;
      state.data = [];
    });
  }
});

export default LinkageOverviewSlice.reducer;
