import React from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import CryptoJS from 'crypto-js';

function IntercomComponent({ children }) {
  // const env = import.meta.env.VITE_ENV;
  const userDetail = localStorage.getItem('userDetails');
  const intercomSecret = import.meta.env.VITE_INTERCOM_SECRET;

  if (!userDetail) {
    return <>{children}</>;
  }

  const parsedUserDetail = JSON.parse(userDetail);
  const { employeeEmailId } = parsedUserDetail?.user || {};

  const hash = CryptoJS.HmacSHA256(employeeEmailId, intercomSecret);
  const hashInBase64 = CryptoJS.enc.Hex.stringify(hash);

  Intercom({
    app_id: import.meta.env.VITE_INTERCOM,
    user_id: employeeEmailId, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    user_hash: hashInBase64 // IMPORTANT: Replace this with the HMAC code you generated on your server
  });

  return <>{children}</>;
}

export default IntercomComponent;
