import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rtkQueryErrorLogger } from 'middlewares/reduxError';

import rootReducer from './reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['page', 'SSOLogin', 'dateSelect', 'stepper']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(rtkQueryErrorLogger)
});

const persistor = persistStore(store);

export { store, persistor };
