import React from 'react';
import { Box } from '@mui/system';
import { ChevronLeft } from '@mui/icons-material';

const BackButton = ({ size = 16, color = '#292D32', onBack }) => {
  return (
    <Box
      data-test-id="back-button"
      component={'div'}
      sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      onClick={onBack}
    >
      <ChevronLeft size={size} color={color} />
    </Box>
  );
};

export default BackButton;
