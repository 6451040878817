import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { StyledBox, StyledTypography } from 'styled-components/global';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledList, StyledListItem, StyledPopover, StyledTabWithChildrenWrapper } from '../styles';
import StepperComponent from '../TemporaryStepper/Stepper';

const ChildTabs = ({ routeChildren, open, handleClose, isSidebarExpanded, parentName }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  if (!routeChildren || !routeChildren.length) return null;

  /*
   Here we are mapping through the routeChildren and creating a stepper data
   If the child has children, then we render the Link component
   If the child does not have children, then we render the list item component with onclick which opens the dropdown
   */
  const stepperData = routeChildren.map((child, index) => ({
    content:
      child?.children && child.children.length ? (
        <StyledTabWithChildrenWrapper
          key={index}
          data-test-id={`${parentName}-child-${index}`}
          onClick={(e) => {
            e.stopPropagation();
            setPopoverOpen((prev) => !prev);
          }}
        >
          <Box>
            <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <StyledTypography>{child.name}</StyledTypography>
              <StyledBox layoutStyle={'flexAlignCenterJustifyCenter'}>
                <KeyboardArrowDownIcon style={DownArrowStyles(popoverOpen)} />
              </StyledBox>
            </Box>
            <Box position={'relative'}>
              <ChildTabs routeChildren={child.children} open={popoverOpen} handleClose={() => setPopoverOpen(false)} />
            </Box>
          </Box>
        </StyledTabWithChildrenWrapper>
      ) : (
        <StyledListItem key={index} to={child?.path} onClick={(e) => e.stopPropagation()}>
          <Typography className="popover-text">{child.name}</Typography>
        </StyledListItem>
      )
  }));

  return (
    <StyledPopover open={open} handleClose onClose={handleClose} data-test-id={`${parentName}-popover`}>
      <StyledList>
        <StepperComponent steps={stepperData} />
      </StyledList>
    </StyledPopover>
  );
};

export default ChildTabs;
