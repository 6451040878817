import React from 'react';
import { Box } from '@mui/material';
import * as Icons from 'iconsax-react';

import OnHoverToolTip from 'ui-component/Tooltips/OnHoverToolTip';

const IconButton = ({ icon, size, color, onClick, tooltip }) => {
  const IconComponent = Icons[icon];

  if (!IconComponent) {
    console.error(`Icon "${icon}" not found in iconsax-react.`);
    return null;
  }

  return (
    <OnHoverToolTip title={tooltip || ''} placement="top-end" variant="children">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
          cursor: 'pointer'
        }}
        onClick={onClick}
      >
        <IconComponent size={size} color={color} />
      </Box>
    </OnHoverToolTip>
  );
};

export default IconButton;
