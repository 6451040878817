import { createRoot } from 'react-dom/client';

import './index.css';
import 'assets/scss/style.scss';

import App from './App';
import { AppProviders } from './AppProviders';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <AppProviders>
    <App />
  </AppProviders>
);
