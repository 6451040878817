import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 12,
  p: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};

const AssignedModal = ({ open, setOpen, message }) => {
  // console.log('open', open);
  // console.log('message', message);
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleIcon sx={{ color: 'green', mr: 2 }} />
          <Typography variant="subtitle1">You have successfully {message} the tasks</Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default AssignedModal;
