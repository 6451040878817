import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Box, Skeleton, TablePagination, Typography } from '@mui/material';
import { Edit2 } from 'iconsax-react';
import moment from 'moment';

import CustomizedSwitches from 'ui-component/buttons/CustomSwitch';

import { ActionIcon } from './DataSourceTable';
import ViewMore from './ViewMore';

const itemsPerPageOptions = [5, 10, 25];

const MobileScreenTable = ({
  loading,
  rows,
  onTableClick,
  head,
  paginationModel,
  handleSmRowsPerPage,
  handleSmPage,
  selectedChip,
  handleSwitchChange,
  handleEdit,
  tableTotalCount
}) => {
  const navigate = useNavigate();

  const handleRow = useCallback(
    (row) => {
      navigate(`/link?type=${selectedChip}&id=${row.source_id}`);
    },
    [navigate, selectedChip]
  );

  const rowCount = tableTotalCount ? tableTotalCount : sessionStorage.getItem('totalCount');

  const renderFieldContent = (item, row, rowKey) => {
    const fieldContent = {
      accountEnabled: (
        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <CustomizedSwitches onChange={() => handleSwitchChange(row, rowKey)} check={row[item?.field]} />
          <Edit2
            size="32"
            color="#2A76F4"
            style={{
              height: '20px',
              width: '20px',
              cursor: 'pointer'
            }}
            onClick={() => handleEdit(row)}
          />
        </Box>
      ),
      personas: Array.isArray(row[item?.field]) ? row[item?.field]?.map((persona) => persona?.name)?.join(', ') : row[item?.field],
      isActive: row[item?.field] ? 'Active' : 'Inactive',
      status: row[item?.field] ? 'Active' : 'Inactive',
      actions: <ActionIcon onClick={() => handleRow(row)} />,
      updatedAt: moment(row[item?.field]).format('LL'),
      last_run_date: moment(row[item?.field]).format('LL'),
      fileUrl: (
        <a href={row[item?.field]} target="_blank" rel="noopener noreferrer">
          Link
        </a>
      )
    };

    return fieldContent[item?.field] || row[item?.field];
  };

  const renderRow = useCallback(
    (row, rowKey) => (
      <Box key={rowKey}>
        <ViewMore>
          {head?.map((item, index) => (
            <Box
              key={index}
              sx={{
                p: 0,
                py: '12px',
                borderBottom: '1px solid #E6E6E6'
              }}
              align="left"
              onClick={onTableClick}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box width="50%">
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '10px',
                      color: '#434C5B'
                    }}
                  >
                    {item?.field}
                  </Typography>
                </Box>
                <Box width="50%">
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '10px',
                      color: item?.field === 'isActive' || item?.field === 'status' ? (row[item?.field] ? '#5DB806' : 'red') : '#434C5B'
                    }}
                  >
                    {renderFieldContent(item, row, rowKey)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </ViewMore>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [head, onTableClick]
  );

  const renderSkeleton = () => (
    <Box>
      {Array.from(new Array(5))?.map((_, index) => (
        <Box key={index} sx={{ p: 0, py: '12px', borderBottom: '1px solid #E6E6E6' }}>
          {head?.map((item, idx) => (
            <Box
              key={idx}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton variant="text" width="50%" height={30} />
              <Skeleton variant="text" width="50%" height={30} />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );

  return (
    <div>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={itemsPerPageOptions}
          component="div"
          count={rowCount}
          page={paginationModel?.page}
          onPageChange={(event, newPage) => {
            handleSmPage(event, newPage);
          }}
          rowsPerPage={paginationModel?.pageSize || itemsPerPageOptions[0]}
          onRowsPerPageChange={(event) => {
            handleSmRowsPerPage(event);
          }}
          labelRowsPerPage=""
        />
      )}
      <div>{loading ? renderSkeleton() : rows?.map(renderRow)}</div>
    </div>
  );
};

export default memo(MobileScreenTable);
