import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, Modal, TextField, Typography } from '@mui/material';

import { getUsersList } from 'api/taskManagement.service';

const TaskAcceptModal = ({ open, handleClose, onAssign, submitting }) => {
  const [assignedTo, setAssignedTo] = useState([]);
  const [notes, setNotes] = useState('');
  const [assignToSelf, setAssignToSelf] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {
    // Fetch user list from API
    if (userList.length === 0) {
      setUserLoading(true);
      getUsersList()
        .then((response) => {
          setUserList(
            response?.data?.data?.map((user) => {
              return {
                id: user?.id,
                email: user?.employeeEmailId,
                name: user?.displayName
              };
            })
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setUserLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '1rem'
  };

  const handleCheckBoxChange = (e) => {
    setAssignToSelf(e.target.checked);
    if (e.target.checked) {
      setAssignedTo((prev) => [
        ...prev,
        {
          id: userDetails?.user?.id,
          email: userDetails?.user?.employeeEmailId,
          name: userDetails?.user?.displayName
        }
      ]);
    }
  };

  const handleSubmit = () => {
    onAssign({
      newStatus: 'Assigned',
      currentStatus: 'Pending Approval',
      assignedTo,
      notes
    });
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="task-assignment-modal" aria-describedby="task-assignment-form">
        <Box sx={style}>
          <Typography id="task-assignment-modal" variant="h3">
            Task Assignment
          </Typography>

          <Autocomplete
            multiple
            options={userList}
            getOptionLabel={(option) => option.name || ''}
            value={userList.filter((user) => assignedTo.some((assigned) => assigned.id === user.id)) || []}
            onChange={(event, newValues) =>
              setAssignedTo(
                newValues.map((newValue) => ({
                  id: newValue.id,
                  email: newValue.email,
                  name: newValue.name
                }))
              )
            }
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            loading={userLoading}
            disabled={assignToSelf}
            fullWidth
            sx={{ opacity: assignToSelf ? 0.6 : 1, marginY: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assign To"
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            key={userList.map((user) => user.employeeId).join(',')} // ensure unique key for the list
          />

          <TextField label="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} fullWidth multiline rows={3} margin="normal" />

          <FormControlLabel
            control={<Checkbox sx={{ '& path': { fill: '#006BD6' } }} checked={assignToSelf} onChange={handleCheckBoxChange} />}
            label="Assign to myself"
          />

          <Box mt={2} display="flex" justifyContent="flex-end" columnGap={2}>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={submitting} variant="contained" color="primary" onClick={handleSubmit}>
              {submitting ? <CircularProgress color="inherit" size={20} /> : 'Assign'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TaskAcceptModal;
