import React from 'react';
import { Typography } from '@mui/material';

import { SlidingBackground, StyledToggleButton, StyledToggleContainer } from './StyledToggle.styled';

const ToggleTabs = ({ data, selected, setSelected, onChange, disabled }) => {
  const handleClick = (value) => {
    if (disabled) {
      return;
    }
    if (setSelected) {
      setSelected(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  const selectedIndex = data.findIndex((item) => item.value === selected);

  return (
    <StyledToggleContainer
      disabled={disabled}
      sx={{
        flexDirection: { sm: 'row' }
      }}
    >
      <SlidingBackground
        disabled={disabled}
        layout // enables automatic layout animations with framer-motion
        initial={false}
        animate={{
          x: selectedIndex * 130 // assuming each tab has a width of 110px for sm size
        }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }} // smooth sliding effect
      />
      {data?.map(({ label, value }, index) => (
        <StyledToggleButton
          data-test-id={`toggle-tab-${label}`}
          component={'div'}
          onClick={() => handleClick(value)}
          textAlign={'center'}
          key={index}
          index={index}
          value={value}
          selected={selected}
          data={data}
          sx={{
            width: { xs: '85px', sm: '110px', cursor: disabled ? 'not-allowed' : 'pointer' }
          }}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: selected === value ? 600 : 400, lineHeight: '21px' }}>{label}</Typography>
        </StyledToggleButton>
      ))}
    </StyledToggleContainer>
  );
};

export default ToggleTabs;
