import { createSlice } from '@reduxjs/toolkit';

import { getInsightsData } from '../../api/api';

const initialState = {
  data: [],
  loading: false,
  error: ''
};

const InsightsDataSlice = createSlice({
  name: 'Insights',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInsightsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInsightsData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getInsightsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  }
});

export default InsightsDataSlice.reducer;
