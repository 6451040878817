import React from 'react';

import SelectedChips from 'ui-component/SelectedChips';
import SkeletonButtons from 'ui-component/Skeletons/SkeletonButtons';

function DataSourceChips({ chipData, selectedChip, handleTogleButton, loading }) {
  if (loading) {
    return <SkeletonButtons />;
  } else {
    return <SelectedChips chipData={chipData} selectedValue={selectedChip} handleTogleButton={handleTogleButton} />;
  }
}

export default DataSourceChips;
