import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Typography, Divider, Fade } from '@mui/material';
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  IconWrapper,
  MessageWrapper
} from './styles/ErrorModalStyles';

const ErrorModal = ({ open, onClose, message, isSuccess, context }) => {
  const successMessage = context === 'upload' ? 'Upload Successful' : 'Validation Successful';
  const failureMessage = context === 'upload' ? 'Upload Failed' : 'Validation Failed';

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth TransitionComponent={Fade} transitionDuration={300}>
      {context && (
        <>
          <StyledDialogTitle>
            <Box display="flex" alignItems="center">
              <IconWrapper success={isSuccess}>
                {isSuccess ? (
                  <CheckCircleOutlineIcon sx={{ fontSize: 32, color: 'success.main' }} />
                ) : (
                  <ErrorOutlineIcon sx={{ fontSize: 32, color: 'error.main' }} />
                )}
              </IconWrapper>
              <Typography variant="h4">{isSuccess ? successMessage : failureMessage}</Typography>
            </Box>
          </StyledDialogTitle>
          <Divider />
        </>
      )}

      <StyledDialogContent>
        <MessageWrapper>{message}</MessageWrapper>
      </StyledDialogContent>

      <StyledDialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color={isSuccess ? 'primary' : 'error'}
          sx={{
            minWidth: 120,
            mr: 1
          }}
        >
          {isSuccess ? 'Close' : 'Cancel'}
        </Button>
        {/* {!isSuccess && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              minWidth: 120,
              backgroundColor: (theme) => theme.palette.primary.main
            }}
          >
            Try Again
          </Button>
        )} */}
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ErrorModal;
