import { REGEX } from 'constants/regex';

export const INITIAL_ACTION_FORM = [
  {
    name: 'assetsOrUsers',
    labelText: 'Type',
    disabled: false,
    type: 'switch',
    required: true,
    options: [
      { label: 'Users', value: 'users' },
      { label: 'Assets', value: 'assets' }
    ],
    grid: {
      xs: 12,
      sm: 12,
      md: 12
    }
  },
  {
    name: 'current',
    labelText: 'Current State',
    type: 'text',
    grid: { xs: 12, sm: 12, md: 12 }
  },
  {
    name: 'target',
    labelText: 'Target State',
    type: 'text',
    required: true,
    grid: { xs: 12, sm: 12, md: 12 }
  },
  {
    name: 'impact',
    labelText: 'Impact Value',
    type: 'number',
    regex: REGEX['float'],
    regexMessage: 'Please enter a valid number',
    grid: { xs: 12, sm: 12, md: 12 }
  },
  {
    name: 'actionDescription',
    labelText: 'Action Description',
    type: 'text',
    required: true,
    grid: { xs: 12, sm: 12, md: 12 }
  },
  {
    name: 'actionType',
    labelText: 'Action Type',
    type: 'select',
    options: [
      { label: 'Generic', value: 'Generic' },
      { label: 'Upgrade User Count', value: 'Upgrade User Count' },
      { label: 'Upgrade Feature', value: 'Upgrade Feature' },
      { label: 'Upgrade Performance', value: 'Upgrade Performance' },
      { label: 'Upgrade Storage', value: 'Upgrade Storage' },
      { label: 'Downgrade User Count', value: 'Downgrade User Count' },
      { label: 'Downgrade Feature', value: 'Downgrade Feature' },
      { label: 'Downgrade Performance', value: 'Downgrade Performance' },
      { label: 'Downgrade Storage', value: 'Downgrade Storage' }
    ],
    required: true,
    grid: { xs: 12, sm: 12, md: 12 }
  },
  {
    name: 'note',
    labelText: 'Notes',
    type: 'text',
    grid: { xs: 12, sm: 12, md: 12 }
  }
];

export const INITIAL_TASK_FORM = [
  {
    name: 'name',
    labelText: 'Name',
    type: 'text',
    placeholder: 'Add owners to devices',
    required: true,
    grid: {
      xs: 12,
      sm: 12,
      md: 12
    }
  },
  {
    name: 'description',
    labelText: 'Description',
    type: 'text',
    placeholder: 'Enter Description',
    required: true,
    grid: {
      xs: 12,
      sm: 12,
      md: 12
    }
  },
  {
    name: 'priority',
    labelText: 'Priority',
    type: 'select',
    placeholder: 'Service Level Agreement',
    required: true,
    defaultValue: '',
    options: [
      {
        label: 'Low',
        value: 'Low'
      },
      {
        label: 'Medium',
        value: 'Medium'
      },
      {
        label: 'High',
        value: 'High'
      },
      {
        label: 'Time Sensitive',
        value: 'Time Sensitive'
      },
      {
        label: 'Critical with Due Date',
        value: 'Critical with Due Date'
      }
    ],
    grid: {
      xs: 12,
      sm: 12,
      md: 12
    }
  },
  {
    name: 'taskType',
    labelText: 'Type',
    type: 'select',
    required: true,
    // disabled: true,
    placeholder: 'Task Type',
    defaultValue: 'Externally Managed',
    MenuProps: {
      PaperProps: {
        style: { pointerEvents: 'none' } // Disable selection in the menu
      }
    },
    options: [
      {
        label: 'Externally Managed',
        value: 'Externally Managed'
      },
      {
        label: 'Channel Notification',
        value: 'Channel Notification'
      },
      {
        label: 'Automated Direct',
        value: 'Automated Direct'
      }
    ],
    grid: {
      xs: 12,
      sm: 12,
      md: 12
    }
  },
  {
    name: 'impactType',
    labelText: 'Impact Type',
    type: 'select',
    placeholder: 'Impact Type',
    defaultValue: 'Percent',
    required: true,
    options: [
      {
        label: 'Numbers',
        value: 'Numbers'
      },
      {
        label: 'Percent',
        value: 'Percent'
      },
      {
        label: 'Cost',
        value: 'Cost'
      }
    ]
  },
  {
    name: 'estimatedImpact',
    labelText: 'Estimated Impact',
    type: 'text',
    placeholder: 'Enter Estimated Impact',
    regex: REGEX['float'],
    regexMessage: 'Please enter a valid number',
    required: true,
    grid: {
      xs: 12,
      sm: 12,
      md: 12
    }
  },
  {
    name: 'expectedCompletionDate',
    labelText: 'Expected Completion Date',
    type: 'date',
    disablePast: true,
    required: true,
    grid: {
      xs: 12,
      sm: 12,
      md: 12
    }
  },
  {
    name: 'taskAssignedTo',
    labelText: 'Assign To',
    type: 'autocomplete',
    options: [],
    multiple: true,
    loadingText: 'Loading...',
    required: true,
    grid: {
      xs: 12,
      sm: 12,
      md: 12
    }
  },
  {
    name: 'note',
    labelText: 'Notes',
    type: 'text',
    grid: {
      xs: 12,
      sm: 12,
      md: 12
    }
  }
];
