import { createSlice } from '@reduxjs/toolkit';

import { getUniqueDraft } from '../../api/api';

const initialState = {
  data: {},
  loading: false,
  error: ''
};

const UniqueDraftSlice = createSlice({
  name: ['Unique draft'],
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUniqueDraft.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUniqueDraft.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getUniqueDraft.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  }
});

export default UniqueDraftSlice.reducer;
