import React from 'react';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { InfoCircle } from 'iconsax-react';

const OnHoverToolTip = ({ title, variant = 'icon', children, placement = 'bottom-end', onClick = () => {} }) => {
  const useStyles = makeStyles(() => ({
    tooltipText: {
      fontSize: '14px',
      fontWeight: 500,
      backgroundColor: 'black',
      padding: '12px 18px'
    }
  }));

  const classes = useStyles();

  switch (variant) {
    case 'icon':
      return (
        <Box display={'flex'} onClick={onClick}>
          <Tooltip title={title} arrow placement={placement} classes={{ tooltip: classes.tooltipText }} />
          <InfoCircle size={16} color={'#292D32'} />
        </Box>
      );
    case 'children':
      return (
        <Tooltip onClick={onClick} title={title} arrow placement={placement} classes={{ tooltip: classes.tooltipText }}>
          {children}
        </Tooltip>
      );
    default:
      return null;
  }
};

export default OnHoverToolTip;
