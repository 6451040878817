import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tooltipTypography: {
    maxWidth: '200px',
    maxHeight: '200px',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    wordSpacing: '0.3em',
    padding: '10px',
    fontSize: '12px'
  },
  tooltip: {
    backgroundColor: 'black',
    color: 'white',
    border: 'none'
  },
  box: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#FA2B2B',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    flexShrink: 0
  },
  infoCircle: {
    marginRight: '4px'
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 20px)',
    fontSize: '13px'
  }
}));

export default useStyles;
