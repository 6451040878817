const styles = {
  actionIcon: {
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#2A76F4',
      transition: 'color 0.1s ease'
    }
  },
  emptyRequiredCell: {
    border: '1px solid #FC8080',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    borderRadius: '5px',
    textAlign: 'center',
    padding: '1px 5px',
    width: '80px',
    height: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  navlink: {
    textDecoration: 'none',
    color: 'black'
  },
  activeChip: {
    backgroundColor: '#EFF8E6',
    color: '#4CAF50'
  },
  inactiveChip: {
    backgroundColor: '#FEE9E9',
    color: '#F44336'
  },
  urlColor: '#2A76F4',
  urlHoverColor: '#1E5BB8',
  urlButton: {
    color: '#2A76F4',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '&:hover': {
      color: '#1E5BB8',
      transition: 'color 0.1s ease'
    }
  },
  superTagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 0.1,
    my: 1,
    color: 'black',
    bgcolor: '#2A76F41A',
    p: 1,
    borderRadius: 2,
    width: '100%',
    maxHeight: '100px',
    overflow: 'auto',
    scrollbarWidth: 'none',
    position: 'relative',
    top: '41%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  personasTypography: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '10px',
    color: '#434C5B'
  },
  fileViewBox: {
    display: 'flex',
    alignItems: 'center'
  },
  fileViewTypography: {
    marginX: '5px'
  },
  fileViewLink: {
    color: '#2A76F4',
    textDecoration: 'underline',
    '&:hover': {
      color: '#1E5BB8'
    }
  },
  profileImage: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#E6EFFF',
    color: '#434C5B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold'
  }
};

export default styles;
