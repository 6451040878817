import { createSlice } from '@reduxjs/toolkit';

import { getMainServices } from '../../api/api';

const initialState = {
  data: [],
  loading: false,
  error: ''
};

const MainServicesSlice = createSlice({
  name: 'MainServices',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMainServices.pending, (state) => {
        state.data = [];
        state.loading = true;
      })
      .addCase(getMainServices.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getMainServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  }
});

export default MainServicesSlice.reducer;
