import { configuration } from 'services/baseApiService';

import { createHeadersWithToken } from './api';
import axios from './interceptors';

export const getAssetOverview = async ({ assetId, assetType }) => {
  const url = `${configuration.insightsDevBaseUrl}api/v1/asset/${assetId}/screen/overview?asset_type=${assetType}`;

  try {
    const response = await axios.get(url, createHeadersWithToken());
    return response.data;
  } catch (error) {
    console.error('Error fetching asset overview:', error);
    throw error;
  }
};

export const getAssetScreenData = async ({ assetId, assetType, screen }) => {
  const url = `${configuration.insightsDevBaseUrl}api/v1/asset/${assetId}/screen/${screen}?asset_type=${assetType}`;

  try {
    const response = await axios.get(url, createHeadersWithToken());
    return response.data;
  } catch (error) {
    console.error('Error fetching asset screen data:', error);
    throw error;
  }
};

export const getAssetTabScreens = async ({ assetType }) => {
  const url = `${configuration.insightsDevBaseUrl}api/v1/asset/${assetType}/screens`;
  try {
    const response = await axios.get(url, createHeadersWithToken());
    return response.data;
  } catch (error) {
    console.error('Error fetching asset tab screens:', error);
    throw error;
  }
};

export const getLicenseForm = async (licenseId) => {
  const url = `${configuration.adminURL}data-wrapper/get-license-form/${licenseId}`;

  try {
    const response = await axios.get(url, createHeadersWithToken());
    return response;
  } catch (error) {
    console.error('Error fetching license form:', error);
    throw error;
  }
};

export const updateLicenseForm = async (licenseId, formData) => {
  const url = `${configuration.adminURL}data-wrapper/update-license-using-form/${licenseId}`;

  try {
    const response = await axios.put(url, formData, createHeadersWithToken());
    return response;
  } catch (error) {
    console.error('Error updating license form:', error);
    throw error;
  }
};

export const deleteLicense = async (licenseId) => {
  const url = `${configuration.adminURL}data-wrapper/delete-license/${licenseId}`;

  try {
    const response = await axios.delete(url, createHeadersWithToken());
    return response;
  } catch (error) {
    console.error('Error deleting license:', error);
    throw error;
  }
};
