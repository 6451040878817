import React from 'react';
import { Box, Skeleton } from '@mui/material';

function SkeletonButtons() {
  return (
    <Box display={'flex'} gap={'1em'}>
      <Skeleton variant="rectangular" width={60} height={40} sx={{ borderRadius: '8px' }} />
      <Skeleton variant="rectangular" width={100} height={40} sx={{ borderRadius: '8px' }} />
      <Skeleton variant="rectangular" width={140} height={40} sx={{ borderRadius: '8px' }} />
    </Box>
  );
}

export default SkeletonButtons;
