import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';

import ActionUpdateModal from 'ui-component/Modal/ActionUpdateModal';
import CreateAction from 'ui-component/Modal/CreateAction';
import DeleteModal from 'ui-component/Modal/DeleteModal';
import CommonTable from 'ui-component/table/DataSourceTable/CommonTable';
import { useFunctionContext } from 'views/common/FunctionContext';

const ActionStateManagement = (props) => {
  const { title, sectionProps, apiCallOnPage, tableData, params, selectedChip, setSelectedChip, tableTotalCount, children, toolbarConfig } =
    props;
  const [open, setOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [row, setRow] = useState(null);
  const [taskState, setTaskState] = useState({
    from: 'Not Started',
    to: 'Pending'
  });
  const [actionId, setActionId] = useState(null);
  const [tableState, setTableState] = useState({
    page_number: 0,
    page_size: 10
  });

  const handleRefreshTable = () => {
    apiCallOnPage({ ...params, ...tableState });
  };

  const { setSharedFunction } = useFunctionContext() || {};

  useEffect(() => {
    if (setSharedFunction) {
      setSharedFunction({ handleActionRefresh: handleRefreshTable });
    }
    // eslint-disable-next-line
  }, []);

  const handleStatusChange = useMemo(
    () => (e, id, from, to) => {
      // {
      //   "id": "string",
      //   "newStatus": "Success",
      //   "currentStatus": "Success"
      // }
      // update from and to state of the task
      //  * It is triggered for the status update dropdown click
      // * from and to state is sent by the table dropdown and we set it here

      setUpdateModalOpen(true);
      setActionId(id);
      setTaskState({
        from,
        to
      });
    },
    []
  );

  const handleRowDelete = (row) => {
    if (row?.delete) {
      setRow(row);
      setShowDeleteModal(true);
    } else {
      return;
    }
  };

  return (
    <>
      <CommonTable
        toolbarConfig={toolbarConfig}
        apiCallOnPage={apiCallOnPage}
        tableData={tableData}
        req_params={params}
        title={title}
        disableExport={sectionProps?.disableExport}
        toggleButtons={sectionProps?.toggleButtons}
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
        tableTotalCount={tableTotalCount}
        searchBar={sectionProps?.searchBar}
        setTableState={setTableState}
        onRowDelete={handleRowDelete}
        // temp drill
        // handleTaskOpen={handleTaskOpen}
        handleStatusChange={handleStatusChange}
        // taskState={taskState}
      >
        {children}
      </CommonTable>
      {open && <CreateAction open={open} handleClose={() => setOpen(false)} handleRefreshTable={handleRefreshTable} />}
      {showDeleteModal && (
        <DeleteModal
          variant="action"
          handleRefreshTable={handleRefreshTable}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          row={row}
        />
      )}
      <ActionUpdateModal
        open={updateModalOpen}
        handleClose={() => setUpdateModalOpen(false)}
        from={taskState?.from}
        to={taskState?.to}
        actionId={actionId}
        setState={setTaskState}
        handleRefreshTable={handleRefreshTable}
      />
    </>
  );
};

export default ActionStateManagement;
