import React from 'react';
import { Box, Button } from '@mui/material';

function SelectedChips({ chipData = [], selectedValue = '', handleTogleButton }) {
  const chipSelectedValue = selectedValue ? selectedValue : chipData[0]?.value;

  return (
    <Box display={'flex'} gap={'1em'}>
      {chipData.map((chip, index) => (
        <Button
          key={index}
          variant={chip?.value === chipSelectedValue ? 'contained' : 'outlined'}
          color={'primary'}
          onClick={() => handleTogleButton(chip.value)}
        >
          {chip.label}
        </Button>
      ))}
    </Box>
  );
}

export default SelectedChips;
