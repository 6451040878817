import moment from 'moment';

import beaumont from 'assets/images/clinic/beaumont.svg';
import hca from 'assets/images/clinic/HCA Healthcare.svg';
import lifepoint from 'assets/images/clinic/Lifepoint Health.svg';
import piedmont from 'assets/images/clinic/piedmont.svg';
import prisma from 'assets/images/clinic/prisma.svg';
import tenet from 'assets/images/clinic/Tenet Health.svg';
import trinity from 'assets/images/clinic/Trinity Health.svg';
import UH from 'assets/images/clinic/UH.svg';

export const organizationImageMap = {
  Piedmont: piedmont,
  Prisma: prisma,
  Beaumont: beaumont,
  UH: UH,
  'LifePoint Health': lifepoint,
  'Trinity Health': trinity,
  'HCA HealthCare': hca,
  'Tenet HealthCare': tenet
};

//Always add the new routes dynamic routes at last
export const routeToPageMap = {
  '/hardware': 'hardware',
  '/software': 'software',
  '/home': 'home',
  '/asset': 'asset',
  '/datasets': 'datasets',
  '/datasets/list': 'datasets',
  '/datasets/catalogs': 'catalogs',
  '/link': 'link',
  '/link/detail': 'source-detail',
  '/tasks': 'tasks',
  '/report-tracker/insight/:id': 'user-insight',
  '/datasets/catalogs/:id': 'catalog-detail',
  '/datasets/list/:id': 'dataset-detail',
  '/tasks/:id': 'task-detail',
  '/asset/:assetType': 'asset_360',
  '/invoice-list': 'invoice-list',
  '/link/detail/:id': 'mkb-validation',
  '/data-product/:dataProductId': 'data-product',
  '/invoice-list/:invoiceId': 'invoice-detail'
  // Add other route : pageType here
};

export const ASK_ASATO_WIDGET_ENABLED_PAGES = ['model-insight'];

export const INSIGHT_STATES = [
  {
    label: 'Snoozed',
    value: 'snoozed',
    color: 'primary'
  },
  {
    label: 'Assigned',
    value: 'assigned',
    color: 'primary'
  },
  {
    label: 'WIP',
    value: 'work in progress',
    color: 'success'
  },
  {
    label: 'Reassigned',
    value: 'reassigned',
    color: 'primary'
  },
  {
    label: 'On Hold',
    value: 'on hold',
    color: 'warning'
  },
  {
    label: 'Completed',
    value: 'completed',
    color: 'success'
  },
  {
    label: 'Closed',
    value: 'closed',
    color: 'error'
  }
];

export const STATE_TRANSITIONS = {
  assigned: [
    { label: 'WIP', value: 'work in progress', color: 'success' },
    // { label: 'Reassigned', value: 'reassigned', color: 'primary' },
    { label: 'On Hold', value: 'on hold', color: 'warning' }
  ],
  'work in progress': [
    // { label: 'Reassigned', value: 'reassigned', color: 'primary' },
    { label: 'On Hold', value: 'on hold', color: 'warning' },
    { label: 'Completed', value: 'completed', color: 'success' } //only if latest outcome is there
  ],
  reassigned: [
    { label: 'WIP', value: 'work in progress', color: 'success' },
    { label: 'On Hold', value: 'on hold', color: 'warning' }
  ],
  'on hold': [
    { label: 'WIP', value: 'work in progress', color: 'success' },
    // { label: 'Reassigned', value: 'reassigned', color: 'primary' },
    { label: 'Closed', value: 'closed', color: 'danger' } // only if no latest outcome
  ],
  completed: [],
  closed: []
};

export const TASK_STATE_TRANSITIONS = {
  // pending: [
  //   { label: 'Accept', value: 'accept', color: 'primary' },
  //   { label: 'Reject', value: 'reject', color: 'primary' }
  // ],
  assigned: [
    { label: 'In Progress', value: 'in progress', color: '#FEEFD7', fill: '#FAAE35' },
    { label: 'Rejected', value: 'rejected', color: '#FEE9E9', fill: '#FF0000' },
    { label: 'Accepted', value: 'accepted', color: '#EFF8E6', fill: '#5DB806' }
  ],
  accepted: [
    { label: 'Accepted', value: 'accepted', color: '#00FF40', fill: 'green' },
    { label: 'In Progress', value: 'in progress', color: '#FEEFD7', fill: '#FAAE35' },
    { label: 'Rejected', value: 'rejected', color: '#FEE9E9', fill: '#FF0000' }
  ],
  'in progress': [
    { label: 'In Progress', value: 'in progress', color: '#FEEFD7', fill: '#FAAE35' },
    { label: 'Completed(with Failure)', value: 'completed-wf', color: '#FEE9E9', fill: '#FF0000' },
    { label: 'Completed', value: 'completed', color: '#EFF8E6', fill: '#5DB806' },
    { label: 'Failure', value: 'failure', color: '#FEE9E9', fill: '#FF0000' }
  ],
  'completed-wf': [],
  completed: [{ label: 'Completed', value: 'completed', color: '#EFF8E6', fill: '#5DB806' }],
  closed: [],
  rejected: [],
  failure: []
};

export const TASK_STATUS = [
  { value: 'In-Progress', bgColor: '#F0E68C', fill: 'orange' },
  { value: 'Completed (With Failures)', bgColor: '#F0E68C', fill: 'red' },
  { value: 'Assigned', bgColor: '#F0E68C', fill: 'brown' },
  { value: 'Accepted', bgColor: '#00FF40', fill: 'brown' },
  { value: 'Completed (Success)', bgColor: '#00FF40', fill: 'green' },
  { value: 'Completed', bgColor: '#00FF40', fill: 'green' },
  { value: 'Rejected', bgColor: '#FEE9E9', fill: '#FF0000' }
];
export const SLA_FACTOR = [
  { value: 'Critical with Due Date', bgColor: '#FEE9E9', fill: '#FF0000' },
  { value: 'Time Sensitive', bgColor: '#E9F3FE', fill: '#0077FF' },
  { value: 'Low', bgColor: '#EFF8E6', fill: '#5DB806' },
  { value: 'Medium', bgColor: '#FEEFD7', fill: '#FAAE35' },
  { value: 'High', bgColor: '#FEE9E9', fill: '#FF0000' }
];

export const STUB_ONBOARDING = {
  data: [
    {
      assetName: 'All',
      assetPercentage: 71,
      assetType: 'All',
      sourceStatus: [
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Failure'
        },
        {
          sourceId: 'microsoftclouddefender',
          sourceName: 'Microsoft Cloud Defender',
          status: 'Success'
        },
        {
          sourceId: 'browserextension',
          sourceName: null,
          status: 'Failure'
        },
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Failure'
        },
        {
          sourceId: 'microsoftclouddefender',
          sourceName: 'Microsoft Cloud Defender',
          status: 'Success'
        },
        {
          sourceId: 'browserextension',
          sourceName: null,
          status: 'Failure'
        }
      ]
    },
    {
      assetType: 'user',
      assetName: 'User',
      assetPercentage: 100,
      sourceStatus: [
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        }
      ]
    },
    {
      assetType: 'app',
      assetName: 'App',
      assetPercentage: 100,
      sourceStatus: [
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        }
      ]
    },
    {
      assetType: 'license',
      assetName: 'License',
      assetPercentage: 100,
      sourceStatus: [
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Success'
        }
      ]
    },
    {
      assetType: 'invoice',
      assetName: 'Cost',
      assetPercentage: 0,
      sourceStatus: [
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Failure'
        }
      ]
    },
    {
      assetType: 'usage',
      assetName: 'Usage',
      assetPercentage: 0,
      sourceStatus: [
        {
          sourceId: 'microsoftclouddefender',
          sourceName: 'Microsoft Cloud Defender',
          status: 'Success'
        },
        {
          sourceId: 'browserextension',
          sourceName: null,
          status: 'Failure'
        }
      ]
    }
  ]
};

export const INSIGHT_TABLE_TOOLTIP = {
  most_used_paid_apps:
    'Top 5 paid apps in the org, ordered by percentage of license utilized; number of users/number of licenses used per app. When there is a tie, the app with the higher total annual cost is chosen',
  risky_app_usage: 'Top 5 apps in the org that do not have SOC2 compliance, ordered by number of users per app in the selected time period',
  top_5_apps_cost: 'Annualized cost of the top 5 Apps. (Date filter is not applicable for this chart.)',
  top_5_category_by_cost: 'Annualized cost of the top 5 Product Categories. (Date filter is not applicable for this chart.)',
  top_5_vendors_by_cost: 'Annualized cost of the top 5 Vendors. (Date filter is not applicable for this chart.)',
  least_used_paid_apps:
    'Bottom 5 paid apps in the org, ordered by percentage of license utilized; number of users/number of licenses used per app. When there is a tie, the app with the higher total annual cost is chosen',
  most_used_apps: 'Top 5 apps in the org, ordered by number of users per app in the selected time period',
  most_used_apps_by_category: 'Top 5 product categories, ordered by number of apps per product category in the selected time period',
  most_used_apps_by_user_bytes: 'Top 5 apps in the org, ordered by employee usage (user-bytes, user-mins) in the selected time period'
};

export const STEPS_WITH_TYPE = {
  detailed: {
    assigned: ['Insight', 'Decision', 'Expected Outcome'],
    'work in progress': ['Decision', 'Expected Outcome', 'Outcome'],
    reassigned: ['Decision', 'Expected Outcome', 'Outcome'],
    'on hold': ['Decision', 'Expected Outcome', 'Outcome']
  },
  summary: {
    assigned: ['Insight', 'Decision', 'Expected Outcome'],
    'work in progress': ['Decision', 'Expected Outcome', 'Outcome'],
    reassigned: ['Decision', 'Expected Outcome', 'Outcome'],
    'on hold': ['Decision', 'Expected Outcome', 'Outcome']
  },
  history: {
    completed: ['Decision', 'Expected Outcome', 'Outcome'],
    closed: ['Decision', 'Expected Outcome', 'Outcome']
  }
};

export const DECISION_SLIDER_MARKS = [
  {
    value: -101
    // label: 'Less than expected'
  },
  {
    value: 0
    // label: '0% Achieved'
  },
  {
    value: 100
    // label: '100% Achieved'
  },
  {
    value: 101
    // label: 'More than expected'
  }
];

export const DATE_RANGES = {
  last7: { startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
  last30: { startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
  last90: { startDate: moment().subtract(90, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') }
};

export const GROUP_BY_KEYS_TABLE = {
  apps_top10_paid_avg_cost: 'appName',
  apps_paid_with_usage: 'appName',
  apps_paid_no_usage: 'appName',
  cost_paid_apps_no_usage: 'appName'
};

export const REACT_QUILL_CONFIG = {
  toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], [{ indent: '-1' }, { indent: '+1' }], ['link']]
};

export const REACT_QUILL_FORMATS = ['bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link'];

export const MOCK_DATA_FG = {
  nodes: [
    {
      id: 1,
      name: 'Node 1'
    },
    { id: 2, name: 'Node 2', color: 'red', size: 10 },
    {
      id: 7,
      name: 'Node 7',
      color: 'green',
      size: 10
    },
    { id: 13, name: 'Node 13' },
    { id: 14, name: 'Node 14' }
  ],
  links: [
    { source: 1, target: 2, id: '1-2' },
    { source: 1, target: 7, id: '1-7' },
    { source: 1, target: 13, id: '1-13' },
    { source: 1, target: 14, id: '1-14' }
  ]
};

export const BAR_CHART_STUB = [
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft SharePoint Online.svg',
    xaxisValue: 'Microsoft SharePoint Online',
    yaxisValue: 590
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Office Portal.svg',
    xaxisValue: 'Office Portal',
    yaxisValue: 357
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/MSN.svg',
    xaxisValue: 'MSN',
    yaxisValue: 354
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft Defender SmartScreen.svg',
    xaxisValue: 'Microsoft Defender SmartScreen',
    yaxisValue: 335
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Azure Content Delivery Network.svg',
    xaxisValue: 'Azure Content Delivery Network',
    yaxisValue: 331
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft Graph.svg',
    xaxisValue: 'Microsoft Graph',
    yaxisValue: 316
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Skype for Business.svg',
    xaxisValue: 'Skype for Business',
    yaxisValue: 312
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft Exchange Online.svg',
    xaxisValue: 'Microsoft Exchange Online',
    yaxisValue: 305
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft Store.svg',
    xaxisValue: 'Microsoft Store',
    yaxisValue: 302
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft OneDrive for Business.svg',
    xaxisValue: 'Microsoft OneDrive for Business',
    yaxisValue: 298
  }
];

export const VARIANCE_CHART_BENCHMARK_OU = [
  {
    values: {
      'MICROSOFT 365 E5': 2775,
      F1: 0,
      'F3(Teams Desktop + Sec)': 0,
      'F3(Teams Online + Sec)': 475,
      'Not active': 0,
      'EXCHANGE ONLINE (PLAN 1)': 0,
      'EXCHANGE ONLINE (PLAN 2)': 0,
      'MICROSOFT 365 AUDIO CONFERENCING': 0,
      'MICROSOFT 365 BUSINESS BASIC': 0,
      'MICROSOFT 365 BUSINESS STANDARD': 0,
      'MICROSOFT TEAMS ESSENTIALS': 0,
      'MICROSOFT TEAMS PREMIUM INTRODUCTORY PRICING': 0,
      'MICROSOFT TEAMS ROOMS BASIC': 0,
      'MICROSOFT 365 BUSINESS PREMIUM': 0,
      'MICROSOFT 365 E3': 0,
      'MICROSOFT TEAMS ROOMS PRO': 0,
      'TEAMS PHONE WITH CALLING PLAN (COUNTRY ZONE 1 - US)': 0
    },
    variance: {
      'MICROSOFT 365 E5': 1373,
      F1: -299,
      'F3(Teams Desktop + Sec)': 0,
      'F3(Teams Online + Sec)': 185,
      'Not active': 0,
      'EXCHANGE ONLINE (PLAN 1)': 0,
      'EXCHANGE ONLINE (PLAN 2)': 0,
      'MICROSOFT 365 AUDIO CONFERENCING': 0,
      'MICROSOFT 365 BUSINESS BASIC': -3,
      'MICROSOFT 365 BUSINESS STANDARD': -11,
      'MICROSOFT TEAMS ESSENTIALS': 0,
      'MICROSOFT TEAMS PREMIUM INTRODUCTORY PRICING': 0,
      'MICROSOFT TEAMS ROOMS BASIC': 0,
      'MICROSOFT 365 BUSINESS PREMIUM': 0,
      'MICROSOFT 365 E3': 0,
      'MICROSOFT TEAMS ROOMS PRO': 0,
      'TEAMS PHONE WITH CALLING PLAN (COUNTRY ZONE 1 - US)': 0
    }
  }
];

export const VARIANCE_CHART_BENCHMARK_UU = [
  {
    values: {
      'West Cobb': 40,
      'Macon Occupational Medicine': 38,
      'Verdae (Greenville)': 29,
      Powdersville: 30,
      'West Bloomfield': 20,
      Austell: 25,
      'East Point': 27,
      'Greenville - Woodruff': 19,
      Fayetteville: 28,
      Medina: 24
    },
    variance: {
      'West Cobb': 26,
      'Macon Occupational Medicine': 17,
      'Verdae (Greenville)': 15,
      Powdersville: 14,
      'West Bloomfield': 11,
      Austell: 11,
      'East Point': 11,
      'Greenville - Woodruff': 10,
      Fayetteville: 10,
      Medina: 10
    }
  }
];

export const WATERFALL_CHART_BENCHMARK_CAU = [
  {
    category: 'F3(Teams Online + Sec) to F1',
    displayValue: 70061.68,
    toolTip: 'Number of users: 299',
    open: 0,
    stepValue: 70061.68,
    value: 70061.68
  },
  {
    category: 'Total Savings',
    displayValue: 70061.68,
    toolTip: 'Number of users: 299',
    open: 0,
    stepValue: 70061.68,
    value: 70061.68
  }
];

export const LINE_BAR_CHART_OPTIMIZATION = {
  Title: 'Microsoft Office 365 Optimisation',
  Y1_axis: '# of Licenses',
  Y2_axis: 'Annual Cost ($)',
  X_axis: ['Now', 'Optimised'],
  value: [
    {
      category: 'Now',
      E5: 2775,
      'F3+': 225,
      'Annual Cost': 1605000
    },
    {
      category: 'Optimised',
      E5: 500,
      'F3+': 2500,
      'Annual Cost': 760500
    }
  ],
  Textual_Insight: 'Optimising your Microsoft Office 365 licenses allocation will save you $0.9M annually'
};

export const AMCHARTS_WATERFALL_DATA = [
  {
    category: 'Net revenue',
    value: 8786,
    open: 0,
    stepValue: 8786,
    displayValue: 8786
  },
  {
    category: 'Cost of sales',
    value: 8786 - 2786,
    open: 8786,
    stepValue: 8786 - 2786,
    displayValue: 2786
  },
  {
    category: 'Operating expenses',
    value: 8786 - 2786 - 1786,
    open: 8786 - 2786,
    stepValue: 8786 - 2786 - 1786,
    displayValue: 1786
  },
  {
    category: 'Amortisation',
    value: 8786 - 2786 - 1786 - 453,
    open: 8786 - 2786 - 1786,
    stepValue: 8786 - 2786 - 1786 - 453,
    displayValue: 453
  },
  {
    category: 'Income from equity',
    value: 8786 - 2786 - 1786 - 453 + 1465,
    open: 8786 - 2786 - 1786 - 453,
    stepValue: 8786 - 2786 - 1786 - 453 + 1465,
    displayValue: 1465
  },
  {
    category: 'Operating income',
    value: 8786 - 2786 - 1786 - 453 + 1465,
    open: 0,
    displayValue: 8786 - 2786 - 1786 - 453 + 1465
  }
];

export const AMCHARTS_MULTI_SERIES_BAR_DATA = [
  {
    category: 'Q1',
    color: '#213555',
    values: {
      q1v1: 100
    }
  },
  {
    category: 'Q2',
    color: '#3E5879',
    values: {
      q2v1: 120,
      q2v2: 210,
      q2v3: 310
    }
  },
  {
    category: 'Q3',
    color: '#D8C4B6',
    values: {
      q3v1: 130,
      q3v2: 220,
      q3v3: 320
    }
  },
  {
    category: 'Q4',
    color: '#4DA1A9',
    values: {
      q4v1: 140,
      q4v2: 230,
      q4v3: 330
    }
  }
];

export const AMCHARTS_SANKEY_DATA = {
  id: 'Total_Over_Provisioned_Assets',
  icon: '',
  info: 'Tech provisioning analysis across Tiers and organizations',
  Title: 'Technology provisioning analysis across Tiers and organizations',
  category: 'Benchmark',
  isEnabled: true,
  valueLabel: null,
  chartType: 'sankeychart',
  value: {
    node_categories: ['category1', 'category2', 'category3', 'category4'],
    node_values: [
      { Total: 2764 },
      { 'E5*': 2628 },
      { 'F3(Teams Online + Sec)*': 136 },
      { E5: 1453 },
      { 'F3(Teams Dekstop + Sec)': 811 },
      { 'F3(Teams Online + Sec)': 227 },
      { Deactivate: 273 },
      { 'Adv Practice Providers': 543 },
      { 'Clinical Svc Reps': 752 },
      { 'Revenue Management': 235 },
      { 'Patient Svc Reps': 457 },
      { Physicians: 154 },
      { Others: 623 }
    ],
    links: [
      { from: 'Total', to: 'E5*', thickness: 2628 },
      { from: 'Total', to: 'F3(Teams Online + Sec)*', thickness: 136 },
      { from: 'E5*', to: 'E5', thickness: 1449 },
      { from: 'E5*', to: 'F3(Teams Dekstop + Sec)', thickness: 780 },
      { from: 'E5*', to: 'F3(Teams Online + Sec)', thickness: 216 },
      { from: 'E5*', to: 'Deactivate', thickness: 183 },
      { from: 'F3(Teams Online + Sec)*', to: 'E5', thickness: 4 },
      { from: 'F3(Teams Online + Sec)*', to: 'Deactivate', thickness: 90 },
      { from: 'F3(Teams Online + Sec)*', to: 'F3(Teams Dekstop + Sec)', thickness: 31 },
      { from: 'F3(Teams Online + Sec)*', to: 'F3(Teams Online + Sec)', thickness: 11 },
      { from: 'E5', to: 'Adv Practice Providers', thickness: 233 },
      { from: 'E5', to: 'Clinical Svc Reps', thickness: 379 },
      { from: 'E5', to: 'Revenue Management', thickness: 226 },
      { from: 'E5', to: 'Patient Svc Reps', thickness: 221 },
      { from: 'E5', to: 'Physicians', thickness: 36 },
      { from: 'E5', to: 'Others', thickness: 358 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Adv Practice Providers', thickness: 205 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Clinical Svc Reps', thickness: 279 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Patient Svc Reps', thickness: 174 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Physicians', thickness: 53 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Revenue Management', thickness: 3 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Others', thickness: 97 },
      { from: 'F3(Teams Online + Sec)', to: 'Adv Practice Providers', thickness: 66 },
      { from: 'F3(Teams Online + Sec)', to: 'Clinical Svc Reps', thickness: 56 },
      { from: 'F3(Teams Online + Sec)', to: 'Patient Svc Reps', thickness: 28 },
      { from: 'F3(Teams Online + Sec)', to: 'Physicians', thickness: 39 },
      { from: 'F3(Teams Online + Sec)', to: 'Others', thickness: 38 },
      { from: 'Deactivate', to: 'Patient Svc Reps', thickness: 34 },
      { from: 'Deactivate', to: 'Clinical Svc Reps', thickness: 38 },
      { from: 'Deactivate', to: 'Physicians', thickness: 26 },
      { from: 'Deactivate', to: 'Adv Practice Providers', thickness: 39 },
      { from: 'Deactivate', to: 'Revenue Management', thickness: 6 },
      { from: 'Deactivate', to: 'Others', thickness: 130 }
    ]
  },
  footers: [],
  xaxisTitle: '',
  yaxisTitle: '',
  xaxisValueLabel: null,
  yaxisValueLabel: null,
  insightText: [
    'Piedmont has the highest Over Provisioned issue with 59 clinics, highlighting potential inefficiencies in resource allocation.',
    'Piedmont has the highest Under Provisioned issue with 11 clinics, indicating specific provisioning challenges.'
  ]
};

export const AMCHARTS_STACKED_COLUMN_DATA = {
  id: 'asset_drift_across_organization',
  icon: 'Monitor',
  title: 'Hardware drift status across organizations',
  info: 'An overview of hardware assets categorized by its respective owning organizations and its current drift status.',
  chartValues: [
    {
      category: 'UH',
      unchanged: 163,
      unknown: 30,
      drifted: 26
    },
    {
      category: 'Beaumont',
      unchanged: 257,
      drifted: 12,
      unknown: 229
    },
    {
      category: 'Prisma',
      unchanged: 219,
      unknown: 22,
      drifted: 5
    },
    {
      category: 'Piedmont',
      unknown: 507,
      drifted: 29,
      unchanged: 1306
    }
  ],
  groupedValues: null,
  toolTip: null,
  isEnabled: true,
  type: 'stackedbarchart',
  xAxisTitle: 'Organization',
  yAxisTitle: 'Hardware Status',
  description: null,
  insightText: [
    'UH has the highest drift percentage of assets (11.87%) with defaulters pertaining to 7 Clinics and a total number of drifted assets equal to 26.'
  ],
  category: 'asset_drift',
  displayType: null,
  valueLabel: null,
  yAxisTitle1: null,
  yAxisTitle2: null,
  dataProductId: null,
  format: null,
  lineSeries: null,
  insightId: null,
  isPositive: null,
  isClickable: true,
  forecast: false,
  dropdownOption: null
};

export const DEFAULT_TOOLBAR_CONFIG_OVERRIDES = [
  {
    value: 'download',
    label: 'Download'
  },
  {
    value: 'filter',
    label: 'Filter'
  },
  {
    value: 'columns',
    label: 'Columns'
  },
  {
    value: 'density',
    label: 'Density'
  },
  {
    value: 'refresh',
    label: 'Refresh'
  }
];
