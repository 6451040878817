import { createSlice } from '@reduxjs/toolkit';

import { getConnecterDetails } from '../../api/api';

const initialState = {
  data: {},
  loading: false,
  error: ''
};

const ConnectorDetailsSlice = createSlice({
  name: 'connector_details',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getConnecterDetails.pending, (state) => {
        state.data = {};
        state.loading = true;
      })
      .addCase(getConnecterDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getConnecterDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = {};
      });
  }
});

export default ConnectorDetailsSlice.reducer;
