import React, { useState } from 'react';
import { Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material';

import { updateTaskStatus } from 'api/taskManagement.service';
import useNotification from 'hooks/useNotifications';
import { useFunctionContext } from 'views/common/FunctionContext';

const TaskUpdateModal = ({ open, handleClose, from, to, taskId, setState, handleRefreshTable }) => {
  const [updateReason, setUpdateReason] = useState('');
  const [loading, setLoading] = useState(false);
  const { error: errorNotification, success: successNotification } = useNotification();
  const { sharedFunction } = useFunctionContext() || {};
  const handleRefreshCards = sharedFunction?.handleRefreshCards;
  const isNoteMandatory = [
    'Rejected',
    'Completed (With Failures)',
    'Completed (Success)',
    'Failure',
    'Completed',
    'Closed by User'
  ].includes(to);
  const handleUpdate = () => {
    if (isNoteMandatory && !updateReason) {
      errorNotification('Please enter a reason for the status update', 3000);
    } else {
      setLoading(true);
      updateTaskStatus(taskId, {
        newStatus: to,
        completionDate: new Date(),
        currentStatus: from,
        note: updateReason
      })
        .then((res) => {
          if (res.status === 200) {
            setState({ from, to });
            successNotification('Task updated successfully', 3000);
            handleRefreshTable();
            if (handleRefreshCards) {
              handleRefreshCards();
            }
          } else {
            errorNotification('Error updating task', 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          errorNotification('Error updating task', 3000);
        })
        .finally(() => {
          setUpdateReason('');
          handleClose();
          setLoading(false);
        });
    }
  };

  const handleCloseModal = () => {
    setUpdateReason('');
    handleClose();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 2
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="reject-task-modal" aria-describedby="reject-task-form">
      <Box sx={style}>
        <Typography id="reject-task-modal" variant="h3" component="h2" mb={1}>
          Task Status Update
        </Typography>

        <TextField
          label={isNoteMandatory && !updateReason ? 'Note (Required)' : 'Note'}
          required={isNoteMandatory && !updateReason}
          placeholder="Enter the reason for the status update"
          value={updateReason}
          onChange={(e) => setUpdateReason(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="outlined" color="primary" onClick={handleCloseModal}>
            Cancel
          </Button>
          {/* ! Hard code alert */}
          <Button
            variant="contained"
            color="primary"
            disabled={loading || (isNoteMandatory && !updateReason)} // Disable the button during loading
            onClick={handleUpdate}
            // Todo : Remove inline style
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ visibility: loading ? 'hidden' : 'visible' }}>Update</Box>
            {loading && (
              <CircularProgress
                size={24}
                color="inherit"
                sx={{
                  position: 'absolute'
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TaskUpdateModal;
