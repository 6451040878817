import ErrorPage from 'pages/data-not-found';
import { useEffect } from 'react';
import { Suspense } from 'react';
import { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { changePage } from 'store/slices/PageSlice';
import DotLoader from 'ui-component/LoadingScreen/Loader';
import { LoaderContainer } from 'styled-components/global';

const AskAsato = import.meta.env.VITE_STORYBOOK
  ? lazy(() => import('test/mocks/AskAsatoMocks').catch(() => ({ default: () => null })))
  : lazy(() => import('air/AskAsato').catch(() => ({ default: () => <ErrorPage errorType="remoteComponentError" /> })));

const AskAsatoService = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePage('ask-asato'));
  }, []);

  return (
    <Suspense
      fallback={
        <LoaderContainer>
          <DotLoader />
        </LoaderContainer>
      }
    >
      <AskAsato />
    </Suspense>
  );
};

export default AskAsatoService;
