import { Outlet } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import Header from 'layout/MainLayout/Header';

import { StyledScrollbarWrapper } from 'styled-components/global';
import InternetConnectionCard from 'ui-component/common/internet-connection';

import Sidebar from './sidebar';

const AppLayout = ({ children }) => {
  return (
    <StyledScrollbarWrapper>
      <Toolbar sx={{ backgroundColor: '#021A42', zIndex: 999 }}>
        <Header />
      </Toolbar>
      <Box display="flex" position={'relative'} minHeight={'calc(100vh - 80px)'}>
        <Sidebar />
        <Box width="100%" paddingY={1} paddingX={2} maxHeight={'calc(100vh - 80px)'} overflow={'auto'}>
          {children}
          <Outlet />
        </Box>
      </Box>

      <InternetConnectionCard />
    </StyledScrollbarWrapper>
  );
};

export { AppLayout };
