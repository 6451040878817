import { getAssetList } from 'api/taskManagement.service';

export const getUserFormSelectInput = ({ formKeyName, formLabelText, gridSize }) => {
  return {
    name: formKeyName,
    labelText: formLabelText,
    type: 'autocomplete-with-api',
    apiPath: `api/v1/list/users`,
    searchKey: 'userName',
    required: true,
    grid: {
      xs: 12,
      sm: 12,
      md: gridSize,
      lg: gridSize
    }
  };
};

export const getAssetFormSelectInput = async ({ formKeyName, formLabelText, setLoading, assetType, gridSize }) => {
  setLoading(true);
  try {
    const assetList = await getAssetList(assetType);
    const assets = assetList.data.map((asset) => {
      return {
        label: asset.assetName,
        value: asset
      };
    });
    return {
      name: formKeyName,
      labelText: formLabelText,
      type: 'autocomplete',
      options: assets,
      grid: {
        xs: 12,
        sm: 12,
        md: gridSize,
        lg: gridSize
      }
    };
  } catch (err) {
    console.error(err);
  } finally {
    setLoading(false);
  }
};
