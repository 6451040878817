import { Card, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const DataGridSkeleton = () => {
  const skeletonItems = Array.from({ length: 8 });

  return (
    <Card sx={{ width: '100%' }} data-test-id="skeleton-loading">
      <Grid container direction="column">
        <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton variant="rectangular" sx={{ my: 2, borderRadius: 4 }} height={50} width={'300px'} />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" height={60} sx={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }} />
        </Grid>
        <Grid item>
          {skeletonItems.map((_, index) => (
            <Skeleton key={index} variant="rectangular" sx={{ my: 2, borderRadius: 2 }} height={30} />
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};

export default DataGridSkeleton;
