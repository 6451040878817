import React from 'react';
import { Button } from '@mui/material';

import { StyledButton } from 'styled-components/global';
import OnHoverToolTip from 'ui-component/Tooltips/OnHoverToolTip';

import CustomizedSwitches from './CustomSwitch';
import IconButton from './IconButton';

const GlobalButton = (props) => {
  switch (props.type) {
    case 'custom-switch':
      return <CustomizedSwitches {...props} />;

    case 'icon':
      return <IconButton {...props} />;

    case 'icon-text': {
      return (
        <Button {...props} startIcon={<IconButton icon={props.icon} size={props.size} color={props.color} />}>
          {props.children}
        </Button>
      );
    }

    default:
      return (
        <OnHoverToolTip title={props?.children} variant="children">
          <StyledButton {...props}>{props?.children}</StyledButton>
        </OnHoverToolTip>
      );
  }
};

export default GlobalButton;
