import React, { useCallback, useState } from 'react';
import { Box, MenuItem, Popover } from '@mui/material';
import { More } from 'iconsax-react';

const PopOverTab = ({ divOptions, params }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const filteredOptions = divOptions?.filter((option) => option.label === 'View' || (option.label === 'Edit' && params?.row?.isEditable));

  return (
    <>
      <MenuItem
        sx={{
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          borderRadius: '50px'
        }}
        component="div"
        onClick={handleClick}
      >
        <More size={24} color="#353D4D" />
      </MenuItem>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ borderRadius: '8px', overflow: 'hidden' }}
      >
        {filteredOptions?.map((option, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            gap="14px"
            alignItems="center"
            sx={{
              padding: '12px 24px 12px 12px',
              cursor: 'pointer'
            }}
            onClick={() => {
              option.onClick(params?.row);
              handleClose();
            }}
          >
            {option.icon}
            {option.right_icon}
          </Box>
        ))}
      </Popover>
    </>
  );
};

export default PopOverTab;
