const fileContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #E0E0E0',
  padding: '8px',
  borderRadius: '8px',
  width: '100%',
  backgroundColor: '#FFFFFF',
  marginBottom: '8px',
  '&:last-child': {
    marginBottom: 0
  }
};

const styles = {
  root: {
    position: 'relative',
    width: '100%'
  },
  dropzone: (disabled, height = '35svh') => ({
    minHeight: height,
    minWidth: '40svh',
    maxHeight: '35svh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#2A76FA',
    borderStyle: 'dashed',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: disabled ? 'no-drop' : 'pointer',
    margin: '5px 0',
    backgroundColor: '#F7FAFF',
    opacity: disabled ? 0.6 : 1,
    gap: '1rem',
    padding: '1.2rem',
    position: 'relative'
  }),
  fileContainer: fileContainerStyles,
  fileNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  fileDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    width: '100%'
  },
  progress: {
    width: '300px',
    borderRadius: '4px'
  },
  progressContainer: {
    width: '100%',
    padding: '8px',
    backgroundColor: '#F7FAFF',
    borderRadius: '8px',
    marginTop: 1
  },
  uploadingText: {
    marginBottom: 1,
    textAlign: 'center',
    fontWeight: 500
  },
  successMessage: {
    color: 'green'
  },
  chooseFileText: {
    color: '#2A76FA'
  },
  scrollableFileBox: {
    height: '320px',
    overflowY: 'auto',
    width: '70%'
  },
  fileName: {
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 600
  },
  fileDetail: {
    color: '#666',
    maxWidth: '450px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fileInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  uploadButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 2
  },
  fileSize: {
    color: '#666',
    fontSize: '12px'
  },
  progressOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(247, 250, 255, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5
  },
  progressContent: {
    backgroundColor: '#FFFFFF',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
    marginTop: 2
  },
  cancelButton: {
    color: '#2A76FA',
    borderColor: '#2A76FA',
    flex: 1,
    '&:hover': {
      color: '#FB5555',
      borderColor: '#FDAAAA'
    }
  },
  uploadButton: {
    backgroundColor: '#2A76FA',
    flex: 1,
    '&:hover': {
      backgroundColor: '#1E5BC6'
    },
    '&:disabled': {
      backgroundColor: '#B6B6B6',
      color: '#FFFFFF'
    }
  },
  continueButton: {
    backgroundColor: '#2A76FA',
    flex: 1,
    '&:hover': {
      backgroundColor: '#1E5BC6'
    }
  },
  addMoreFilesContainer: {
    mb: 2,
    textAlign: 'center'
  },

  addMoreFilesText: {
    color: '#2A76FA',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  downloadableFileContainer: {
    ...fileContainerStyles,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F8F9FA'
    }
  }
};

export default styles;
