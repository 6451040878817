const styles = {
  container: {
    height: 'auto',
    width: '100%',
    borderRadius: '20px'
  },
  dataGrid: {
    borderRadius: '20px',
    '& .header': {
      backgroundColor: '#0D2349',
      color: 'white'
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: '#0D2349',
      color: 'white'
    },
    '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .MuiDataGrid-columnHeaders .MuiSvgIcon-root': {
      color: 'white'
    },
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '50vh',
      overflowY: 'scroll !important'
    }
  }
};

export default styles;
