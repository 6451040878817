import { lazy, Suspense, useState } from 'react';
import { AutoAwesome } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { CloseCircle } from 'iconsax-react';

import { StyledIconWrapper } from 'styled-components/global';
import { buttonBaseProps } from 'ui-component/table/DataSourceTable/CustomToolbar';

const AskAsatoWidget = import.meta.env.VITE_STORYBOOK
  ? lazy(() => import('test/mocks/AskAsatoMocks').catch(() => ({ default: () => null })))
  : lazy(() => import('air/AskAsatoWidget').catch(() => ({ default: () => null })));

const AskAsatoWidgetToolbar = ({ configItem }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Button {...buttonBaseProps} startIcon={<AutoAwesome />} onClick={handleToggle}>
        {configItem?.label || 'Ask Asato'}
      </Button>
      <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="flex-end" position="relative">
        {open && (
          <Suspense fallback={null}>
            <AskAsatoWidget>
              <StyledIconWrapper onClick={handleToggle} round={'full'} theme={'light'}>
                <CloseCircle size={24} />
              </StyledIconWrapper>
            </AskAsatoWidget>
          </Suspense>
        )}
      </Box>
    </>
  );
};

export default AskAsatoWidgetToolbar;
