import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router';
import { Button } from '@mui/material';
import { Add } from 'iconsax-react';

import InvoiceModalWrapper from 'ui-component/CustomWrappers/Modal/InvoiceModalWrapper';
import { DownloadContext } from 'ui-component/table/DataSourceTable/CommonTable';
import { buttonBaseProps } from 'ui-component/table/DataSourceTable/CustomToolbar';

const NewEntitlements = ({ configItem }) => {
  const createButtonBaseProps = {
    ...buttonBaseProps,
    startIcon: <Add />
  };
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const { entitlements, req_params } = useContext(DownloadContext) || {};
  const location = useLocation();

  //todo: take via route
  const shouldShowEntitlements = () => {
    const { search, pathname } = location;
    return !pathname.includes('/link') || new URLSearchParams(search).get('source_id') === 'license_manual_upload';
  };

  const handleUploadTypeOpen = () => {
    setInvoiceOpen(true);
  };

  const handleInvoiceClose = () => setInvoiceOpen(false);

  return (
    shouldShowEntitlements() &&
    entitlements && (
      <>
        <Button {...createButtonBaseProps} onClick={handleUploadTypeOpen}>
          {configItem?.label || 'New Entitlements'}
        </Button>
        <InvoiceModalWrapper open={invoiceOpen} onClose={handleInvoiceClose} req_params={req_params} />
      </>
    )
  );
};

export default NewEntitlements;
