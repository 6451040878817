import { lazy } from 'react';
// import { Data2 } from 'iconsax-react';

import Loadable from 'ui-component/Loadable';

import { DATA_PRODUCTS_PATHS } from './dataProducts.paths';

const DataProductDetailPage = Loadable(lazy(() => import('@features/DataProducts/DataProductDetail/DataProductDetailPage')));
// const DataProductsPage = Loadable(lazy(() => import('@features/DataProducts/DataProductsPage')));

export const DATA_PRODUCTS_ROUTES = [
  // {
  //   path: DATA_PRODUCTS_PATHS.DATA_PRODUCTS,
  //   element: <DataProductsPage />,
  //   name: 'Data Products',
  //   sidebar: true,
  //   beta: true,
  //   position: 'middle',
  //   rolePermission: ['user', 'executive'],
  //   svg: <Data2 />
  // },
  {
    path: DATA_PRODUCTS_PATHS.DATA_PRODUCTS_DETAIL,
    element: <DataProductDetailPage />,
    name: 'Data Product Detail',
    rolePermission: ['user', 'executive']
  }
];
