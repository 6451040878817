import React from 'react';
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';

import GlobalButton from 'ui-component/buttons';
import FormInput from 'ui-component/FormInputs';
import { convertJsonToYupSchema, getDisplayName, getEmptyFormValues } from 'utils';

const GlobalForm = forwardRef(
  (
    {
      children,
      initialValues,
      formData,
      onSubmit,
      onChange,
      yupSchema,
      resetOnSubmit = true,
      validateOnBlur = false,
      validateOnChange = false,
      enableReinitialize = false,
      emptyValues = getEmptyFormValues(formData)
    },
    ref
  ) => {
    const validationSchema = yupSchema ? yupSchema : convertJsonToYupSchema(formData);

    const formik = useFormik({
      initialValues: initialValues || getEmptyFormValues(formData),
      validationSchema: validationSchema,
      validateOnBlur,
      validateOnChange,
      enableReinitialize,
      onSubmit: async (values, { resetForm }) => {
        await onSubmit(values);
        if (resetOnSubmit) {
          resetForm({ values: emptyValues });
        }
      }
    });

    const handleChange = (e) => {
      formik.handleChange(e);
      setTimeout(() => {
        formik.validateField(e.target.name);
      }, 0);
      if (onChange) {
        onChange(e);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        getFormValues: () => formik.values,
        setFormValues: (values) => formik.setValues(values)
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [formik.values]
    );

    const renderFormFields = () => {
      // If formData is an array
      if (Array.isArray(formData)) {
        return formData.map((form_input, index) => (
          <Grid item key={index} {...(form_input.grid || { xs: 12, md: 12, sm: 12 })} paddingX={1}>
            <FormInput {...form_input} formik={formik} handleChange={handleChange} />
          </Grid>
        ));
      }

      // grouped structure
      return Object.entries(formData).map(([groupName, fields], groupIndex) => (
        <Grid item xs={12} key={groupIndex}>
          <Box sx={{ mb: 2, mt: groupIndex > 0 ? 2 : 0 }}>
            <Typography sx={{ mb: 2, fontSize: '20px', fontWeight: 600, lineHeight: '32px' }}>{getDisplayName(groupName)}</Typography>
            <Grid container spacing={2}>
              {fields.map((form_input, fieldIndex) => (
                <Grid item key={fieldIndex} {...(form_input.grid || { xs: 12, md: 6, sm: 12 })}>
                  <FormInput {...form_input} formik={formik} handleChange={handleChange} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      ));
    };

    return (
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={2}>
          {renderFormFields()}
          <Grid item xs={12} paddingX={1}>
            {children ? (
              children
            ) : (
              <GlobalButton variant="contained" type="submit">
                Submit
              </GlobalButton>
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
);

export default GlobalForm;
