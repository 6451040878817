import { useMemo, useState } from 'react';
import { useEffect } from 'react';

import { updateTaskStatus } from 'api/taskManagement.service';
import useNotification from 'hooks/useNotifications';
import AssignedModal from 'ui-component/Modal/AssignedModal';
import DeleteModal from 'ui-component/Modal/DeleteModal';
import TaskAcceptModal from 'ui-component/Modal/TaskAcceptModal';
import TaskRejectModal from 'ui-component/Modal/TaskRejectModal';
import TaskUpdateModal from 'ui-component/Modal/TaskUpdateModal';
import CommonTable from 'ui-component/table/DataSourceTable/CommonTable';
import { useFunctionContext } from 'views/common/FunctionContext';

const TaskStateManagementTable = (props) => {
  const { title, sectionProps, apiCallOnPage, tableData, params, selectedChip, setSelectedChip, tableTotalCount, children, toolbarConfig } =
    props;
  const [successModal, setSuccessModal] = useState(false);
  const [message, setMessage] = useState('');
  const [taskId, setTaskId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [row, setRow] = useState(null);
  const [taskState, setTaskState] = useState({
    from: 'Pending Approval',
    to: 'Assigned'
  });
  //   eslint-disable-next-line no-unused-vars
  const { error: errorNotification, success: successNotification } = useNotification();
  const [tableState, setTableState] = useState({
    page_number: 0,
    page_size: 10
  });

  const [taskModalOpen, setTaskModalOpen] = useState({
    accept: false,
    reject: false,
    update: false
  });

  const handleRefreshTable = () => {
    apiCallOnPage({ ...params, ...tableState });
  };

  // create a context to call handleRefreshTable below

  const { setSharedFunction } = useFunctionContext() || {};

  useEffect(() => {
    // Set the function in context when Child A mounts
    if (setSharedFunction) {
      setSharedFunction((prev) => ({ ...prev, handleRefreshTable: handleRefreshTable }));
    }
  }, [setSharedFunction]);

  const handleTaskOpen = (item, name) => {
    // Task id is set once user clicks on the accept button
    // name can be 'accept' or 'reject'.
    // * Only triggers in case of accept or reject button click
    if (item?.id) {
      setTaskId(item.id);
      setTaskState((prev) => ({
        ...prev,
        to: name === 'accept' ? 'Assigned' : 'Rejected'
      }));
      setTaskModalOpen({
        [`${name}`]: true
      });
    } else {
      errorNotification('Task ID not found for this task!', 3000);
    }
  };

  const handleTaskClose = () => {
    setTaskModalOpen({
      accept: false,
      reject: false
    });
  };

  const handleAssign = (data) => {
    // * Transition 1
    // if successful, from: assigned, to: 'assigned'(still)
    // if error, from: pending approval, to: assigned(initial state)
    if (data) {
      setAssignLoading(true);
      setMessage('accepted');
      updateTaskStatus(taskId, {
        newStatus: data?.newStatus,
        // ! Hard code alert!
        // expectedCompletionDate: new Date(),
        currentStatus: data?.currentStatus,
        assignees: data?.assignedTo,
        note: data.notes
      })
        .then((res) => {
          if (res.status === 200) {
            // * We set the new `from` state (which is old `to` state)
            setTaskState((prev) => ({
              ...prev,
              // ! hard coding alert
              // hardcoded because this is handle assign function
              // if it is successful, it will always go to assigned.
              from: 'Assigned'
            }));
            successNotification('Task assigned successfully', 3000);
            handleRefreshTable();
          } else {
            errorNotification('Error assigning task', 3000);
          }
        })
        .catch((error) => {
          console.log(error);

          errorNotification('Error assigning task', 3000);
        })
        .finally(() => {
          handleTaskClose();
          setTaskId(null);
          setAssignLoading(false);
        });
    }
  };

  const handleClose = () => {
    setTaskModalOpen({
      accept: false,
      reject: false
    });
  };

  //   eslint-disable-next-line no-unused-vars
  const handleStatusChange = useMemo(
    () => (e, id, from, to) => {
      // update from and to state of the task
      // * It is triggered for the status update dropdown click
      // * from and to state is sent by the table dropdown and we set it here
      // * so after task assignment, when someone changes dropdown,
      // * from and to will be updated from table dropdown and then the modal will open

      setTaskId(id);
      // Transitions after transition 1 are all dependent on table
      // from and to both will be given by table
      setTaskState({
        from,
        to
      });
      // From Here, open modal and from modal, hit Status update API again.
      setTaskModalOpen({
        update: true
      });
    },
    []
  );

  const handleRowDelete = (row) => {
    setRow(row);
    setShowDeleteModal(true);
  };

  return (
    <>
      <CommonTable
        toolbarConfig={toolbarConfig}
        apiCallOnPage={apiCallOnPage}
        tableData={tableData}
        req_params={params}
        title={title}
        disableExport={sectionProps?.disableExport}
        toggleButtons={sectionProps?.toggleButtons}
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
        tableTotalCount={tableTotalCount}
        searchBar={sectionProps?.searchBar}
        // temp drill
        handleTaskOpen={handleTaskOpen}
        handleStatusChange={handleStatusChange}
        setTableState={setTableState}
        onRowDelete={handleRowDelete}
      >
        {children}
      </CommonTable>
      {taskModalOpen?.accept && (
        <TaskAcceptModal
          handleRefreshTable={handleRefreshTable}
          open={taskModalOpen?.accept}
          onAssign={handleAssign}
          handleClose={handleClose}
          submitting={assignLoading}
        />
      )}
      {taskModalOpen?.reject && (
        <TaskRejectModal
          open={taskModalOpen?.reject}
          handleClose={handleTaskClose}
          from={taskState?.from}
          to={taskState?.to}
          taskId={taskId}
          setState={setTaskState}
          handleRefreshTable={handleRefreshTable}
        />
      )}
      {taskModalOpen?.update && (
        <TaskUpdateModal
          handleRefreshTable={handleRefreshTable}
          open={taskModalOpen?.update}
          handleClose={handleTaskClose}
          from={taskState?.from}
          to={taskState?.to}
          taskId={taskId}
          setState={setTaskState}
        />
      )}
      {successModal && <AssignedModal open={successModal} setOpen={setSuccessModal} message={message} />}
      {showDeleteModal && (
        <DeleteModal variant="task" handleRefreshTable={handleRefreshTable} open={showDeleteModal} setOpen={setShowDeleteModal} row={row} />
      )}
    </>
  );
};

export default TaskStateManagementTable;
