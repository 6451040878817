import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

const StepperWrapper = styled(Box)`
  margin-left: 0.2rem;
  border-left: 3px solid #d4e4fd;
`;

const StepNode = styled(Box)`
  margin: 0 0 0.5rem 0;

  &:last-child {
    margin-bottom: 0;
  }
  padding-left: 0.5rem;
  position: relative;

  &::before {
    content: '';
    width: 0.5rem;
    height: 3px;
    background: #d4e4fd;
    position: absolute;
    top: 1rem;
    left: 0;
  }
`;

const StepperComponent = ({ steps }) => {
  return (
    <StepperWrapper>
      {steps.map((step, index) => (
        <StepNode key={index}>
          <Box variant="h6">{step.content}</Box>
        </StepNode>
      ))}
    </StepperWrapper>
  );
};

export default StepperComponent;
