import React, { useEffect } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import BetaTag from './beta-tag';
import ChildTabs from './child-tabs';
import { StyledBox } from 'styled-components/global';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DownArrowStyles, motionDivStyles } from '../styles';

const MemoizedSvg = React.memo(({ svg }) => {
  return <div style={{ display: 'flex', alignItems: 'center', padding: 2 }}>{svg}</div>;
});

const TabContent = ({ route, isSidebarExpanded, open, setOpen }) => {
  useEffect(() => {
    if (!isSidebarExpanded) {
      setOpen(false);
    }
  }, [isSidebarExpanded, setOpen]);

  const motionDivProps = {
    initial: { scaleX: 0, opacity: 0, width: 0 },
    animate: {
      scaleX: isSidebarExpanded ? 1 : 0,
      opacity: isSidebarExpanded ? 1 : 0,
      width: isSidebarExpanded ? '140px' : 0
    },
    exit: { scaleX: 0, opacity: 0, width: 0 },
    style: motionDivStyles(isSidebarExpanded)
  };

  const childTabsMotionProps = {
    initial: { height: 0 },
    animate: { height: open ? 'auto' : 0, opacity: open ? 1 : 0 },
    exit: { height: 0, opacity: 0 },
    style: { overflow: 'hidden' }
  };

  return (
    <>
      <BetaTag beta={route.beta} isSidebarExpanded={isSidebarExpanded} />
      <Tooltip title={!isSidebarExpanded ? route.name : ''} placement="right" arrow>
        <StyledBox display={'flex'} alignItems={'flex-start'} flexDirection={'column'}>
          <Box display={'flex'} alignItems={'center'}>
            <MemoizedSvg svg={route.svg} />
            <motion.div {...motionDivProps}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography noWrap>{route.name}</Typography>
                {route?.children && route.children.length > 0 && (
                  <StyledBox layoutStyle={'flexAlignCenterJustifyCenter'}>
                    <KeyboardArrowDownIcon style={DownArrowStyles(open)} />
                  </StyledBox>
                )}
              </Box>
            </motion.div>
          </Box>
          <Box position={'relative'}>
            <motion.div {...childTabsMotionProps}>
              <ChildTabs
                parentName={route.name}
                isSidebarExpanded={isSidebarExpanded}
                open={open}
                routeChildren={route.children}
                handleClose={() => setOpen(false)}
              />
            </motion.div>
          </Box>
        </StyledBox>
      </Tooltip>
    </>
  );
};

export default TabContent;
